const TokenAvatar = ({ id, className }) => {

    return (
        <img
            src={id == null ? '/img/projects/DEFAULT_LOGO.png' : `https://hypemytoken.s3.eu-central-1.amazonaws.com/${id}.jpg`}
            className={`${className}  rounded-full`}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/img/projects/DEFAULT_LOGO.png";
            }}
        />
    )
}

export default TokenAvatar