import { useContext, useEffect, useState } from "react";
import httpService from "services/http-service";
import { ThemeContext } from "utils/theme";
import BattleHistoryCard from "../BattleHistoryCard/BattleHistoryCard";



const BattleHistoryTable = () => {
    const [battles, setBattles] = useState([])
    const { darkMode } = useContext(ThemeContext);

    useEffect(() => {
        httpService
            .get(`/api/v1/battle/all`)
            .then((res) => setBattles(res.data.battles))
            .catch((err) => setBattles([]));
    }, []);


    return (

        <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-800 p-4">

            <BattleHistoryHeader />
            {battles.map((b, i) => <BattleHistoryCard battle={b} index={i} darkmode={darkMode} />)}
        </div>

    );
};

const BattleHistoryHeader = () => {
    return (
        <div className="px-4 sm:px-6 md:px-0">
            <h1 className="text-2xl leading-6 font-bold text-gray-900 dark:text-gray-200 mb-16">Battles</h1>
            <div className="hidden md:flex md:flex-row flex-col flex-wrap items-start md:items-center max-w-[92%] ">

                <div className="block font-clash_b lg:w-[25em] md:w-[22em] dark:text-white">
                    DATE
                </div>

                <div className="lg:w-[22em] md:w-[11em] font-clash_b flex justify-start md:justify-start w-full md:w-auto dark:text-white">
                    WINNER
                </div>
                <div
                    className="ml-4 md:ml-16 font-clash_b flex items-center dark:text-white">
                    HYPE
                </div>
            </div>
        </div>
    )
}

export default BattleHistoryTable;
