import { ThemeContext } from "utils/theme";
import { HypeButton } from "components/buttons/HypeButton/HypeButton";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpService from "services/http-service";
import { ClassNames } from "@emotion/react";
// Context

const BattleCard = ({ hypes = 0, projectId, victory, enableHype }) => {
    const { darkMode } = useContext(ThemeContext);

    const [project, setProject] = useState(null);
    const [currentHype, setCurrentHype] = useState(hypes);

    useEffect(() => {
        httpService
            .get(`/api/v1/token/${projectId}`)
            .then((res) => setProject(res.data.result[projectId]));
    }, [projectId]);

    if (project == null) return null
    return (
        <div
            className={`bg-white dark:bg-[#1B191B]  mb-2 p-2 mx-1 lg:mx-auto rounded min-w-[184px] md:min-w-[264px] text-hmt_text_color_light dark:text-hmt_text_color_dark
    
    ${victory !== null && victory === false ? "hidden md:block opacity-10" : ""}
    
    `}
        >
            <Link to={`/token/${project.id}`}>
                    <img
                        className={`${victory ? "h-[165px] w-[248px]" : "h-[110px] w-[165px]" }  md:h-[165px] md:w-[248px]  mb-4 flex justify-center md:justify-start`}
                        src={project.battleLogo == null
                            ? `/img/battle/hb_default.png`
                            : `https://hypemytoken.s3.eu-central-1.amazonaws.com/${project.battleLogo}.jpg`}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/img/battle/hb_default.png";
                        }}
                        alt="Hype Battle Project"
                    />
            </Link>
            <h3 className="font-clash_b text-xl mb-2 flex justify-start md:justify-start">
                <Link to={`/token/${project.id}`} className="truncate">{project.title}</Link>
            </h3>
            <div className="flex">
                <h6 className="text-sm font-mulish flex items-center justify-center md:justify-start text-[#FF5B09] dark:text-[#F80043]">
                    <img
                        className="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 mr-1"
                        src={darkMode ? "/img/hype_count_dark.svg" : "/img/hype_count.svg"}
                        alt="Hype Count"
                    />{" "}
                    {Number(currentHype).toLocaleString('en')}
                </h6>
                {enableHype &&
                    <HypeButton
                        styling={"w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 ml-auto"}
                        projectId={projectId}
                        handleClick={() => setCurrentHype(currentHype + 1)}
                    />
                }
            </div>
        </div>
    );
};
export default BattleCard