import { useEffect, useState } from "react";
import { getHypeTierBGColor, getHypeTierIcon } from "utils/HypeMeterUtils";
import { HypeButton } from "../../../components/buttons/HypeButton/HypeButton";
import httpService from "../../../services/http-service";


const TokenHypeMeter = ({ id, darkMode, hypes, setHypes }) => {
    const [hypeTier, setHypeTier] = useState(null);
    const [hypePercent, setHypePercent] = useState(0);
    const [bgColor, setBgColor] = useState("transparent");


    useEffect(() => {
        httpService
            .get(`/api/v1/token/${id}/tier`)
            .then((res) => {
                setHypeTier(res.data);
            })
            .catch((err) => setHypeTier(null));
    }, [id]);

    useEffect(() => {
        if (hypeTier) {
            let hp = 100;
            if (hypeTier.next) {
                hp = Math.ceil((hypeTier.hypes / (hypeTier.next - 1)) * 100);
            }
            setHypePercent(hp);
            setBgColor(getHypeTierBGColor(hypeTier.tier));
        }
    }, [hypeTier]);
    return (
        <div className="mb-4 mt-8 w-full ">
            <div className="text-black dark:text-white flex items-center justify-between">
                <div className="w-[80%] md:w-[90%] bg-gray-100 dark:bg-gray-900 rounded-full relative">
                    <div
                        style={{
                            width: `${hypePercent}%`,
                            backgroundImage: bgColor,
                        }}
                        className="h-4 rounded-full relative"
                    >
                        {hypePercent > 10 && (
                            <img
                                className={`${hypePercent <= 50 ? "hidden" : "block"
                                    } md:block w-8 h-8 absolute top-0 translate-y-[-40%] md:translate-y-[-50%] translate-x-[20%] right-0`}
                                src={getHypeTierIcon(hypeTier?.tier)}
                                alt="HMT Icon"
                            />
                        )}
                    </div>
                </div>
                <HypeButton styling={"w-10 h-10 md:w-12 md:h-12"} projectId={id}
                    handleClick={() => setHypes(hypes + 1)} />

            </div>
            <h3 className="font-clash_sb text-black dark:text-white mb-4">
                Hype Tier <span className="ml-1">{hypeTier?.tier}</span>
            </h3>

        </div>
    );
};

export default TokenHypeMeter