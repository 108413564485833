import { createSlice } from '@reduxjs/toolkit'
import httpService from "../../services/http-service";
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        hypeRestrictedProjects: [],
        followingTokens: [],
    },
    reducers: {
        setRestrictedProjects: (state, action) => {
            state.hypeRestrictedProjects = action.payload
        },
        addRestrictedProjects: (state, action) => {
            state.hypeRestrictedProjects.push(action.payload)
        },
        removeRestrictedProjects: state => {
            state.hypeRestrictedProjects = []
        },

        addFollowingToken: (state, action) => {
            state.followingTokens.push(action.payload)
        },
        removeFollowingToken: (state, action) => {
            state.followingTokens = state.followingTokens.filter(e => e !== action.payload)  
        },
        setFollowingTokens: (state, action) => {
            state.followingTokens = action.payload
        },
    }
})


export const selectHypeRestrictedProjects = state => state.user.hypeRestrictedProjects

export const selectFollowingTokens = state => state.user.followingTokens

export const { setRestrictedProjects, addRestrictedProjects, removeRestrictedProjects, addFollowingToken, removeFollowingToken, setFollowingTokens } = userSlice.actions

export default userSlice.reducer