

export default function AdvertisementDateFieldEnd({ data, changeData }) {
    return (
        <div className="flex-col items-center ml-8">
            <label htmlFor="end" className="block text-md text-gray-700 dark:text-gray-400 ">
                End (Exclusive)
            </label>
            <div className="mt-1">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                        type="date"
                        id="end"
                        name="trip-start"
                        value={data?.end && new Date(data?.end).toISOString().split('T')[0]}
                        className=" text-xs dark:text-black dark:invert-[100%]"
                        onChange={e => changeData('end', Date.parse(e.target.value+":00:00.000 UTC"))}
                    />

                </div>

            </div>
        </div>
    )
}

