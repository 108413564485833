import { Dialog, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom"
import axios from "axios";
import { ThemeContext } from "utils/theme";
import { Fragment, useContext, useEffect, useState } from "react"
import httpService from "services/http-service"
import TermsAndConditions from "../../../components/sections/TermsAndConditions/TermsAndConditions";
import SubmissionErrorSection from "../SubmissionErrorSection/SubmissionErrorSection";
import TokenTypeField from "../TokenSubmissionFields/TokenTypeField/TokenTypeField";
import TokenTitleField from "../TokenSubmissionFields/TokenTitleField/TokenTitleField";
import TokenNetworkField from "../TokenSubmissionFields/TokenNetworkField/TokenNetworkField";
import TokenTickerField from "../TokenSubmissionFields/TokenTickerField/TokenTickerField";
import TokenDescriptionField from "../TokenSubmissionFields/TokenDescriptionField/TokenDescriptionField";
import TokenMediaFields from "../TokenSubmissionFields/TokenMediaFields/TokenMediaFields";
import TokenWebsiteField from "../TokenSubmissionFields/TokenWebsiteField/TokenWebsiteField";
import PriceDataFields from "../TokenSubmissionFields/PriceDataFields/PriceDataFields";
import SocialFields from "../TokenSubmissionFields/SocialFields/SocialFields";
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function SubmitTokenForm() {
    const [data, setData] = useState({
        type: "token",
        network: null
    })
    const { darkMode } = useContext(ThemeContext);

    const [submitted, setSubmitted] = useState(false)
    const [avatar, setAvatar] = useState(null)
    const [battleLogo, setBattleLogo] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [uploadingImages, setUploadingImages] = useState(false)
    const [missingData, setMissingData] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const history = useHistory()
    const [submitError, setSubmitError] = useState(false)
    const [titleExists, setTitleExists] = useState(false)
    const [tickerExists, setTickerExists] = useState(false)

    const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS,
        secretAccessKey: process.env.REACT_APP_SECRET,
    }



    const changeObj = (objName, key, value) => {
        setData(d => ({
            ...d,
            [objName]: {
                ...d[objName],
                [key]: value
            }
        }))
    }

    const removeFromObj = (objName, key) => {
        const s = { ...data[objName] }
        delete s[key]
        setData(d => ({ ...d, [objName]: { ...s } }))
    }

    const changeData = (key, value) => {
        setData(d => ({ ...d, [key]: value }))
    }

    const changeType = (type) => {

        setData(d => ({
            ...d,
            type: type,
            priceData: {}
        }))
    }

    const submitFormData = async () => {


        if (!validateFormData(data) || !acceptedTerms || tickerExists || titleExists) {
            window.scrollTo({
                top: 0, left: 0, behavior: "smooth",
            });
            setMissingData(true)
            return
        }
        setSubmitting(true)
        setUploadingImages(true)



        const battleLogoFilename = `battle_${data.title}_${Date.now()}`
        const avatarFilename = `avatar_${data.title}_${Date.now()}`
        const formData = { ...data }

        if (battleLogo != null) {
            const battleLogoUrl = await axios({
                method: "GET",
                url: "/api/v1/s3/presigned-url/" + battleLogoFilename + ".jpg",
            })
            await fetch(battleLogoUrl.data, {
                method: "PUT",
                body: battleLogo?.file,
            });

            formData['battleLogo'] = battleLogoFilename

        }

        if (avatar != null) {

            const avatarUrl = await axios({
                method: "GET",
                url: "/api/v1/s3/presigned-url/" + avatarFilename + ".jpg",
            })

            await fetch(avatarUrl.data, {
                method: "PUT",
                body: avatar?.file,
            });

            formData['avatar'] = avatarFilename

        }
        setUploadingImages(false)

        formData['ticker'] = `#${formData['ticker']}`
        
        httpService.post('/api/v1/submission', formData).then(() => {
            window.scrollTo({
                top: 0, left: 0,
            });
            setSubmitted(true)
        }).catch(e => {
            window.scrollTo({
                top: 0, left: 0,
            });
            setSubmitError(true)
        })

    }


    if (submitted) return (<ThankYou />)

    return (
        <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-800 p-4 ">
            <div>
                <h1 className="text-2xl leading-6 font-bold text-gray-900 dark:text-gray-200 mb-2">Submit your Token</h1>
                <p className="text-xs text-gray-500">
                    Provide all the required information of your token. Optionally, provide a custom avatar and battle logo.
                    Once you have submitted your token, it will be reviewed by our moderators.
                </p>
                <p className="text-xs text-gray-500 mt-2">

                    If your submission is deemed unacceptable (i.e. it contains inappropriate language, bad grammar, or low quality / inappropriate avatar or battle logo), the moderators
                    will reject your submission. Otherwise, your submission will be accepted after which is will be visible on our platform.
                </p>
            </div>
            <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-800 pt-4 sm:space-y-5">
                <SubmissionErrorSection missingData={missingData} submitError={submitError} formDataValid={validateFormData(data)} acceptedTerms={acceptedTerms} />
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Token Details</h3>
                    </div>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <TokenTypeField data={data} changeType={changeType} />

                        <TokenTitleField data={data} changeData={changeData} setTitleExists={setTitleExists} titleExists={titleExists} />
                        <TokenNetworkField data={data} changeData={changeData} />
                        <TokenTickerField data={data} changeData={changeData} setTickerExists={setTickerExists} tickerExists={tickerExists} />
                        <TokenDescriptionField data={data} changeData={changeData} />
                        <TokenMediaFields avatar={avatar} battleLogo={battleLogo} setBattleLogo={setBattleLogo} setAvatar={setAvatar} />

                    </div>
                </div>

                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Community Details</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                        <TokenWebsiteField data={data} changeData={changeData} />

                        <PriceDataFields data={data} changeObj={changeObj} removeFromObj={removeFromObj} />
                        <SocialFields data={data} changeObj={changeObj} removeFromObj={removeFromObj} />
                    </div>
                </div>
            </div>
            <div className=" flex items-start py-8">
                <div className="flex items-center h-5">
                    <input
                        name={"terms"}
                        id={"terms"}
                        type="checkbox"
                        checked={acceptedTerms}
                        onChange={e => setAcceptedTerms(e.target.checked)}
                        className={`focus:ring-red-500 h-4 w-4 text-red-600 ${darkMode && 'bg-gray-800'}   border-gray-300 rounded`}
                    />
                </div>
                <div className="ml-2 text-sm mr-4">
                    <label htmlFor="terms" className="font-medium text-gray-700 dark:text-white dark:text-gray-300 text-xs">
                        I have read accept the <span className="underline cursor-pointer" onClick={(e) => {
                            e.preventDefault()

                            setShowTerms(true)
                        }}>Terms & Conditions</span>
                    </label>
                </div>
                {showTerms &&
                    <TermsAndConditions open={showTerms} onClose={() => setShowTerms(false)} onAgree={() => setAcceptedTerms(true)} />
                }

            </div>

            <div className="pt-5">
                <div className="flex justify-end">
                    {(!submitting || submitError) &&
                        <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => history.push("/")}
                        >
                            Back
                        </button>
                    }
                    <button
                        className="ml-3 inline-flex disabled:bg-slate-200 disabled:text-slate-500 disabled:border-slate-200 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={submitFormData}
                        disabled={!validateFormData(data) || !acceptedTerms || tickerExists || titleExists}

                    >
                        {(submitting && !submitError) ?
                            <div role="status" className="flex justify-start items-center">
                                <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                {uploadingImages ? "Uploading Images.." : "Submitting Token.."}
                            </div>
                            :
                            <>Submit</>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}




const ThankYou = () => {
    const history = useHistory()
    return (
        <div className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 md:-mt-16 lg:mt-0 lg:my-0 lg:mb-16 min-h-[50vh] md:min-h-[50vh] ">

            <h1 className=" flex justify-center text-2xl dark:text-gray-300 mt-48" >Thanks for you submission!</h1>
            <h5 className="flex justify-center text-center font-mulish dark:text-gray-200">Your submission will be reviewed as soon as possible.</h5>
            <div class="flex justify-center  mb-8">
                <div class="flex items-center mt-8">
                    <button
                        class="bg-red-500 hover:bg-red-700 text-white font-bold font-mulish py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => {
                            history.push('/')
                        }

                        }
                    >
                        Return to Home
                    </button>
                </div>
            </div>
        </div>
    )
}


const validateFormData = (data) => {
    if (!data) return
    const fieldsPresent = ['title', 'ticker', 'network', 'type', 'description', 'website', 'priceData', 'socials'].every(item => data.hasOwnProperty(item));
    const noTextEmpty = fieldsPresent ? ['title', 'ticker', 'network', 'type', 'description', 'website'].every(item => data[item].length > 0) : false
    const noObjectsEmpty = fieldsPresent ? ['priceData', 'socials'].every(item => Object.keys(data[item]).length > 0) : false
    return fieldsPresent && noTextEmpty && noObjectsEmpty
}