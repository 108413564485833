import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpService from "services/http-service";
import Battle from "../ActiveBattle/Battle";
// Context

const BattleSection = () => {
  const [activeBattle, setActiveBattle] = useState(true);
  const [battle, setBattle] = useState({});

  useEffect(() => {
    httpService.get("/api/v1/battle").then((res) => {
      setBattle(res.data.battle);

      setActiveBattle(res.data.active);
    });
  }, []);

  return (
    <>
      <section
        className={`xl:w-full xl:max-w-[1200px] mx-auto bg-hero-light dark:bg-hero-dark bg-contain xl:bg-cover bg-top bg-no-repeat ${
          activeBattle || battle?.winner
            ? "pt-[25%] md:pt-16 lg:pt-32 md:mb-24 lg:mb-8"
            : "h-[120px] md:h-[300px] lg:h-[325px]"
        }`}
      >
        {(activeBattle ||
          (battle?.winner != null &&
            Date.now() - battle.endsAt <= 86400000)) && (
          <Battle data={battle} />
        )}
      </section>
      {!activeBattle &&
        (battle?.winner == undefined ||
          Date.now() - battle.endsAt > 86400000) && <NoBattle />}
    </>
  );
};

export default BattleSection;

const NoBattle = () => {
  return (
    <div className="w-[90%] lg:w-[800px] xl:w-[1000px] mx-auto mt-2 md:mt-0 md:translate-y-[-125%] lg:translate-y-[-150%] xl:translate-y-[-50%] bg-[#f6f6f6] dark:bg-[#191616] text-center py-4 lg:py-8 rounded-lg">
      <h3 className="font-clash_b text-[12px] md:text-[16px] mb-2 text-hmt_dark dark:text-hmt_light">
        No Active Hype Battle!
      </h3>
      <p className="font-mulish text-[10px] md:text-[14px] text-hmt_dark dark:text-hmt_light mb-2">
        Want to promote your Token for maximum exposure?
      </p>
      <Link
        className="text-base font-semibold leading-7 text-[#FF5B0A]"
        to="/advertising"
      >
        Click <span aria-hidden="true">→</span>
      </Link>
    </div>
  );
};
