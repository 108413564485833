import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Context

import Avatar from "../../../../components/presentation/TokenAvatar/TokenAvatar";
import httpService from "../../../../services/http-service";

const SubmissionModerationSection = () => {
    const [listings, setListings] = useState([]);

    useEffect(() => {

        httpService.get(`/api/v1/submission/review`).then((res) => {
            const projectListings = res.data.submissions;
            setListings(projectListings);

        });
    }, []);


    return (
        <section className="p-4">
            <div className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 md:-mt-16 lg:mt-0 lg:my-0 lg:mb-16">

                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                            All pending token submissions are shown below.
                        </p>
                    </div>

                </div>
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ">
                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
                        <thead className="bg-gray-50 dark:bg-[#212121]">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font text-gray-900 dark:text-gray-300 sm:pl-6">
                                    Title
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                                >
                                    Network
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                                >
                                    Ticker
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">
                                    Type
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-900 bg-white dark:bg-[#121212] ">
                            {listings.map((token) => (
                                <tr key={token.project + "-" + token.hypedAt}>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6">
                                        <Link to={`/moderation/submission/${token.id}`}>
                                            <div className="flex items-center">

                                                <Avatar
                                                    id={token.avatar}
                                                    className="w-10 h-10 mr-2"
                                                />


                                                {token.title}
                                            </div>
                                        </Link>

                                        <dl className="font-normal lg:hidden">
                                            <dt className="sr-only sm:hidden">Network</dt>
                                            <dd className="mt-1 truncate text-gray-500 sm:hidden dark:text-gray-300 text-xs">{token.network}</dd>
                                            <dt className="sr-only sm:hidden">Ticker</dt>
                                            <dd className="mt-1 truncate text-gray-500 sm:hidden dark:text-gray-300 text-xs">{token.ticker}</dd>
                                        </dl>
                                    </td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{token.network}</td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{token.ticker}</td>
                                    <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-200">{token.type}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}


export default SubmissionModerationSection