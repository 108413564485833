import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, yupToFormErrors } from "formik";
import * as Yup from "yup";
import S3 from "react-aws-s3";
import Datepicker from "react-tailwindcss-datepicker";
import File from "../../components/forms/File";
import TermsAndConditions from "components/sections/TermsAndConditions/TermsAndConditions";
import * as dayjs from "dayjs";
import { eachDayOfInterval } from "date-fns";
import axios from "axios";

function getDatesInRange({ startDate, endDate }, occupation) {
  const booleanArray = [];
  const datesInRange = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });
  datesInRange?.forEach((value, index) => {
    if (index === 0) return;
    if (index === datesInRange?.length - 1) return;
    const formattedValue = dayjs(value).format("YYYY-MM-DD");
    const includeBookedDate = occupation?.some(
      ({ startDate, endDate }) =>
        formattedValue === startDate || formattedValue === endDate
    );
    booleanArray.push(includeBookedDate);
  });

  return booleanArray.filter(Boolean);
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET,
};

const ValidationSchema = Yup.object().shape({
  url: Yup.string().required("Please provide url"),
  resourceId: Yup.string().required("This Field is Required"),
  start: Yup.string().required("This Field is Required").nullable(),
  end: Yup.string()
    .test(
      "same_dates_test",
      "Start date cannot be the same as End date",
      function (value) {
        const { start } = this.parent;
        if (!value || !start) return;

        return new Date(value).getTime() > new Date(start).getTime();
      }
    )
    .nullable(),
});

const initialValues = {};

function validate(values) {
  return ValidationSchema.validate(values, { abortEarly: false })
    .then(() => { })
    .catch((err) => {
      return yupToFormErrors(err);
    });
}

const AdForm = (props) => {
  const { submitAdRequest, occupation } = props;
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [showTerms, setShowTerms] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [file, setFile] = useState();
  const [validationError, setValidationError] = useState(false);

  async function handleFileUpload(file) {
    const filename = `${file.name}_${Date.now()}`
    const avatarUrl = await axios({
      method: "GET",
      url: `/api/v1/s3/presigned-url/${filename}`,
    })

    await fetch(avatarUrl.data, {
      method: "PUT",
      body: file,
    });

    return `https://hypemytoken.s3-eu-central-1.amazonaws.com/${filename}`;
  }

  async function handleSubmit(values, formikbag) {
    const { setFieldValue } = formikbag;
    const uploadedFileLocation = await handleFileUpload(file);
    setFieldValue("resourceId", uploadedFileLocation);

    submitAdRequest(
      {
        ...values,
        resourceId: uploadedFileLocation,
      },
      formikbag
    );
  }

  return (
    <div
      className={classNames(
        "isolate mx-auto mt-10 grid max-w-xl grid-cols-1 gap-8 items-center justify-center"
      )}
    >
      <div
        className={classNames(
          "ring-2 ring-indigo-600",
          "rounded-3xl p-8 xl:p-10"
        )}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {({
            values,
            submitForm,
            setFieldValue,
            isValid,
            isSubmitting,
            errors,
            touched,
            setValues,
            validateForm,
            setFieldTouched,
          }) => (
            <Form>
              <p className="mb-4 text-sm leading-6 text-gray-600 dark:text-white">
                Fill in the form below and complete the payment.
              </p>
              <div className="">
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-400">
                  Dates From - To
                </label>
                <Datepicker
                  separator={"-"}
                  primaryColor={"orange"}
                  value={dates}
                  minDate={dayjs().subtract(1, "day")}
                  displayFormat={"DD/MM/YYYY"}
                  onChange={(value) => {
                    const { endDate, startDate } = value;
                    if (endDate === null || startDate === null) {
                      setValidationError(false);
                      setDates(value);
                      setFieldTouched("start", true, false);
                      setFieldTouched("end", true, false);
                      setValues({
                        ...values,
                        start: startDate,
                        end: endDate,
                      });
                      return;
                    }
                    const formattedEndDate =
                      dayjs(endDate).format("YYYY-MM-DD");
                    const formattedStartDate =
                      dayjs(startDate).format("YYYY-MM-DD");
                    const rangeIncludesOccupiedDates = getDatesInRange(
                      {
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                      },
                      occupation
                    );
                    if (rangeIncludesOccupiedDates?.length) {
                      setValidationError(true);
                      setDates({ startDate: null, endDate: null });
                      return;
                    }

                    setValidationError(false);
                    setDates(value);
                    setFieldTouched("start", true, false);
                    setFieldTouched("end", true, false);
                    setValues({
                      ...values,
                      start: formattedStartDate,
                      end: formattedEndDate,
                    });
                  }}
                  showShortcuts={false}
                  containerClassName="max-w-lg sm:max-w-xs"
                  disabledDates={occupation}
                />
                <p className="mt-2 text-pink-600 text-sm">
                  {validationError || errors?.start ? (
                    <>
                      {validationError ? (
                        "The dates you selected include already booked dates."
                      ) : (
                        <ErrorMessage name="start" />
                      )}
                    </>
                  ) : errors?.end ? (
                    <ErrorMessage name="end" />
                  ) : null}
                </p>
              </div>
              <div className="mt-4">
                <label className="block text-xs font-medium text-gray-700 dark:text-gray-400">
                  Url the banner will link to
                </label>
                <Field
                  name="url"
                  type="text"
                  className="peer max-w-lg block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-xs text-xs border-gray-300 rounded-md dark:bg-gray-800 dark:text-white dark:border-black"
                  required={errors?.url?.length > 0}
                />
                <p className="mt-2 invisible peer-invalid:visible text-pink-600 text-sm">
                  <ErrorMessage name="url" />
                </p>
              </div>
              <File
                name="resourceId"
                id="resourceId"
                label="Banner Image"
                setFieldValue={setFieldValue}
                file={file}
                setFile={setFile}
              />
              <div className=" flex items-start pt-2">
                <div className="flex items-center h-5">
                  <input
                    name={"terms"}
                    id={"terms"}
                    type="checkbox"
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                    className={`focus:ring-red-500 h-4 w-4 text-red-600 dark:bg-gray-800 border-gray-300 rounded`}
                  />
                </div>
                <div className="ml-2 text-sm mr-4">
                  <label
                    htmlFor="terms"
                    className="font-medium text-gray-700 dark:text-white dark:text-gray-300 text-xs"
                  >
                    I have read and understand section 12.6 of the{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();

                        setShowTerms(true);
                      }}
                    >
                      Terms & Conditions
                    </span>
                  </label>
                </div>
                {showTerms ? (
                  <TermsAndConditions
                    open={showTerms}
                    onClose={() => setShowTerms(false)}
                    onAgree={() => setAcceptedTerms(true)}
                  />
                ) : null}
              </div>
              <button
                type="submit"
                className="disabled:bg-slate-200 disabled:text-slate-500 disabled:cursor-not-allowed bg-orange-500 text-white max-w-lg mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={
                  !isValid || isSubmitting || !acceptedTerms || validationError
                }
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdForm;
