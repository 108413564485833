

export default function AdvertisementDateFieldStart({ data, changeData }) {

    return (
        <div className="flex-col items-center">
            <label htmlFor="start" className="block text-md text-gray-700 dark:text-gray-400 ">
                Start
            </label>
            <div className="mt-1">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <input 
                    type="date" 
                    id="start" 
                    name="trip-start" 
                    className=" text-xs dark:text-black dark:invert-[100%]" 
                    value={data?.start && new Date(data?.start).toISOString().split('T')[0]}
                    
                    onChange={e => changeData('start', Date.parse(e.target.value+":00:00.000 UTC"))}
                    />

                </div>

            </div>
        </div>
    )
}

