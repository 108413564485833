import { useEffect } from "react";
import ProjectDetails from "../TokenPageLayout/TokenPageLayout";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import TokenPageLayout from "../TokenPageLayout/TokenPageLayout";

const TokenPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0, behavior: "smooth",
        });
    }, []);

    return (
        <HeaderLayout>
            <TokenPageLayout />
        </HeaderLayout>
    );
};

export default TokenPage;
