import dayjs from "dayjs";
import { useEffect, useState } from "react";
import httpService from "../../../services/http-service";
// Context
import Chart from "../../../components/presentation/HypeChart/HypeChart";

const TokenHypeChart = ({ project }) => {
    const [filter, setFilter] = useState({
        period: "hours",
        recurrence: 24,
    });
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        httpService
            .get(
                `/api/v1/hype/history?token=${project.id}&timeunit=${filter.period}&size=${filter.recurrence}`
            )
            .then((res) => {
                let hypeObj = res.data.data;
                let hypeData = Object.keys(hypeObj)
                    .map((key) => {
                        let chartObj = {};
                        let date = new Date(0);
                        date.setUTCMilliseconds(key);
                        chartObj.date = date;
                        if (filter.period === "hours") {
                            chartObj.pretty_date = dayjs(date).format("HH:mm");
                        }
                        if (filter.period === "days") {
                            chartObj.pretty_date = dayjs(date).format("D MMM");
                            chartObj.date = date;
                        }
                        if (filter.period === "months") {
                            chartObj.pretty_date = dayjs(date).format("MMM YY");
                            chartObj.date = date;
                        }

                        chartObj.hypes = hypeObj[key];
                        return chartObj;
                    })
                    .sort((a, b) => a.date - b.date);
                setChartData(hypeData);
            })
            .catch((err) => {
                setChartData(null);
            });
    }, [filter, project]);
    return (
        <section>
            {/* Header */}
            <header>
                <div className="flex items-center flex-wrap md:flex-nowrap justify-between  md:justify-between mb-4">
                    <h5 className="md:text-lg mr-1 text-hmt_text_color_light dark:text-hmt_text_color_dark font-clash_sb mb-4 md:mb-0">
                        Hype History
                    </h5>
                    <h5 className="md:text-md mr-1 text-hmt_text_color_light dark:text-hmt_text_color_dark font-clash_sb mb-4 md:mb-0 ">
                        All Time High: {project.allTimeHigh}
                    </h5>


                </div>
                <div className="flex items-center mb-4">
                    <button
                        onClick={() =>
                            setFilter({
                                period: "hours",
                                recurrence: 24,
                            })
                        }
                        className={`${filter.period === "hours" && filter.recurrence === 23
                            ? "bg-chart_btn text-white"
                            : "bg-gray-100 dark:bg-gray-900 text-hmt_text_color_light dark:text-hmt_text_color_dark"
                            } w-16 p-1 text-xs rounded-full font-clash_sb mr-4`}
                    >
                        Today
                    </button>
                    <button
                        onClick={() =>
                            setFilter({
                                period: "days",
                                recurrence: 7,
                            })
                        }
                        className={`${filter.period === "days" && filter.recurrence === 6
                            ? "bg-chart_btn text-white"
                            : "bg-gray-100 dark:bg-gray-900 text-hmt_text_color_light dark:text-hmt_text_color_dark"
                            } w-16 p-1 text-xs rounded-full font-clash_sb mr-4`}
                    >
                        7 days
                    </button>
                    <button
                        onClick={() =>
                            setFilter({
                                period: "days",
                                recurrence: 30,
                            })
                        }
                        className={`${filter.period === "days" && filter.recurrence === 29
                            ? "bg-chart_btn text-white"
                            : "bg-gray-100 dark:bg-gray-900 text-hmt_text_color_light dark:text-hmt_text_color_dark"
                            } w-16 p-1 text-xs rounded-full font-clash_sb mr-4`}
                    >
                        30 days
                    </button>
                </div>
            </header>
            {/* Chart */}
            <div className="font-mulish">{chartData && <Chart chartData={chartData} />}</div>
        </section>
    );
};

export default TokenHypeChart