import { Helmet } from "react-helmet"

export const SEO = ({ title, description}) => {
    return (
        <Helmet>
            <html lang="en" amp />
            <title itemProp="title" lang="en">
                {title}
            </title>
            <meta charSet="utf-8" />
            <meta name="description" content={description} />
            <meta property="keywords" content="hype,bitcoin,crypto,tokens,cryptocurrency,ethereum" />
            <link rel="canonical" href="" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.hypemytoken.com/" />
            <meta name="twitter:creator" content="@HypeMyToken" />
            <meta name="twitter:site" content="@HypeMyToken" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </Helmet>
    )
}