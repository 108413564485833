import axios from "axios"
import { useEffect, useState } from "react"
import httpService from "services/http-service"


export default function TokenNetworkField({ data, changeData }) {
    const [allowedNetworks, setAllowedNetworks] = useState([])
    useEffect(() => {
        httpService.get('/api/v1/token/properties/network').then(res => setAllowedNetworks(res.data.networks))
    }, [])



    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
            <label htmlFor="network" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                Network
                {!data.network && <span className={`text-red-600 text-xs ml-1`}>*</span>}

            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">

                <div className="max-w-[15em] flex rounded-md shadow-sm">

                    <select
                        id="title"
                        name="title"
                        className="flex-1 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-white dark:border-black block w-full min-w-0 rounded-none rounded-r-md text-xs border-gray-300 "
                        value={data.network}
                        onChange={(e) => changeData('network', e.target.value)}
                    >
                        <option></option>
                        {allowedNetworks.map(n => <option>{n}</option>)}
                    </select>
                </div>
            </div>
        </div>
    )
}

