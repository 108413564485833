
import { ExclamationIcon } from "@heroicons/react/outline";

const SubmissionErrorSection = ({ missingData, submitError, formDataValid, acceptedTerms }) => {

    return (
        <>
            {missingData &&

                <div className="rounded-md bg-red-700 p-2 mt-4 my-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <div className="text-sm text-white font-mulish">
                                {(formDataValid && !acceptedTerms) ? "Please accept the terms & conditions" : "Please fill in the missing information!"}

                            </div>
                        </div>
                    </div>
                </div>

            }
            {submitError &&
                <div className="rounded-md bg-red-700 p-2 mt-4 my-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <div className="text-sm text-white font-mulish">
                                Something went wrong while submitting your token.
                                Please check if all fields contain sane data.
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default SubmissionErrorSection   