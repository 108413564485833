import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Context

import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import Graph from "components/presentation/HypeGraphSmall/HypeGraphSmall";
import { HypeButton } from "components/buttons/HypeButton/HypeButton";
import httpService from "services/http-service";
import { ThemeContext } from "@emotion/react";
import { Line, LineChart, ResponsiveContainer } from "recharts";


const DumpCard = ({ projectID, diff }) => {
    const { darkMode } = useContext(ThemeContext);

    // Set Project ID of Project to be Pumped
    const [project, setProject] = useState(null);

    // Project Data
    useEffect(() => {
        if (projectID != undefined) {

            httpService
                .get(`/api/v1/token/${projectID}`)
                .then((res) => {
                    setProject(res.data.result[projectID]);
                })
                .catch((err) => setProject(null));
        }
    }, [projectID]);
    // Chart Data
   

    return (
        <div className="w-full md:w-[49%] mb-4 ">
            <h3 className="font-clash_b text-black dark:text-white mb-4">
                Recent Dump
            </h3>
            <div
                className="border-2 h-[9em] border-[#FF2E0010] flex items-center justify-between p-4 lg:p-8 rounded-lg bg-[#FF2E0008] dark:bg-[#FF2E0012] text-black dark:text-white font-mulish">
                <div className="flex items-center">
                    {project ?
                        <>
                            <Link to={`/token/${projectID}`}>
                                <Avatar
                                    id={project.avatar}
                                    className="w-12 h-12"
                                />

                            </Link>
                            <div className="ml-4 ">
                                <h2 className="font-mulish_b lg:text-xl mb-4">
                                    <Link to={`/token/${projectID}`}>
                                        {project.title}{" "} <span className="text-red-500 text-xs">({diff})</span>
                                    </Link>
                                </h2>
                                <span className="flex md:justify-start text-xs">
                                    <span
                                        className="bg-white dark:bg-white/10 p-1 0 rounded mr-2">
                                        {project.ticker}
                                    </span>
                                    <span
                                        className="bg-white dark:bg-white/10 p-1 0 rounded">
                                        {project.type}
                                    </span>
                                </span>
                            </div>
                        </>
                        :
                        <div className="ml-4 h-[4.1em] flex-col justify-center items-center ">
                            <h2 className="font-mulish_b lg:text-xl ">Unavailable</h2>
                            <p className="font-mulish">No dump at this moment</p>
                        </div>
                    }
                </div>
                {project &&
                    <div className="flex items-center justify-end ">

                        <HypeButton styling={"w-10 h-10 md:w-10 md:h-10 lg:w-12 lg:h-12"} projectId={projectID} />
                    </div>
                }

            </div>
        </div>
    );
};

export default DumpCard