import httpService from "../../../services/http-service";
import { useContext, useState } from "react";
import { ThemeContext } from "../../../utils/theme";
import {
  addRestrictedProjects,
  selectHypeRestrictedProjects,
} from "../../../features/user/userSlice";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "services/AuthService";
import Captcha from "components/sections/Captcha/Captcha";
import { ClientJS } from "clientjs";
import HypeLimitPopup from "components/sections/HypeLimitPopup/HypeLimitPopup";
import WonGiveAwayModal from "./WonGiveAwayModal";
import ConfettiExplosion from "react-confetti-explosion";

const COLORS = ["#FFC700", "#FF0000", "#2E3191", "#41BBC7"];
// ["#9A0023", "#FF003C", "#AF739B", "#FAC7F3", "#F7DBF4"]
const mediumProps = {
  force: 0.6,
  duration: 2500,
  particleCount: 100,
  width: 1000,
  colors: COLORS,
};

const HypeButton = ({ projectId, styling, handleClick }) => {
  const [captcha, setCaptcha] = useState(false);
  const [limited, setLimited] = useState(false);
  const [latestHypeResponse, setLatestHypeResponse] = useState();
  const { darkMode } = useContext(ThemeContext);
  const loggedIn = AuthService.isLoggedIn();
  const restrictedProjects = useSelector(selectHypeRestrictedProjects);
  const dispatch = useDispatch();
  const [giveAwayModalOpen, setGiveAwayModal] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [giveAwayInfo, setGiveAwayInfo] = useState(null);

  const client = new ClientJS();

  let id = parseInt(projectId);

  const disableHypeBtn =
    restrictedProjects.includes(id) || restrictedProjects.length >= 5;
  const handleHype = () => {
    if (!disableHypeBtn) {
      if (!loggedIn) {
        setCaptcha(true);
        return;
      } else {
        dispatch(addRestrictedProjects(id));
        const fingerprint = client.getFingerprint();
        httpService
          .post(
            `/api/v1/hype`,
            { tokenId: id },
            {
              headers: {
                "X-Request-ID": fingerprint,
              },
            }
          )
          .then((res) => {
            if (res.data.wonGiveAway) {
              setIsExploding(true);
              fetchGiveAway(res.data.giveAwayId);
              setTimeout(() => {
                setGiveAwayModal(true);
              }, 500);
            }
            setLatestHypeResponse(res.data);
          });
        handleClick();
      }
    }
  };

  function fetchGiveAway(giveAwayId) {
    if (giveAwayId) {
      httpService.get(`/api/v1/giveaway/${giveAwayId}`).then((res) => {
        setGiveAwayInfo(res.data);
      });
    }
  }

  function handleClose() {
    setGiveAwayModal(false);
  }

  return (
    <>
      <img
        role="button"
        className={`${styling} 
          ${
            disableHypeBtn
              ? "drop-shadow-none cursor-not-allowed"
              : "drop-shadow-hmt_light dark:drop-shadow-hmt_dark cursor-pointer"
          }
        `}
        src={
          disableHypeBtn
            ? "/img/hypebtn_disabled.svg"
            : darkMode
            ? "/img/hypebtn_dark.svg"
            : "/img/hypebtn_light.svg"
        }
        alt="Hype Button"
        onClick={handleHype}
      />
      {isExploding && <ConfettiExplosion {...mediumProps} />}
      {giveAwayModalOpen && (
        <WonGiveAwayModal
          open={giveAwayModalOpen}
          onClose={handleClose}
          giveAwayInfo={giveAwayInfo}
        />
      )}
      <HypeLimitPopup open={limited} onClose={() => setLimited(false)} />
      <Captcha
        open={captcha}
        onClose={() => setCaptcha(false)}
        onValidate={(c) => {
          if (!disableHypeBtn) {
            const fingerprint = client.getFingerprint();

            httpService
              .post(
                `/api/v1/hype`,
                { tokenId: id, captchaResponse: c },
                {
                  headers: {
                    "X-Request-ID": fingerprint,
                  },
                }
              )
              .then((res) => {
                if (res.data.wonGiveAway) {
                  setIsExploding(true);
                  fetchGiveAway(res.data.giveAwayId);
                  setTimeout(() => {
                    setGiveAwayModal(true);
                  }, 500);
                }
                setLatestHypeResponse(res.data);
                setCaptcha(false);
                dispatch(addRestrictedProjects(id));
                handleClick();
              })
              .catch((err) => {
                if ((err.response.status = 429)) {
                  setCaptcha(false);
                  setLimited(true);
                }
              });
          }
        }}
      />
    </>
  );
};

export { HypeButton };
