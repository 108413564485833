import { useContext, useEffect, useState } from 'react'
import httpService from 'services/http-service'
import { ThemeContext } from 'utils/theme'
import { Link } from "react-router-dom";
import {

    BellAlertIcon,

} from '@heroicons/react/24/outline'
const TokenFollowers = ({ data }) => {
    const [popup, setPopup] = useState(false)
    const { darkMode } = useContext(ThemeContext);



    return (
        <>
            {data &&
                <>
                    <div className='mb-4 mt-4'>

                        <button onClick={() => {
                            if (data.total > 0)
                                setPopup(true)
                        }} className=' flex'>

                            <BellAlertIcon className="h-6 w-6 flex-shrink-0 text-gray-400 mr-2" aria-hidden="true" />
                            <p className='dark:text-gray-300 mr-2 text-sm'>{data.total} Follower(s)</p>
                        </button>

                        {popup &&
                            <TokensPopUp title="Followed Tokens" data={data} open={true} onClose={() => setPopup(null)} />
                        }
                    </div>
                </>
            }
        </>
    )
}

const TokensPopUp = ({ title, data, open, onClose }) => {
    const { darkMode } = useContext(ThemeContext);

    return (
        <>

            <div id="defaultModal" tabindex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 -translate-y-1/2 w-full max-w-md -translate-x-1/2 z-50 h-modal `} >
                <div class="relative p-4  ">
                    <div class="relative bg-white rounded-lg shadow dark:bg-[#212121]">
                        <div class="flex justify-between items-start p-2 px-4 rounded-t border-b dark:border-gray-600">
                            <h3 class="text-xl font-clash_b text-gray-900 dark:text-white">
                                {title}
                            </h3>
                            <button type="button" onClick={() => onClose()} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div class="p-6 space-y-4 dark:text-white font-mulish text-xs overflow-y-auto max-h-[50vh]">
                            {data.anonymous > 0 &&
                                <p className="ml-2 dark:text-white font-clash_b text-md mb-4">{data.anonymous} Anonymous followers</p>
                            }
                            {data.followers.map(u =>
                                <Link to={`/user/${u.username}`} >

                                    <div className="flex items-center mb-1">
                                        <img
                                            className="h-9 w-9 rounded-full flex items-center justify-center "
                                            src={darkMode
                                                ? `https://ui-avatars.com/api/?background=800020&color=fff&name=${u.username}&bold=true`
                                                : `https://ui-avatars.com/api/?background=9A2A2A&color=fff&name=${u.username}bold=true`
                                            }
                                            alt=""
                                        />
                                        <p className="ml-2 dark:text-white font-clash_b text-md">{u.username}</p>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>
        </>
    )

}

export default TokenFollowers