import MobileFilter from "../../../components/tables/TokenTable/MobileFilter/MobileFilter"

const TableViewFilter = ({ handleChangeFilter, activeFilter, elementRef, networkFilter, typeFilter, handleChangeNetworkFilter, handleChangeTypeFilter }) => {
    console.log(networkFilter)
    console.log(typeFilter)
    return (
        <div
            ref={elementRef}
            className="flex items-center justify-between flex-wrap md:flex-nowrap overflow-x-scroll md:overflow-x-hidden">
            <div
                className={`w-[45%] md:w-[47%]  border-b ${activeFilter === "listed"
                    ? "border-b-2 border-[#FF5B0A] font-clash_b text-black dark:text-white"
                    : "border-b dark:border-gray-800 font-clash text-[#AEAEAE]"
                    } text-center p-1 md:p-2 cursor-pointer flex items-center justify-center`}
                onClick={() => handleChangeFilter("listed")}
            >
                <span className="mr-2 md:mr-2">
                    <svg
                        fill={activeFilter === "listed" ? "#FF5B0A" : "#AEAEAE"}
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path
                            d="M10 0s8 7.58 8 12a8 8 0 1 1-16 0c0-1.5.91-3.35 2.12-5.15A3 3 0 0 0 10 6V0zM8 0a3 3 0 1 0 0 6V0z" />
                    </svg>

                </span>
                Listed
            </div>
            <div
                className={`md:w-[47%] w-[45%] border-b ${activeFilter === "new"
                    ? "border-b-2 border-[#FF5B0A] font-clash_b text-black dark:text-white"
                    : "border-b dark:border-gray-800 font-clash text-[#AEAEAE]"
                    } text-center p-1 md:p-2 cursor-pointer flex items-center justify-center`}
                onClick={() => handleChangeFilter("new")}
            >
                <span className="mr-2 md:mr-2">

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill={activeFilter === "new" ? "#FF5B0A" : "#AEAEAE"}
                        className="w-4 h-4 mb-1"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />

                    </svg>

                </span>
                All
            </div>
            <div
                className={`md:w-[6%] w-[10%]  font-clash border-b pb-1 bg-gray-50 dark:bg-[#121212] rounded-tr-lg  md:pb-2 dark:border-gray-800 border-l text-[#AEAEAE] cursor-pointer flex items-center justify-center
                ${networkFilter != null || typeFilter != null
                        ? "border-b-2 border-[#FF5B0A] font-clash_b text-black dark:text-white"
                        : "border-b dark:border-gray-800 font-clash text-[#AEAEAE]"
                    }`}

            >
                <MobileFilter
                    networkFilter={networkFilter}
                    typeFilter={typeFilter}
                    onApply={(n, t) => {

                        handleChangeNetworkFilter(n)
                        handleChangeTypeFilter(t)
                    }}

                />

            </div>
        </div>

    )
}

export default TableViewFilter