import { SEO } from "components/presentation/SEO/SEO";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import httpService from "services/http-service";
import { ThemeContext } from "utils/theme";
import TokenDetails from "components/sections/TokenDetails/TokenDetails";
import TokenHeader from "components/sections/TokenHeader/TokenHeader";


const SubmissionActions = ({id}) => {
    const history = useHistory();

    const rejectSubmission = () => {
        httpService.post(`/api/v1/submission/review/reject/${id}`).then(() => history.push('/moderation'))
    }

    const approveSubmission = () => {
        httpService.post(`/api/v1/submission/review/approve/${id}`)
            .then((res) => history.push('/moderation'))

    }


    return (
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <button
                type="button"
                className="inline-flex items-center dark:bg-[#444444] dark:text-white justify-center px-4 py-2 dark:border-none border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"

            >
                Edit
            </button>
            <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => rejectSubmission()}
            >
                Reject
            </button>
            <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={() => approveSubmission()}
            >
                Approve
            </button>

        </div>
    )
}

export default SubmissionActions