import Submit from "views/SubmitToken/SubmitTokenPage/SubmitTokenPage";
import AdministrationPage from "views/Administration/AdministrationPage/AdministrationPage";
import BattlesPage from "views/Battles/BattlesPage/BattlesPage";
import ForgotPasswordPage from "views/ForgotPassword/ForgotPasswordPage/ForgotPasswordPage";
import LoginPage from "views/Login/LoginPage/LoginPage";
import ModerationPage from "views/Moderation/ModerationPage/ModerationPage";
import RegisterPage from "views/Register/RegisterPage/RegisterPage";
import SubmissionPage from "views/Submission/SubmissionPage/SubmissionPage";
import TokenPage from "views/Token/TokenPage/TokenPage";
import UserProfilePage from "views/ManageUserProfile/UserProfilePage/UserProfilePage";
import VerifyAccountPage from "views/VerifyAccount/VerifyAccountPage/VerifyAccountPage";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminRoute from "../AdminRoute/AdminRoute";
import ModeratorRoute from "../ModeratorRoute/ModeratorRoute";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import HomePage from "views/Home/HomePage/HomePage";
import GiveAwayPage from "views/GiveAway/GiveAwayPage/GiveAwayPage";
import AdvertisingPage from "views/Advertising";
import SubmittedTokensPage from "views/SubmittedTokens/SubmittedTokensPage/SubmittedTokensPage";
import FollowingTokensPage from "views/FollowingTokens/FollowingTokensPage/FollowingTokensPage";
import PublicUserProfilePage from "views/PublicUserProfile/PublicUserProfilePage/PublicUserProfilePage";

const adminRoutes = {
  "/admin": <AdministrationPage />,
};

const moderatorRoutes = {
  "/moderation/submission/:id": <SubmissionPage />,
  "/moderation": <ModerationPage />,
};

const userRoutes = {
  "/submit": <Submit />,
  "/profile": <UserProfilePage />,
  "/user/:user/:tab": <PublicUserProfilePage />,
  "/user/:user": <PublicUserProfilePage />,
};

const unauthenticatedRoutes = {
  "/login": <LoginPage />,
  "/register": <RegisterPage />,
  "/verify": <VerifyAccountPage />,
  "/reset-password": <ForgotPasswordPage />,
  "/battles": <BattlesPage />,
  "/token/:id": <TokenPage />,
  "/giveaway": <GiveAwayPage />,
  "/advertising": <AdvertisingPage />,
};

export default function HypeMyTokenRoutes() {
  return (
    <Switch>
      {Object.keys(adminRoutes).map((url) => (
        <AdminRoute path={url}>{adminRoutes[url]}</AdminRoute>
      ))}
      {Object.keys(moderatorRoutes).map((url) => (
        <ModeratorRoute path={url}>{moderatorRoutes[url]}</ModeratorRoute>
      ))}
      {Object.keys(userRoutes).map((url) => (
        <PrivateRoute path={url}>{userRoutes[url]}</PrivateRoute>
      ))}
      {Object.keys(unauthenticatedRoutes).map((url) => (
        <Route path={url}>{unauthenticatedRoutes[url]}</Route>
      ))}

      <Route exact path="/">
        <HomePage />
      </Route>

      <Redirect to={"/"} />
    </Switch>
  );
}
