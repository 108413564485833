import axios from "axios"


export default function TokenDescriptionField({ data, changeData }) {



    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                <span>
                    Description
                    {!data.description && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                    <p className="text-xs text-gray-500">Provide a brief description of the token. {560 - (data.description ? data.description.length : 0)} characters left.</p>

                </span>

            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                    id="description"
                    name="description"
                    rows={5}
                    value={data.description}
                    onChange={(e) => changeData('description', e.target.value)}
                    maxlength="560"
                    className="max-w-lg shadow-sm block w-full focus:ring-red-500 focus:border-red-500 text-xs border border-gray-300 rounded-md dark:bg-gray-800 dark:text-white dark:border-black"
                    defaultValue={''}
                />
            </div>
        </div>
    )
}

