import React, { useState } from "react";

const FAQ = () => {
  return (
    <section>
      <div className="mt-8 min-h-[104px] max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto py-0 pt-4 xl:pt-0 px-2 lg:px-4 xl:px-[8px] pb-16 text-black dark:text-white">
        <h2 className="text-2xl font-clash_b mb-8">FAQ</h2>
        <Collapsible
          title="What is HypeMyToken?"
          description="HypeMyToken is an interactive platform for crypto enthusiasts to spread awareness about their favorite tokens."
        />
        <Collapsible
          title="How does HypeMyToken work?"
          description="Once your token is submitted on HypeMyToken.com everyone will be able to Hype a token by clicking the Hype Button on the website. Enthusiasts can also Tweet about your token whilst using a token #HypeMyToken in combination with e.g. #Bitcoin Every Tweet with these hashtags will positively impact your hype level. Keep in mind Tweeting has double the Hype impact! But don’t get too comfortable.. Your ranking isn’t permanent.
          "
        />
        <Collapsible
          title="How long does a Hype last?"
          description="A Hype through HypeMytoken.com is valid for 120 hours, 
          if you use an account your Hype will be valid for 168 hours. (IP-address/account restricted.) 
          A Hype through Twitter is valid for 24 hours. Tweeting can take up to 1 hour before it is counted as a Hype."
        />
        <Collapsible
          title="How often can I Hype?"
          description="You can Hype every 2 hours without an account. With an account you can Hype every hour."
        />
        <CollapsibleSPL title="What is the Hype Meter?" />

        <Collapsible
          title="What is a Hype Battle?"
          description="Four similar tokens (based on our algorithm) are selected to battle it out in HypeMyToken style. Communities and users will have to act quickly as this event will only last 24 hours. Whoever can gather the most Hype within this event will receive a top spot on HypeMyToken for 24 hours."
        />
      </div>
    </section>
  );
};

export default FAQ;

const Collapsible = ({ title, description }) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <article className="pt-4 pb-4 border-b border-gray-300">
      <div className="flex justify-between">
        <h5
          className="cursor-pointer font-clash_sb"
          onClick={() => setShowDescription(!showDescription)}
        >
          {title}
        </h5>
        <span
          onClick={() => setShowDescription(!showDescription)}
          className="cursor-pointer font-bold"
        >
          {showDescription ? "-" : "+"}
        </span>
      </div>
      {showDescription && (
        <p className="text-[14px] font-mulish mt-4 text-[#4B4B4B] dark:text-gray-100">
          {description}
        </p>
      )}
    </article>
  );
};

const CollapsibleSPL = (props) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <article className="pt-4 pb-4 border-b border-gray-300">
      <div className="flex justify-between">
        <h5
          className="cursor-pointer font-clash_sb"
          onClick={() => setShowDescription(!showDescription)}
        >
          {props.title}
        </h5>
        <span
          onClick={() => setShowDescription(!showDescription)}
          className="cursor-pointer font-bold"
        >
          {showDescription ? "-" : "+"}
        </span>
      </div>
      {showDescription && (
        <div>
          <p className="text-[14px] font-mulish mt-4 text-[#4B4B4B] dark:text-gray-100">
          The Hype Meter is located on the profile of a token. 
          This meter shows the advancement of the token towards the next Hype tier.
          Hype tiers have a potential impact on the amount of Hypes that are awarded when a token receives a single Hype. 
          Currently there are five Hype tiers:
          </p>
          <ol className="list-decimal pl-8 text-[14px] font-mulish mt-4 text-[#4B4B4B] dark:text-gray-100">
            <li>TIER 1. 0-99 (Each hype counts as 1)</li>
            <li>TIER 2. 100-999 (Each hype has a chance of counting for 2)</li>
            <li>TIER 3. 1,000-4,999 (Each hype has a chance of counting for 3)</li>
            <li>TIER 4. 5,000-9,999 (Each hype has a chance of counting for 4)</li>
            <li>TIER 5. 10,000+ (Each hype has a chance of counting for 5)</li>
          </ol>
          <p className="text-[14px] font-mulish mt-4 text-[#4B4B4B] dark:text-gray-100">
          Once you have received enough Hype to reach a new Tier, 
          the impact of each Hype has a chance of doubling. 
          That means a single hype could count for two or more (see tier table.)
          </p>
        </div>
      )}
    </article>
  );
};
