import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react"
import httpService from "services/http-service";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const MobileFilter = ({ onApply, networkFilter, typeFilter }) => {
    const [open, setOpen] = useState(false)
    const [networkFilterInternal, setNetworkFilter] = useState(networkFilter)
    const [typeFilterInternal, setTypeFilter] = useState(typeFilter)
    const [allowedNetworks, setAllowedNetworks] = useState([])

    useEffect(() => {
        httpService.get('/api/v1/token/properties/network').then(res => setAllowedNetworks(res.data.networks))
    }, [])

    const onClose = () => {
        setOpen(false)
    }


    return (
        <>
            <button onClick={() => setOpen(o => !o)} className='w-[100%] h-[100%] flex items-center justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-4 mt-2 md:mt-3 mb-1 text-[#535353] dark:text-[#FFFFFF80] `}>
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                    />
                </svg>
            </button>

            <div id="defaultModal" tabIndex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 max-w-lg -translate-y-1/2 w-[90vw] -translate-x-1/2 z-50 h-modal `} >

                <div class="relative p-4  ">
                    <div class="relative bg-white rounded-lg shadow dark:bg-[#212121]">
                        <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                            <h3 class="text-lg font-clash_b text-gray-900 dark:text-white">
                                Filters
                            </h3>
                            <button type="button" onClick={() => onClose()} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div class="p-6 space-y-6 dark:text-white font-mulish text-xs overflow-y-auto h-[30vh]">
                            <div >
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Network
                                </label>
                                <select
                                    id="network"
                                    name="network"
                                    className="mt-1 block w-full rounded-md border-gray-300 dark:bg-black dark:text-white py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    defaultValue={networkFilter}
                                    onChange={(e) => setNetworkFilter(e.target.value)}

                                >
                                    <option key={'none'} selected={networkFilter == null} value={null}>All</option>

                                    {allowedNetworks.map(a => <option key={a} selected={networkFilter == a}>{a}</option>)}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Type
                                </label>
                                <select
                                    id="type"
                                    name="type"
                                    className="mt-1 block w-full rounded-md border-gray-300 dark:bg-black dark:text-white py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                                    defaultValue={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                >
                                    <option selected={networkFilter == null} value={null}>All</option>
                                    <option selected={networkFilter == "NFT"} value={"NFT"}>NFT</option>
                                    <option selected={networkFilter == "TOKEN"} value={"TOKEN"}>Token</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex items-center p-2 space-x-2 rounded-b">
                            <button data-modal-toggle="defaultModal" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={() => {
                                onClose()
                                let n = null
                                if (networkFilterInternal && networkFilterInternal !== 'All') n = networkFilterInternal
                                let t = null
                                if (typeFilterInternal && typeFilterInternal !== 'All') t = typeFilterInternal
                                onApply(n, t)
                            }}>Apply</button>
                            <button onClick={() => onClose()} data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                        </div>
                    </div>

                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>

        </>
    )
}

export default MobileFilter