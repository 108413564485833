import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/stylesheets/index.css";
import App from "./App";
// Context
import { ThemeProvider } from "./utils/theme";
import { Provider } from "react-redux";
import store from './utils/store'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>

        <App />

      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
