import NetworkFilter from "../NetworkFilter/NetworkFilter";
import TypeFilter from "../TypeFilter/TypeFilter";

const TableHeader = ({  }) => {
    return (
        <div
            className="font-mulish hidden md:flex justify-between items-center p-2 md:pt-4 lg:pt-2 bg-white dark:bg-[#EAECF008] text-[12px] text-black dark:text-white border-b border-gray-200 dark:border-gray-800">
            <div className="w-[5%] text-center">#</div>
            <div className="w-[20%] pl-8">Title</div>
            <div className="w-[15%] text-center  flex items-center">
                Network{" "}

            </div>
            <div className="w-[10%] text-center  flex items-center">Ticker</div>
            <div className="w-[15%] text-center  flex items-center justify-center">
                Type
            </div>
            <div className="w-[20em] pl-8">Hype Meter</div>
            <div className="w-[15%] text-center">Hype</div>
        </div>
    );
};

export default TableHeader