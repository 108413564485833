import axios from "axios"


export default function AdvertisementLinkField({ data, changeData}) {

    return (
        <div className="flex-col items-center">
            <label htmlFor="link" className="block text-md text-gray-700 dark:text-gray-400 ">
                Link
            </label>
            <div className="mt-1">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                        type="text"
                        name="title"
                        value={data?.link}
                        onChange={(e) => changeData('link', e.target.value)}

                        id="link"
                        className=" block w-full focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
                    />

                </div>

            </div>
        </div>
    )
}

