import * as dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ThemeContext } from "utils/theme";
import { Fragment, useContext, useRef, useState } from "react";
import AuthService from "services/AuthService";
import RedirectLogin from "components/sections/RedirectLogin/RedirectLogin";

const TweetFeed = ({ tweets }) => {
  const activity = [...tweets];
  activity.sort((a, b) => b.timestamp - a.timestamp);

  return (
    <ul role="list" className="-mb-8 mt-2">
      {activity.map((a, i) => (
        <li key={i}>
          <div className="relative pb-8">
            {i !== activity.length - 1 ? (
              <span
                className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex items-start space-x-3">
              <div className="relative">
                <a
                  className="mr-2 flex "
                  href={`https://www.twitter.com/${a.content.username}/status/${a.content.tweetId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-6 w-6 bg-white ml-2 rounded-full  flex items-center justify-center ring-8 ring-white"
                    src={"/img/brand/Twitter-logo.svg"}
                    alt="Github"
                  />
                </a>
              </div>
              <div className="min-w-0 flex-1">
                <div>
                  <div className="text-sm font-medium text-gray-900 dark:text-gray-100">
                    {a.user}
                  </div>
                  <p className="mt-0.5 text-sm text-gray-500 dark:text-gray-400">
                    {dayjs.unix(a.timestamp / 1000).format("D MMM - HH:mm")}
                  </p>
                </div>
                <div className="mt-2 text-sm text-gray-700 dark:text-gray-200 font-mulish">
                  <p>{a.content.content}</p>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

const HypeFeed = ({ hypes, title, onRedirect }) => {
  const { darkMode } = useContext(ThemeContext);

  const activity = [...hypes];

  activity.sort((a, b) => b.timestamp - a.timestamp);

  return (
    <>
      <ul role="list" className="-mb-8 ">
        {activity.map((a, i) => (
          <li key={i}>
            <div className="relative pb-8">
              {i !== activity.length - 1 ? (
                <span
                  className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <div>
                  <div className="relative px-1">
                    <div className="h-8 w-8  rounded-full flex items-center justify-center">
                      {a.user == "anonymous" ? (
                        <img
                          className="h-8 w-8 rounded-full flex items-center justify-center "
                          src={"/img/hypebtn_disabled.svg"}
                          alt=""
                        />
                      ) : (
                        <>
                          {AuthService.isLoggedIn() ? (
                            <Link to={`/user/${a.user}`}>
                              <img
                                className="h-8 w-8 rounded-full flex items-center justify-center "
                                src={
                                  darkMode
                                    ? `https://ui-avatars.com/api/?background=800020&color=fff&name=${a.user}&bold=true`
                                    : `https://ui-avatars.com/api/?background=9A2A2A&color=fff&name=${a.user}&bold=true`
                                }
                                alt=""
                              />
                            </Link>
                          ) : (
                            <button
                              onClick={() => onRedirect(`/user/${a.user}`)}
                            >
                              <img
                                className="h-8 w-8 rounded-full flex items-center justify-center "
                                src={
                                  darkMode
                                    ? `https://ui-avatars.com/api/?background=800020&color=fff&name=${a.user}&bold=true`
                                    : `https://ui-avatars.com/api/?background=9A2A2A&color=fff&name=${a.user}&bold=true`
                                }
                                alt=""
                              />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="min-w-0 flex-1 py-1.5">
                  <div className="text-sm text-gray-500 dark:text-gray-300 font-mulish">
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {a.user == "anonymous" ? (
                        a.user
                      ) : (
                        <Link to={`/user/${a.user}`}>{a.user}</Link>
                      )}
                    </span>{" "}
                    Hyped up{" "}
                    <span className="font-medium text-gray-900 dark:text-gray-100">
                      {title}
                    </span>{" "}
                    (
                    <span
                      className={`${a.content.amount > 1 && "text-[#F1573D]"}`}
                    >
                      + {a.content.amount} Hype
                    </span>
                    ){" "}
                    <span className="whitespace-nowrap">
                      ({dayjs.unix(a.timestamp / 1000).format("D MMM - HH:mm")})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export { TweetFeed, HypeFeed };
