import React, { useContext } from "react";
import {
  LineChart,
  Line,
  Label,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
// Context
import { ThemeContext } from "../../../utils/theme";

const HypeChart = ({ chartData }) => {
  const { darkMode } = useContext(ThemeContext);
  const textColor = darkMode ? "#FFF" : "#000";
  return (
    <div className="bg-gray-50 dark:bg-[#191616] p-4 text-hmt_text_color_light dark:text-hmt_text_color_dark">
      <div className="-ml-8 md:-ml-6">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartData}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <Line
              type="monotone"
              dataKey="hypes"
              stroke="#FF512F"
              strokeWidth={1}
              dot={false}
              connectNulls={true}
            />
            <XAxis

              dataKey="pretty_date"
              interval={"preserveStartEnd"}
              stroke={textColor}
            />
            <YAxis tick={false} />
            <Tooltip content={<CustomTooltip/>}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="dark:bg-gray-900 border border-gray-500 rounded p-2">
        <p className="font-clash_b">{`${label}`}</p>
        <p className="text-red-500">{`Hype: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default HypeChart;
