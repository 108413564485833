import { useState, useEffect, useCallback } from "react";
import httpService from "services/http-service";
import TokenSearchBox from "components/forms/TokenSearchBox/TokenSearchBox";

const HypeBattle = () => {
  const [nextBattle, setNextBattle] = useState([]);
  const [participantOne, setParticipantOne] = useState();
  const [participantTwo, setParticipantTwo] = useState();
  const [participantThree, setParticipantThree] = useState();
  const [participantFour, setParticipantFour] = useState();
  const [duration, setDuration] = useState();

  function fetchNextBattle() {
    httpService
      .get(`/api/v1/admin/battle/next`)
      .then((res) => setNextBattle(res.data))
      .catch((err) => setNextBattle([]));
  }

  useEffect(() => {
    fetchNextBattle();
  }, []);

  function submitBattle() {
    httpService
      .post("/api/v1/admin/battle", {
        participants:
          participantThree && participantFour
            ? [
                participantOne,
                participantTwo,
                participantThree,
                participantFour,
              ]
            : [participantOne, participantTwo],
        duration: Number(duration),
      })
      .then((response) => {
        console.log("response", response);
        fetchNextBattle();
      });
  }

  return (
    <div className="p-4">
      {nextBattle?.id ? (
        <span>
          {`Next battle with id: ${nextBattle?.id} will start after the current active battle is finished.`}
        </span>
      ) : (
        <>
          <div className="mt-4">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-400">
              Token 1
            </label>
            <TokenSearchBox
              navigateOnChange={false}
              onChangeCallback={(value) => setParticipantOne(value?.id)}
            />
          </div>
          <div className="mt-4">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-400">
              Token 2
            </label>
            <TokenSearchBox
              navigateOnChange={false}
              onChangeCallback={(value) => setParticipantTwo(value?.id)}
            />
          </div>
          <div className="mt-4">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-400">
              Token 3
            </label>
            <TokenSearchBox
              navigateOnChange={false}
              onChangeCallback={(value) => setParticipantThree(value?.id)}
            />
          </div>
          <div className="mt-4">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-400">
              Token 4
            </label>
            <TokenSearchBox
              navigateOnChange={false}
              onChangeCallback={(value) => setParticipantFour(value?.id)}
            />
          </div>
          <div className="mt-4">
            <label
              htmlFor="email"
              className="block text-xs font-medium text-gray-700 dark:text-gray-400"
            >
              Duration (in minutes)
            </label>
            <div className="mt-2">
              <input
                type="number"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                name="duration"
                id="duration"
                className="block w-[10em] rounded-md border-gray-300 dark:text-white border-gray-800 dark:bg-gray-900 focus:border-red-500 focus:ring-red-500 text-xs"
              />
            </div>
          </div>
          <div className="mt-4 py-2 flex justify-start">
            <button
              type="submit"
              className="mr-6 disabled:bg-slate-200 disabled:text-slate-500 disabled:border-slate-200 bg-red-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={submitBattle}
            >
              <>Save</>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default HypeBattle;
