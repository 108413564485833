import { useEffect } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import ExperimentalForm from "../SubmitTokenForm/SubmitTokenForm";


const Submit = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0, behavior: "smooth",
        });
    }, []);
    return (

        <HeaderLayout>

            <section
                className="min-h-[300px] md:min-h-[500px] lg:min-h-screen font-clash_b w-full ">
                <ExperimentalForm />

            </section>
        </HeaderLayout>);
};

export default Submit;
