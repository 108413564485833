import axios from "axios";

class AuthService {
  login(username, password) {
    return axios
      .post("/api/v1/auth/signin", { username, password })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
          window.dispatchEvent(new Event("storage"));

        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
    window.dispatchEvent(new Event("storage"));

  }
  register(username, email, password) {
    return axios.post("/api/v1/auth/signup", {
      username,
      email,
      password
    });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn() {
    return localStorage.getItem('user') != null
  }

  hasRole(role) {
    return localStorage.getItem('user') != null && this.getCurrentUser().roles.includes(role)
  }
}
export default new AuthService();