import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import httpService from 'services/http-service'
import { ThemeContext } from 'utils/theme'
import UserPasswordSection from '../Password/UserPasswordSection'
import UserWalletSection from '../Wallet/UserWalletSection'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const UserAccountSection = ({ user, onChange }) => {

    return (
        <div className={`py-6 px-4 sm:p-6 lg:pb-8 `}>

            <div className="flex flex-col ">
                <div className="flex-grow space-y-6">
                    <General user={user} onChange={onChange} />
                    <Settings user={user} onChange={onChange} />
                    <UserWalletSection onChange={undefined} />
                    <UserPasswordSection />
                </div>
            </div>
        </div>


    )
}

const General = ({ user, onChange }) => {
    const [twitterName, setTwitterName] = useState(user?.userDetails.twitterProfile)
    const [saving, setSaving] = useState(false)
    const disableSave = (user?.userDetails.twitterProfile == null ? (twitterName == null || twitterName.length == 0) : twitterName == user?.userDetails.twitterProfile)

    const handleSave = () => {
        setSaving(true)
        httpService.put('/api/v1/user/details/twitter', { profile: twitterName?.length > 0 ? twitterName : null }).then((r) => {
            onChange()
            setSaving(false)
        })

    }
    return (
        <>
            <h1 className='dark:text-white border-b w-full border-gray-200 dark:border-[#212121] pb-2 text-lg font-clash_b'>General</h1>

            <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b text-sm">
                    Username
                </label>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-200">
                    {user.username}
                </p>
            </div>
            <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700  dark:text-gray-300 font-clash_b">
                    E-mail
                </label>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-200">
                    {user.email}
                </p>
            </div>

            <div>
                <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b mb-2" >
                    Twitter Profile
                </label>
                <div className="mt-1 flex rounded-md ">
                    <span className="bg-gray-50 dark:bg-[#212121] border text-xs border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center dark:text-gray-100 text-gray-500 sm:text-sm dark:border-gray-500">
                        twitter.com/
                    </span>
                    <input
                        type="text"
                        name="twitter"
                        value={twitterName}
                        onChange={(e) => setTwitterName(e.target.value)}
                        id="twitter"
                        className=" focus:ring-0 dark:bg-[#212121] focus:border-red-500 text-xs  block md:w-[20em] dark:text-white rounded-none rounded-r-md sm:text-sm border-gray-300 dark:border-gray-500"
                        placeholder="Example: HypeMyToken"
                    />
                </div>
                <p className="mt-2 text-xs md:text-sm text-gray-500" id="email-description">
                    We'll use this for giveaways that involve Twitter.
                </p>
            </div>
            {!disableSave &&

                <div className="mt-14 py-2 flex justify-start">
                    <button
                        type="submit"
                        className="mr-6 disabled:bg-slate-200 disabled:text-slate-500 disabled:border-slate-200 dark:disabled:bg-slate-500 dark:disabled:text-slate-200 dark:disabled:border-slate-500 bg-red-700 border border-transparent rounded-md shadow-sm py-1 px-3 inline-flex justify-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={handleSave}
                        disabled={disableSave}
                    >
                        {saving ?
                            <div role="status" className="flex items-center justify-center">
                                <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <>Saving..</>
                            </div>
                            :
                            <>Save</>
                        }
                    </button>

                </div>
            }
        </>
    )
}

const Settings = ({ user, onChange }) => {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);

    const [preferDarkmode, setPreferDarkmode] = useState(user?.userSettings.prefersDarkmode);
    const [shareSubmitted, setShareSubmitted] = useState(user?.userSettings.shareSubmitted);
    const [shareFollowing, setShareFollowing] = useState(user?.userSettings.shareFollowing);
    const [receiveSummary, setReceiveSummary] = useState(user?.userSettings.receiveDailySummary);
    const [shareTwitter, setShareTwitter] = useState(user?.userSettings.shareTwitter)
    const [saving, setSaving] = useState(false)

    const handleSave = () => {
        setSaving(true)
        let settings = {
            prefersDarkmode: preferDarkmode,
            shareSubmitted,
            shareFollowing,
            shareTwitter,
            receiveDailySummary: receiveSummary
        }


        httpService.put('/api/v1/user/settings', settings).then(() => {
            if (!preferDarkmode && darkMode) {
                toggleDarkMode()
            } else if (preferDarkmode && !darkMode) {
                toggleDarkMode()
            }
            setSaving(false)
        })
    }

    const disableSave = (preferDarkmode == darkMode &&
        shareSubmitted == user?.userSettings.shareSubmitted &&
        shareFollowing == user?.userSettings.shareFollowing &&
        shareTwitter == user?.userSettings.shareTwitter &&
        receiveSummary == user?.userSettings.receiveDailySummary
    )
    return (
        <>
            <h1 className='dark:text-white border-b pt-8 w-full border-gray-200  dark:border-[#212121] pb-2 text-lg font-clash_b'>Settings</h1>

            <div className="flex items-center h-5">
                <div className="text-sm mr-4">
                    <label htmlFor="darkmode" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b mb-1" >
                        Prefer Darkmode
                    </label>
                </div>
                <input
                    name={"darkmode"}
                    id={"darkmode"}
                    type="checkbox"
                    checked={preferDarkmode}
                    onChange={e => setPreferDarkmode(e.target.checked)}
                    className={`focus:ring-red-500 h-4 w-4 text-red-600  border-gray-300 rounded`}
                />
            </div>

            <div className="flex items-center h-5">
                <div className="text-sm mr-4">
                    <label htmlFor="sharesubmit" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b mb-1" >
                        Show other users which tokens I submitted
                    </label>
                </div>
                <input
                    name={"sharesubmit"}
                    id={"sharesubmit"}
                    type="checkbox"
                    checked={shareSubmitted}
                    onChange={e => setShareSubmitted(e.target.checked)}
                    className={`focus:ring-red-500 h-4 w-4 text-red-600  border-gray-300 rounded`}
                />
            </div>
            <div className="flex items-center h-5">
                <div className="text-sm mr-4">
                    <label htmlFor="sharefollow" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b mb-1" >
                        Show other users which tokens I am following
                    </label>
                </div>
                <input
                    name={"sharefollow"}
                    id={"sharefollow"}
                    type="checkbox"
                    checked={shareFollowing}
                    onChange={e => setShareFollowing(e.target.checked)}
                    className={`focus:ring-red-500 h-4 w-4 text-red-600  border-gray-300 rounded`}
                />
            </div>
            <div className="flex items-center h-5">
                <div className="text-sm mr-4">
                    <label htmlFor="sharefollow" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b mb-1" >
                        Show my twitter profile to others
                    </label>
                </div>
                <input
                    name={"shareTwitter"}
                    id={"shareTwitter"}
                    type="checkbox"
                    checked={shareTwitter}
                    onChange={e => setShareTwitter(e.target.checked)}
                    className={`focus:ring-red-500 h-4 w-4 text-red-600  border-gray-300 rounded`}
                />
            </div>
            <div className="flex items-center h-5">
                <div className="text-sm mr-4">
                    <label htmlFor="sharefollow" className="block text-sm font-medium text-gray-700 dark:text-gray-300 font-clash_b mb-1" >
                        Receive daily token summary
                    </label>
                </div>
                <input
                    name={"dailySummary"}
                    id={"dailySummary"}
                    type="checkbox"
                    checked={receiveSummary}
                    onChange={e => setReceiveSummary(e.target.checked)}
                    className={`focus:ring-red-500 h-4 w-4 text-red-600  border-gray-300 rounded`}
                />
            </div>
            {!disableSave &&
                <div className="mt-14 py-2 flex justify-start">
                    <button
                        type="submit"
                        className="mr-6 disabled:bg-slate-200 disabled:text-slate-500 disabled:border-slate-200 dark:disabled:bg-slate-500 dark:disabled:text-slate-200 dark:disabled:border-slate-500 bg-red-700 border border-transparent rounded-md shadow-sm py-1 px-3 inline-flex justify-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={handleSave}
                        disabled={disableSave}
                    >
                        {saving ?
                            <div role="status" className="flex items-center justify-center">
                                <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <>Saving..</>
                            </div>
                            :
                            <>Save</>
                        }
                    </button>

                </div>
            }
        </>
    )
}
export default UserAccountSection