import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import httpService from "services/http-service";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TokenSearchBox({
  navigateOnChange = true,
  onChangeCallback,
}) {
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const history = useHistory();
  const [listings, setListings] = useState({});

  useEffect(() => {
    httpService.get(`/api/v1/token?order=new`).then((res) => {
      let projectListings = res.data.listings.map((l) => ({
        id: l.id,
        title: l.title,
      }));
      setListings(projectListings);
    });
  }, []);

  const changeSelectedValue = (val) => {
    setSelectedValue(val);
    console.log("val", val);
    onChangeCallback && onChangeCallback(val);
    navigateOnChange && history.push(`/token/${val.id}`);
  };

  const filteredValues =
    query === ""
      ? listings
      : listings.filter((val) => {
          return val.title.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-6 w-6 flex-shrink-0 text-gray-400"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
        />
      </svg>

      <Combobox
        as="div"
        value={selectedValue}
        onChange={changeSelectedValue}
        className="w-full"
      >
        <div className="ml-2 w-[100%]">
          <Combobox.Input
            className="rounded-md w-[100%]  text-xs border border-gray-300 dark:border-gray-800  focus:border-red-500 outline-none focus:ring-1 focus:ring-red-500 dark:bg-[#212121] dark:text-white"
            placeholder="Search Token"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(val) => val?.title}
          />

          {filteredValues.length > 0 && (
            <Combobox.Options className="absolute z-10 w-[60%] mobile:w-[19em] md:w-[15em] lg:w-[20em] max-h-60  py-4 overflow-auto rounded-md bg-white dark:bg-[#212121] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
              {filteredValues.map((val) => (
                <Combobox.Option
                  key={val.id}
                  value={val}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-1 pl-3 pr-9 text-xs",
                      active
                        ? "bg-red-600 text-white"
                        : "text-gray-900 dark:text-gray-200"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block  ",
                          selected && "font-semibold"
                        )}
                      >
                        {val.title}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        ></span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  );
}
