import { useEffect, useState } from "react";
import httpService from "services/http-service";


const UserAdministrationSection = () => {
    const [users, setUsers] = useState([])
    useEffect(() => {
        httpService.get('/api/v1/admin/user').then(r => setUsers(r.data.users))
    }, [])



    return (
        <div className="p-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                        All registered users are shown below.
                    </p>
                </div>

            </div>

            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ">
                <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
                    <thead className="bg-gray-50 dark:bg-[#212121]">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font text-gray-900 dark:text-gray-300 sm:pl-6">
                                Username
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Email
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Registered At
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Enabled
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">
                                Roles
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-900 bg-white dark:bg-[#121212] ">
                        {users.map((user) => (
                            <tr key={user.username}>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6">
                                    {user.username}
                                    <dl className="font-normal lg:hidden">
                                        <dt className="sr-only ">Email</dt>
                                        <dd className="mt-1 truncate text-gray-700 dark:text-gray-200">{user.email}</dd>
                                        <dt className="sr-only ">Registered At</dt>
                                        <dd className="mt-1 truncate text-gray-700 dark:text-gray-200">{new Date(user.createdAt).toLocaleDateString()} {new Date(user.createdAt).toLocaleTimeString()}</dd>
                                        <dt className="sr-only sm:hidden">Enabled</dt>
                                        <dd className="mt-1 truncate text-gray-500 sm:hidden dark:text-gray-300 text-xs">{user.enabled ? 'Active' : "Inactive"}</dd>
                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{user.email}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{new Date(user.createdAt).toLocaleDateString()} {new Date(user.createdAt).toLocaleTimeString()}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{user.enabled ? 'Active' : "Inactive"}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-200">{user.roles.join(", ")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserAdministrationSection;
