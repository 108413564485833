import axios from "axios";

export default function TokenWebsiteField({ data, changeData }) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2"
      >
        Official Website
        {!data.website && (
          <span className={`text-red-600 text-xs ml-1`}>*</span>
        )}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type="text"
          name="website"
          id="website"
          value={data.website}
          onChange={(e) => changeData("website", e.target.value)}
          className="max-w-lg block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-xs text-xs border-gray-300 rounded-md dark:bg-gray-800 dark:text-white dark:border-black"
        />
      </div>
    </div>
  );
}
