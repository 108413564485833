import { useState } from "react";
import SubmissionModerationSection from "../ModerationSections/SubmissionModerationSection/SubmissionModerationSection";

const tabs = ["Submissions", "Battle"]
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ModerationPanel = () => {
    const [currentNav, setCurrentNav] = useState("Submissions")
    const navigationSwitch = () => {
        switch (currentNav) {
            case "Submissions": return <SubmissionModerationSection />
            case "Battle": return null
        }
    }

    return (
            <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-800 p-4">

                <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-2xl leading-6 font-bold text-gray-900 dark:text-gray-200 mb-2">Moderation</h1>
                </div>
                <div className="bg-white dark:bg-[#121212] rounded-lg shadow overflow-hidden ">

                    <div className="lg:hidden">
                        <label htmlFor="selected-tab" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="selected-tab"
                            name="selected-tab"
                            onChange={e => setCurrentNav(e.target.value)}
                            className="dark:bg-gray-800 dark:text-white mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"

                        >
                            {tabs.map((tab) => (
                                <option key={tab}>{tab}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden lg:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8 px-4">
                                {tabs.map((tab) => (
                                    <a
                                        key={tab}
                                        onClick={() => setCurrentNav(tab)}
                                        className={classNames(
                                            'border-transparent text-gray-500 hover:border-red-300 hover:text-gray-700',
                                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm dark:text-gray-200',
                                            currentNav == tab && 'border-red-500 hover:border-red-500'
                                        )}
                                    >
                                        {tab}
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>
                    <div className="divide-y divide-gray-200 dark:divide-gray-800 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x min-h-[80vh]">

                        <div className="divide-y divide-gray-200 dark:divide-gray-800  lg:col-span-9 ">
                            {navigationSwitch()}
                        </div>
                    </div>
                </div>
            </div>
    );
};
export default ModerationPanel;
