import { ThemeContext } from "utils/theme";
import { useContext, useState } from "react"

const CheckedTextInput = ({ title, onChange, value, onDisable }) => {
    const { darkMode } = useContext(ThemeContext);

    const [enabled, setEnabled] = useState(false)

    return (
        <div className="mb-4" >
            <div className="relative flex items-start ">
                <div className="flex items-center h-5">
                    <input
                        name={title}
                        id={title}
                        type="checkbox"
                        checked={enabled}
                        onChange={e => {
                            setEnabled(e.target.checked)
                            if (!e.target.checked) onDisable()
                        }}
                        className={`focus:ring-red-500 h-4 w-4 text-red-600 ${darkMode && 'bg-gray-800'}   border-gray-300 rounded`}
                    />
                </div>
                <div className="ml-2 text-sm mr-4">
                    <label htmlFor="comments" className="font-medium text-gray-700 dark:text-white dark:text-gray-300 text-xs">
                        {title}
                    </label>
                </div>
            </div>
            <div className={`sm:col-span-4 ${!enabled && 'hidden'}`}>

                <div className="mt-2 flex rounded-md shadow-sm sm:col-span-2 sm:w-[50%]">

                    <input
                        type="text"
                        name={title}
                        id={title}
                        value={value ? value : ''}
                        onChange={e => {
                            onChange(e.target.value)
                            if (e.target.value.length == 0) onDisable()
                        }}
                        className="flex-1 block w-full focus:ring-red-500 focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300 dark:bg-gray-800 dark:text-white dark:border-black"
                    />
                </div>
            </div>
        </div>
    )
}
export default CheckedTextInput