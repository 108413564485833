import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import { useEffect } from "react";
import VerifyAccountForm from "../VerifyAccountForm/VerifyAccountForm";

const VerifyAccountPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  return (
    <HeaderLayout>
      <section className="min-h-[50vh] md:min-h-[50vh] font-clash_b w-full text-center flex flex-col justify-center items-center">

        <VerifyAccountForm/>
      </section>
    </HeaderLayout>
  );
};

export default VerifyAccountPage;
