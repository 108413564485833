const getHypeTierBGColor = (tier) => {
    switch (tier) {
        case 1:
            return "linear-gradient(to right, #F80043 0.07%, #F1573D 36.04%, #E8BD36 80%)";
        case 2:
            return "linear-gradient(to right, #1464AE 0.07%, #00EDFC 36.04%, #36E8E8 80%)";
        case 3:
            return "linear-gradient(to right,  #8436E8 0.07%, #7000FF 36.04%, #C816C1 80%)";
        case 4:
            return "linear-gradient(to right, #FF5757 0.07%, #BA0000 36.04%, #500909 80%)";
        case 5:
            return "linear-gradient(to right, #CEA846 0.07%, #F4BF00 36.04%, #FBFF2F 80%)";
        default:
            return "transparent";
    }
};

const getHypeTierIcon = (tier) => {
    switch (tier) {
        case 1:
            return "/img/hype_tier/tier_1_icon.svg";
        case 2:
            return "/img/hype_tier/tier_2_icon.svg";
        case 3:
            return "/img/hype_tier/tier_3_icon.svg";
        case 4:
            return "/img/hype_tier/tier_4_icon.svg";
        case 5:
            return "/img/hype_tier/tier_5_icon.svg";
        default:
            return "/img/hype_tier/tier_1_icon.svg";
    }
};

export {getHypeTierBGColor, getHypeTierIcon}