import { useEffect } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import UserProfilePanel from "../UserProfilePanel/UserProfilePanel";

const UserProfilePage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0, behavior: "smooth",
        });
    }, []);
    
    return (
        <HeaderLayout>

            <section >
    
                <UserProfilePanel />
            </section>
        </HeaderLayout>
    );
};

export default UserProfilePage