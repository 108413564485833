import React, { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [toggled, setToggled] = useState(false)

  const toggleDarkMode = () => {
    setToggled(true)
    setDarkMode(!darkMode);
  };




  useEffect(() => {
    if (toggled) {
      sessionStorage.setItem("hypemytoken_theme", darkMode ? "dark" : "light");

      const root = window.document.documentElement;
      if (darkMode) {
        root.classList.remove("light");
        root.classList.add("dark");
      } else {
        root.classList.remove("dark");
        root.classList.add("light");
      }
    }
  }, [darkMode, toggled]);

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
