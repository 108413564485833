import RedirectLogin from "components/sections/RedirectLogin/RedirectLogin";
import { useEffect, useState } from "react";
import httpService from "services/http-service";
import {
  HypeFeed,
  TweetFeed,
} from "../TokenActivitySections/TokenActivitySections";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TokenActivityFeedController = ({ project }) => {
  const [open, setOpen] = useState(false);
  const [recentHypes, setRecentHypes] = useState([]);
  const [recentTweets, setRecentTweets] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    httpService
      .get(`/api/v1/hype/log?token=${project.id}&limit=50`)
      .then((res) => {
        const hypes = res.data.log;
        const hypeActivity = hypes.map((h) => ({
          type: "hype",
          user: h.user,
          content: {
            amount: h.amount,
          },
          timestamp: h.timestamp,
        }));
        setRecentHypes(hypeActivity);
      });
  }, []);

  useEffect(() => {
    httpService.get(`/api/v1/tweet/${project.id}`).then((res) => {
      const tweets = res.data.tweets;
      const tweetActivity = tweets.map((t) => ({
        type: "tweet",
        user: t.username,
        content: t,
        timestamp: t.date,
      }));
      setRecentTweets(tweetActivity);
    });
  }, []);

  return (
    <div className="mb-8 md:mb-16">
      <div
        className="flex justify-between block md:hidden items-center h-12 border-y mb-8 dark:border-gray-800 hover:bg-gray-100 dark:hover:bg-gray-900 hover:cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <p className="dark:text-gray-200">
          Activity ({recentHypes.length + recentTweets.length})
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 dark:text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
          />
        </svg>
      </div>
      {project && (
        <>
          <div className="hidden md:block">
            <StandardActivityFeed
              onClose={() => setOpen(false)}
              open={open}
              project={project}
              tweets={recentTweets}
              hypes={recentHypes}
              onRedirect={setRedirectTo}
            />
          </div>
          <div className="block md:hidden">
            <MobileActivityFeed
              onClose={() => setOpen(false)}
              open={open}
              project={project}
              tweets={recentTweets}
              hypes={recentHypes}
              onRedirect={setRedirectTo}
            />
          </div>
        </>
      )}
      {redirectTo && (
        <RedirectLogin to={redirectTo} onClose={() => setRedirectTo(null)} />
      )}
    </div>
  );
};

const MobileActivityFeed = ({
  onClose,
  open,
  project,
  tweets,
  hypes,
  onRedirect,
}) => {
  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  return (
    <>
      <div
        id="defaultModal"
        tabindex="-1"
        class={` ${
          !open && "hidden"
        } overscroll-none fixed md:max-w-[80vw] bottom-1 left-1/2  w-full -translate-x-1/2 z-50 h-modal`}
      >
        <div class="relative  ">
          <StandardActivityFeed
            onClose={onClose}
            open={open}
            project={project}
            tweets={tweets}
            hypes={hypes}
            onRedirect={(r) => {
              onClose();
              onRedirect(r);
            }}
          />
        </div>
      </div>
      <div
        class={` ${!open && "hidden"} opacity-75 fixed inset-0 z-40 bg-black`}
        onClick={onClose}
      ></div>
    </>
  );
};

const StandardActivityFeed = ({
  project,
  onClose,
  tweets,
  hypes,
  onRedirect,
}) => {
  const tabs = ["Hype", "Tweets"];
  const [current, setCurrent] = useState("Hype");

  return (
    <div class="relative bg-white rounded-lg  md:dark:bg-black md:bg-white  bg-gray-100 dark:bg-[#121212]">
      <div class="flex justify-between items-start px-4 py-2 rounded-t border-b dark:border-gray-800">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <>
              <a
                key={tab}
                onClick={() => setCurrent(tab)}
                className={classNames(
                  tab == current
                    ? "border-red-500 text-red-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab == current ? "page" : undefined}
              >
                {tab} ({tab == "Hype" ? hypes.length : tweets.length})
              </a>
              {current == "Tweets" && tab == "Tweets" ? (
                <div className="w-8 h-8 flex items-center justify-center relative group">
                  <InformationCircleIcon
                    className="h-6 w-6 cursor-pointer dark:stroke-white"
                    aria-hidden="true"
                  />
                  <span className="z-10 absolute hidden group-hover:flex justify-center -top-2 -translate-y-full w-40 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700">
                    If your Tweet doesn't show up, we might be experiencing
                    issues with our API. Don't worry. Your Tweet will still
                    count as Hype, even if it doesn't show up here.
                  </span>
                </div>
              ) : null}
            </>
          ))}
        </nav>
        <button
          type="button"
          onClick={() => onClose()}
          class="text-gray-400 bg-transparent hover:bg-gray-200 block md:hidden hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-toggle="defaultModal"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <div class="p-6 space-y-6 dark:text-white font-mulish text-xs overflow-y-auto h-[50vh] md:h-[35vh] scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 dark:scrollbar-track-gray-700">
        {current == "Hype" ? (
          <HypeFeed
            hypes={hypes}
            title={project.title}
            onRedirect={onRedirect}
          />
        ) : (
          <TweetFeed tweets={tweets} />
        )}
      </div>
    </div>
  );
};
export default TokenActivityFeedController;
