import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
// Context

import httpService from "services/http-service";
import TableBody from "../TableBody/TableBody";
import TableHeader from "../TableHeader/TableHeader";
import TableViewFilter from "../../../../views/Home/TableViewFilter/TableViewFilter";


const TokenTable = ({ loading, listings, onPageChange = () => undefined, noData }) => {
    const [networkFilter, setNetworkFilter] = useState(null)
    const [typeFilter, setTypeFilter] = useState(null)
    const [activeListings, setActiveListings] = useState(listings);
    const [cardsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState(1);
    const [cardOffset, setCardOffset] = useState(0);

    useEffect(() => {
        const filteredListings = !networkFilter && !typeFilter ? listings : listings.filter(l => {
            var n = networkFilter == null ? true : networkFilter == l.network
            var t = typeFilter == null ? true : typeFilter == l.type
            return n && t;
        })
        let endOffset = cardOffset + cardsPerPage;
        setActiveListings(filteredListings.slice(cardOffset, endOffset));
        setTotalPages(Math.ceil(filteredListings.length / cardsPerPage));
    }, [listings, cardsPerPage, cardOffset, networkFilter, typeFilter]);

    useEffect(() => {
        setCardOffset(0)
    }, [listings])

    const handlePageChange = (event) => {
        const newOffset = (event.selected * cardsPerPage) % listings.length;
        
        onPageChange(newOffset)
        setCardOffset(newOffset);
    };

    const handleChangeNetworkFilter = (filter) => {
        setNetworkFilter(filter)
        setCardOffset(0)
    }


    const handleChangeTypeFilter = (filter) => {
        setTypeFilter(filter)
        setCardOffset(0)
    }

    return (

        <>
            <TableHeader
                networkFilter={networkFilter}
                typeFilter={typeFilter}
                changeNetworkFilter={handleChangeNetworkFilter}
                changeTypeFilter={handleChangeTypeFilter}
            />
            <TableBody
                loading={loading}
                listings={activeListings}
                noData={noData}
            />

            <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                forcePage={cardOffset / cardsPerPage}
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                pageCount={totalPages}
                previousLabel="< Prev"
                renderOnZeroPageCount={null}
                containerClassName="w-full border-2 font-mulish flex justify-center items-center px-16 py-4 bg-white dark:bg-[#EAECF008] text-[12px] text-black dark:text-white border-0 border-gray-200 dark:border-gray-800 relative"
                pageLinkClassName="mx-0 md:mx-1 lg:mx-2 p-2 rounded-lg cursor-pointer"
                activeLinkClassName="bg-[#4B4B4B] p-2 rounded-lg cursor-pointer text-white"
                previousClassName="absolute left-4 md:left-8 lg:left-16"
                nextClassName="absolute right-4 md:right-8 lg:right-16"
            />
        </ >
    );
};




export default TokenTable