import { useEffect, useState } from "react";

const BattleTimeRemaining = ({ time }) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let timer = setInterval(() => {
            const remainingMs = (time - Date.now()) / 1000;
            if (remainingMs > 0) {
                setHours(Math.floor(remainingMs / 3600));
                setMinutes(Math.floor((remainingMs % 3600) / 60));
                setSeconds(Math.floor((remainingMs % 3600) % 60));
            } else {
                setHours(0);
                setMinutes(0);
                setSeconds(0);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [time]);

    return (
        <>
            {/* Large */}
            <div className="hidden md:block md:w-max lg:w-[50%] bg-white dark:bg-[#1B191B] mx-auto p-2 rounded">
                <h5 className="flex items-center justify-center font-clash_m">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    {" "}
                    Time Remaining : {hours} hours {minutes} minutes {seconds} seconds
                </h5>
            </div>
            {/* Mobile */}
            <div className="block md:hidden bg-white dark:bg-[#1B191B] mx-auto p-2 rounded">
                <h3 className="flex items-center justify-center font-clash mb-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                    Time Remaining
                </h3>
                <h5 className="flex items-center justify-center font-clash_sb">
                    {hours} hours {minutes} minutes {seconds} seconds
                </h5>
            </div>
        </>
    )
}

export default BattleTimeRemaining