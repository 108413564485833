import axios from "axios";
import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import ForgotPasswordForm from "../ForgotPasswordForm/ForgotPasswordForm";

const ForgotPasswordPage = () => {
    const [password, setPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState('')
    const [changing, setChanging] = useState(false)
    const search = useLocation().search;

    const token = new URLSearchParams(search).get('token');
    const [success, setSuccess] = useState(false)
    const [done, setDone] = useState(false)
    const history = useHistory()


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const handleChange = () => {
        setChanging(true)
        axios.post("/api/v1/auth/reset-password", { token: token, newPassword: password })
            .then(r => {
                setSuccess(r.data)
                setDone(true)
            }).catch(() => {
                setSuccess(false)
                setDone(true)
            })
    }

    return (
        <HeaderLayout>
            <section className="min-h-[50vh] md:min-h-[50vh] font-clash_b w-full text-center flex flex-col justify-center items-center">
                <ForgotPasswordForm/>
            </section>
        </HeaderLayout >
    );
};

export default ForgotPasswordPage;