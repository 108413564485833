import axios from "axios";
import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

const ForgotPasswordForm = () => {
    const [password, setPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState('')
    const [changing, setChanging] = useState(false)
    const search = useLocation().search;

    const token = new URLSearchParams(search).get('token');
    const [success, setSuccess] = useState(false)
    const [done, setDone] = useState(false)
    const history = useHistory()

    const handleChange = () => {
        setChanging(true)
        axios.post("/api/v1/auth/reset-password", { token: token, newPassword: password })
            .then(r => {
                setSuccess(r.data)
                setDone(true)
            }).catch(() => {
                setSuccess(false)
                setDone(true)
            })
    }

    return (
        <form class="flex items-center justify-center w-full max-w-md" onSubmit={e => e.preventDefault()}>
            <div class="p-4 text-left bg-white dark:bg-black ">

                <h2 class="mt-6 text-center text-3xl tracking-tight font-bold text-gray-900 dark:text-gray-200">Reset your password</h2>
                <div class="mt-4">
                    <div >
                        {done ?
                            success
                                ?
                                <>
                                    <p class="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                                        Your password is succesfully changed. You can now
                                        <a class="font-medium text-red-600 hover:text-red-500" onClick={() => history.push("/login")}> log in </a>
                                    </p>
                                </>

                                :
                                <>
                                    <p class="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                                        The reset token is invalid or has expired.
                                    </p>
                                    <button class="px-6 w-full py-2 mt-4 text-white bg-red-600 rounded-lg hover:bg-red-900" onClick={() => history.push('/')}>Back to Home</button>

                                </>
                            :
                            <>

                                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-red-600  focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 " />
                                <input type="password" placeholder="Retype Password" value={checkPassword} onChange={(e) => setCheckPassword(e.target.value)} class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-red-600  focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 " />
                                {(checkPassword.length > 0 && password !== checkPassword) && <p class="text-red-500 text-xs mt-2 italic">Passwords do not match!</p>}

                                <button
                                    disabled={!(password.length > 5 && checkPassword === password)}
                                    className="disabled:bg-slate-200 disabled:text-slate-500 disabled:border-slate-200 px-6 w-full py-2 mt-4 text-white bg-red-600 rounded-lg hover:bg-red-900"
                                    onClick={handleChange}
                                >

                                    {changing ?
                                        <div role="status" className="flex items-center justify-center">
                                            <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <>Changing password..</>
                                        </div>
                                        :
                                        <>Change password</>
                                    }
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ForgotPasswordForm;