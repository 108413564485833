import SubmissionReview from "views/Submission/SubmissionPage/SubmissionPage";
import { useEffect, useState } from "react";
import httpService from "services/http-service";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import AdministrationPanel from "../AdministrationPanel/AdministrationPanel";


const AdministrationPage = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0, behavior: "smooth",
        });
    }, []);


    return (
        <HeaderLayout>
            <AdministrationPanel/>
        </HeaderLayout>
    );
};
export default AdministrationPage;
