import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import { LoginForm } from "views/Login/LoginForm/LoginForm";
import { useContext, useEffect, useState } from "react";


const LoginPage = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  return (
    <HeaderLayout>
      <section className="min-h-[50vh] md:min-h-[50vh] font-clash_b w-full text-center flex flex-col justify-center items-center">
        <LoginForm />
      </section>
    </HeaderLayout>
  );
};

export default LoginPage;
