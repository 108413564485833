import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha";


const Captcha = ({ open, onClose, onValidate }) => {



    return (
        <>

            <div id="defaultModal" tabIndex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 -translate-y-1/2 w-full max-w-2xl -translate-x-1/2 z-50 h-modal `} >
                <div class="relative p-4 flex justify-center ">
                    {open &&
                        <ReCAPTCHA
                            sitekey="6LeE5lIiAAAAANlDDVkE5tmpmoDJ-tTp2FUuXOH_"
                            onChange={onValidate}
                        />
                    }
                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>
        </>
    )
}


export default Captcha