
const SubmissionBattleIcon = ({project}) => {

    return (
        <div className="bg-white py-4 pb-4 dark:bg-[#191616] flex-col justify-center content-center">
            <h2 id="timeline-title" className="text-lg mx-auto text-center font-medium text-gray-900 dark:text-white mb-2">
                Battle Logo
            </h2>

            <img
                src={project.battleLogo ? `https://hypemytoken.s3.eu-central-1.amazonaws.com/${project.battleLogo}.jpg` : `/img/battle/hb_default.png`}
                className="h-[165px] w-[248px] mb-4 mx-auto"

                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/img/battle/hb_default.png";
                }}
                alt="Hype Battle Project"
            />


        </div>
    )
}

export default SubmissionBattleIcon