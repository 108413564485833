import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
// Context

import httpService from "services/http-service";
import TableBody from "../../../components/tables/TokenTable/TableBody/TableBody";
import TableHeader from "../../../components/tables/TokenTable/TableHeader/TableHeader";
import TableViewFilter from "../TableViewFilter/TableViewFilter";
import TokenTable from "../../../components/tables/TokenTable/TokenTable/TokenTable";


const FrontPageTokenTable = () => {
    const [activeFilter, setActiveFilter] = useState("listed");
    const [listings, setListings] = useState([]);
    const [networkFilter, setNetworkFilter] = useState(null)
    const [typeFilter, setTypeFilter] = useState(null)
    const [cardOffset, setCardOffset] = useState(0);
    const elementRef = useRef(null)
    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setData([])
        setloading(true)
        

        httpService.get(`/api/v1/token?order=${activeFilter}`).then((res) => {
            if (activeFilter == "listed")
                res.data.listings.sort((a, b) => b.hypes - a.hypes)
            let projectListings = res.data.listings.map((l, i) => ({ rank: i + 1, ...l }))
            const filteredListings = !networkFilter && !typeFilter ? projectListings : projectListings.filter(l => {
                var n = networkFilter == null ? true : networkFilter == l.network
                var t = typeFilter == null ? true : typeFilter == l.type
                return n && t;
            })
            setData(filteredListings);
            setloading(false)

        });
    }, [activeFilter, networkFilter, typeFilter]);

    const handleChangeFilter = (filter) => {
        console.log(filter)
        setActiveFilter(filter)
        setCardOffset(0)
    }

    const handleChangeNetworkFilter = (filter) => {
        setNetworkFilter(filter)
        setCardOffset(0)
    }


    const handleChangeTypeFilter = (filter) => {
        setTypeFilter(filter)
        setCardOffset(0)
    }


    return (

        <div className="min-h-[300px] md:min-h-auto lg:min-h-auto my-4 rounded-xl border border-gray-200 dark:border-gray-800">


            <TableViewFilter
                handleChangeFilter={handleChangeFilter}
                activeFilter={activeFilter}
                elementRef={elementRef}
                networkFilter={networkFilter}
                typeFilter={typeFilter}
                handleChangeNetworkFilter={handleChangeNetworkFilter}
                handleChangeTypeFilter={handleChangeTypeFilter}
            />
            <TokenTable
                onPageChange={() => window.scrollTo(0, elementRef.current.offsetTop - 72)}
                loading={loading}
                listings={data}
                noData={<p className="dark:text-gray-300 w-[100%] text-gray-600">No tokens have been listed yet. <br /> Head over to the "All" section to see what tokens have been submitted.</p>}

            />
        </div >
    );
};




export default FrontPageTokenTable