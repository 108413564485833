import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import Graph from "components/presentation/HypeGraphSmall/HypeGraphSmall";
import { HypeButton } from "components/buttons/HypeButton/HypeButton";
import { Link } from "react-router-dom";
import TokenHypeMeter from "views/Token/TokenHypeMeter/TokenHypeMeter";
import TokenTable from "../../../../../views/Home/FrontPageTokenTable/FrontPageTokenTable";
import TokenTableHypeMeter from "../../TokenTableHypeMeter/TokenTableHypeMeter";
// Context

const truncate = (text) =>
    text.length > 5 ? `${text.substring(0, 5)}...` : text;

const MobileTokenCard = ({ index, link, avatar, allTimeHigh, title, type, darkMode, currentHype, projectId, chain, ticker, changeCurrentHype, tier  }) => {
    return (
        < div
            className="flex-col md:hidden px-4 py-4 bg-white dark:bg-[#EAECF008] text-[12px] text-black dark:text-white border-b border-gray-200 dark:border-gray-800 relative" >
            <div className="flex justify-between items-center ">
                <div className="w-full">
                    <h6 className="absolute top-1 left-1">{index}</h6>
                    <Link to={link}>
                        <div className="flex flex-wrap items-start cursor-pointer justify-between">
                            <div className="flex mb-4 mr-4">
                                <Avatar id={avatar} className="w-10 h-10 mr-2" />
                                <div className="">
                                    <h5 className="font-clash_b">{title}</h5>
                                    <div
                                        className={`text-[10px] font-mulish_b uppercase ${type.toLowerCase() === "token"
                                            ? "text-token"
                                            : type.toLowerCase() === "nft"
                                                ? "text-nft"
                                                : "text-red-500"
                                            }`}
                                    >
                                        {type}
                                    </div>
                                </div>
                            </div>
                            <div className="flex mr-4 w-full xs:w-auto">
                                <div className={`flex items-center mr-4`}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 mr-1"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                                        />
                                    </svg>
                                    <span>{chain}</span>
                                </div>
                                <div className="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 mr-1"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                        />
                                    </svg>
                                    <span>{ticker}</span>
                                </div>
                            </div>
                        </div>

                    </Link>



                </div>
                <div>
                    <div className="flex items-center justify-end">

                        <HypeButton
                            styling={"ml-4 w-10 h-10"}
                            projectId={projectId}
                            handleClick={() => changeCurrentHype(currentHype + 1)}
                        />
                    </div>
                    <div className="mt-4 flex items-center text-[12px]">


                    </div>
                </div>
            </div >
            <div className="flex items-center justify-between mt-2">
                <div className="w-[70%] min-w-[12em]">

                    <TokenTableHypeMeter id={projectId} hypeTier={tier} />

                </div>
                <div className="flex items-center text-[14px] text-[#FF5B09] dark:text-[#F80043] font-mulish_sb">

                    <img
                        className="w-6 h-6 mr-2"
                        src={
                            darkMode ? "/img/hype_count_dark.svg" : "/img/hype_count.svg"
                        }
                        alt="Hype Count"
                    />{" "}
                    {Number(currentHype).toLocaleString('en')}
                </div>

            </div>

        </div >
    )
}

export default MobileTokenCard