import { useState } from "react"
import textfile from "assets/termsandconditions.txt"


const TermsAndConditions = ({ open, onClose, onAgree }) => {
    const [text, setText] = useState('');
    fetch(textfile)
        .then((response) => response.text())
        .then((textContent) => {
            setText(textContent);
        });



    return (
        <>

            <div id="defaultModal" tabindex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 -translate-y-1/2 w-full max-w-2xl -translate-x-1/2 z-50 h-modal `} >
                <div class="relative p-4  ">
                    <div class="relative bg-white rounded-lg shadow dark:bg-[#212121]">
                        <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                            <h3 class="text-xl font-clash_b text-gray-900 dark:text-white">
                                Terms & Conditions
                            </h3>
                            <button type="button" onClick={() => onClose()} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div class="p-6 space-y-6 dark:text-white font-mulish text-xs overflow-y-auto h-[50vh]">
                            {text.split(/\r|\r\n|\n/).map(t => <p className="mb-3">{t}</p>)}
                        </div>
                        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                            <button data-modal-toggle="defaultModal" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={() => {
                                onClose()
                                onAgree()
                            }}>I agree</button>
                            <button onClick={() => onClose()} data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>
                        </div>
                    </div>
                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>
        </>
    )
}


export default TermsAndConditions