import { useState, useRef, useEffect } from 'react';
import httpService from 'services/http-service';
import GiveAwayEntry from 'views/GiveAway/GiveAwayEntry/GiveAwayEntry';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css"

const GiveAwayCarousel = () => {
    const [giveAways, setGiveAways] = useState([])

    useEffect(() => {
        httpService.get("/api/v1/giveaway").then(res => setGiveAways(res.data.giveAways))
    }, [])


    return (
        <>

            <Swiper
                spaceBetween={1}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper md:hidden block "
            >
                {giveAways.map(ga => (
                    <SwiperSlide >

                        <GiveAwayEntry
                            left={ga.numberOfWins}
                            type={ga.type}
                            image={ga.resource}
                            description={ga.description}
                            criteria={ga.criteria}
                            link={ga.link}
                            title={ga.title}
                            date={ga.end}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Swiper
                spaceBetween={1}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper md:block hidden "
            >
                {giveAways.map(ga => (
                    <SwiperSlide >

                        <GiveAwayEntry
                                    left={ga.numberOfWins}
                                    type={ga.type}
                            image={ga.resource}
                            description={ga.description}
                            criteria={ga.criteria}
                            link={ga.link}
                            title={ga.title}
                            date={ga.end}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

        </>
    );
};

export default GiveAwayCarousel;
