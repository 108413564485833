import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import httpService from "../../../services/http-service";
// Context
import { ThemeContext } from "../../../utils/theme";
import SimilarProjects from "../SimilarTokens/SimilarTokens";
import Advertisement from "components/sections/AdvertisementSection/AdvertisementSection";
import { SEO } from "components/presentation/SEO/SEO";
import TokenAdminPanel from "../TokenAdminPanel/TokenAdminPanel";
import AdvertisementBanner from "components/sections/AdvertisementBanner/AdvertisementBanner";
import TokenHeader from "../../../components/sections/TokenHeader/TokenHeader";
import TokenDetails from "../../../components/sections/TokenDetails/TokenDetails";
import TokenHypeChart from "../TokenHypeChart/TokenHypeChart";
import TokenActivityFeedExp from "../TokenActivityFeed/TokenActivityFeed";
import TokenActivityFeedController from "../TokenActivityFeedController.jsx/TokenActivityFeedController";

const TokenPageLayout = () => {
  let { id } = useParams();
  const { darkMode } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);

  useEffect(() => {
    setLoading(true);
    httpService
      .get(`/api/v1/token/${id}`)
      .then((res) => {
        setProject(res.data.result[id]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setProject(null);
      });
  }, [id]);

  if (!project) {
    return (
      <section className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 md:-mt-16 lg:mt-0 lg:my-0 lg:mb-16">
        <AdvertisementBanner mobileVisible={true} location={"TOKEN_PAGE"} />
        {loading == false && (
          <div className="flex flex-col items-center justify-center my-16">
            <h3 className="text-2xl font-clash_b text-hmt_text_bold_light dark:text-hmt_text_bold_dark mb-4">
              Project Not Found
            </h3>
            <Link to="/">
              <button className="font-mulish_sb px-2 py-1 rounded-md border border-gray-500 text-hmt_text_color_light dark:text-hmt_text_color_dark ">
                Go Home
              </button>
            </Link>
          </div>
        )}
        <AdvertisementBanner mobileVisible={true} location={"TOKEN_PAGE"} />
      </section>
    );
  }
  return (
    <section className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 ">
      {/* <Advertisement mobileVisible={true} /> */}
      <SEO
        title={`${project.title} on HypeMyToken`}
        description="The platform to Hype up your favourite crypto tokens"
      />

      <AdvertisementBanner location={"TOKEN_PAGE"} />
      <TokenHeader project={project} darkMode={darkMode} />
      <TokenDetails project={project} darkMode={darkMode} />
      <TokenActivityFeedController project={project} />
      <TokenHypeChart project={project} />
      <SimilarProjects darkMode={darkMode} />
      <TokenAdminPanel id={id} />
    </section>
  );
};

export default TokenPageLayout;
