import { useEffect, useState } from "react";
import httpService from "services/http-service";
import { getHypeTierBGColor, getHypeTierIcon } from "utils/HypeMeterUtils";


const TokenTableHypeMeter = ({ id, hypeTier }) => {
    const [hypePercent, setHypePercent] = useState(0);
    const [bgColor, setBgColor] = useState("transparent");




    useEffect(() => {
        if (hypeTier) {
            let hp = 100;
            if (hypeTier.next) {
                hp = Math.ceil((hypeTier.hypes / (hypeTier.next - 1)) * 100);
            }
            setHypePercent(hp);
            setBgColor(getHypeTierBGColor(hypeTier.tier));
        }
    }, [hypeTier]);
    return (
        <div className=" w-full ">
            <div className="text-black dark:text-white flex items-center justify-between">
                <div className="w-[100%] md:w-[90%] bg-gray-100 dark:bg-gray-900 rounded-full relative">
                    <div
                        style={{
                            width: `${hypePercent}%`,
                            backgroundImage: bgColor,
                        }}
                        className="h-3 md:h-3 rounded-full relative"
                    >
                        {hypePercent > 15 && (
                            <img
                                className={`block md:block w-6 h-6 md:w-6 md:h-6 absolute top-0 translate-y-[-40%] md:translate-y-[-50%] translate-x-[20%] right-0`}
                                src={getHypeTierIcon(hypeTier?.tier)}
                                alt="HMT Icon"
                            />
                        )}
                    </div>
                </div>

            </div>


        </div>
    );
};

export default TokenTableHypeMeter