import {
  LightMode,
  Darkmode,
} from "components/layout/ThemeToggler/ThemeToggler";
import { useContext, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { ThemeContext } from "../../../utils/theme";
import AuthService from "../../../services/AuthService";
import Footer from "../Footer/Footer";
import SideBar from "../Sidebar/SideBar";
import TokenSearchBox from "components/forms/TokenSearchBox/TokenSearchBox";
import httpService from "services/http-service";
import Header from "../Header/Header";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HeaderLayout = ({ children, thankyouIsActive = false }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const profileMenu = useRef(null);
  const [listings, setListings] = useState({});
  const [maintenance, setMaintenance] = useState(false);

  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const history = useHistory();

  useEffect(() => {
    // httpService.get("/api/v1/health/up").then(() => setMaintenance(false)).catch(() => setMaintenance(true))
  }, []);

  useEffect(() => {
    httpService.get(`/api/v1/token`).then((res) => {
      let projectListings = res.data.listings.map((l) => ({
        id: l.id,
        title: l.title,
      }));
      setListings(projectListings);
    });
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileMenu.current && !profileMenu.current.contains(event.target)) {
        setOpenProfile(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenu]);

  return (
    <div class="min-h-full font-clash_sb">
      {!maintenance && <Header />}
      <main>
        <div
          className={classNames("max-w-7xl mx-auto py-1  sm:px-6 lg:px-8", {
            "lg:min-h-screen": !thankyouIsActive,
          })}
        >
          {children}
        </div>
        <div
          id="defaultModal"
          tabIndex="-1"
          class={` ${
            !maintenance && "hidden"
          } overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 -translate-y-1/2 w-full max-w-7xl -translate-x-1/2 z-50 h-modal `}
        >
          <div class="relative  ">
            <div className=" dark:bg-[#212121] bg-gray-200 w-[90vw] h-[90vh] rounded-lg max-w-7xl p-8">
              <div className="flex-col justify-center ">
                <div className="px-4 sm:px-6 md:px-0 border-b w-full text-center pb-4">
                  <h1 className="text-2xl leading-6 font-bold text-gray-900 dark:text-gray-200 mb-2">
                    Down for Maintenance!
                  </h1>
                </div>

                <p className="dark:text-gray-100 text-center mt-8">
                  HypeMyToken is currently down for maintenance. Please come
                  back later to check out our new improvements!
                </p>
                <p className="dark:text-gray-100 text-center mt-8">
                  Note that while we are down, it is still possible to earn Hype
                  by tweeting about your token. It will be processed as soon as
                  we're back.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class={` ${
            !maintenance && "hidden"
          } opacity-75 fixed inset-0 z-40 bg-black`}
          onClick={() => undefined}
        ></div>
      </main>
      <Footer />
    </div>
  );
};

export default HeaderLayout;
