import axios from "axios"


export default function GiveAwayResourceField({ resource, setResource }) {
    const handleChange = async (e) => {
        const file = e.target.files[0];
        setResource({ file: file, url: URL.createObjectURL(file) })
    }


    return (
        <div className="flex-col items-center">
            <label htmlFor="resource" className="block text-md text-gray-700 dark:text-gray-400 ">

                Resource
                {!resource && <span className={`text-red-600 text-xs ml-1`}>*</span>}
            </label>
            <div className="mt-4">
                <label
                    htmlFor="resource"
                    className="relative cursor-pointer border-2 bg-white dark:bg-black rounded-md border border-red-500 dark:border-red-800 px-2 py-1 font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                >
                    <span>Upload</span>
                    <input
                        id="resource"
                        name="resource"
                        accept="image/*" type="file"
                        className="sr-only"
                        onChange={handleChange}
                    />
                </label>
            </div>

        </div >
    )
}

