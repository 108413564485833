import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import { useEffect } from "react";
import RegisterForm from "../RegisterForm/RegisterForm";


const RegisterPage = () => {


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


  return (
    <HeaderLayout>
      <section className="h-[80vh] font-clash_b w-full text-center flex flex-col justify-center items-center">
        <RegisterForm />
      </section>
    </HeaderLayout>
  );
};

export default RegisterPage;
