import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import Graph from "components/presentation/HypeGraphSmall/HypeGraphSmall";
import { HypeButton } from "components/buttons/HypeButton/HypeButton";
import { Link } from "react-router-dom";
import TokenHypeMeter from "views/Token/TokenHypeMeter/TokenHypeMeter";
import TokenTableHypeMeter from "../../TokenTableHypeMeter/TokenTableHypeMeter";

const LargeTokenCard = ({ index, allTimeHigh, link, avatar, title, type, currentHype, projectId, chain, ticker, changeCurrentHype, tier }) => {
    return (
        <div
            className="hidden font-mulish md:flex justify-between items-center px-2 py-4 bg-white dark:bg-[#EAECF008] text-[12px] text-black dark:text-white border-b border-gray-200 dark:border-gray-800">
            <div className="w-[5%] text-center flex justify-center items-center">
                {index}
            </div>
            <div className="w-[20%] text-center font-mulish_b ">
                <Link to={link}>
                    <div className="flex items-center text-left mr-2">
                        <Avatar id={avatar} className="w-10 h-10 mr-2" />
                        {title}
                    </div>
                </Link>
            </div>
            <div className="w-[15%] text-center flex items-center">{chain} </div>
            <div className="w-[10%] text-center  flex items-center">{ticker} </div>
            <div
                className={`w-[15%] text-center font-mulish_b uppercase ${type.toLowerCase() === "token"
                    ? "text-token"
                    : type.toLowerCase() === "nft"
                        ? "text-nft"
                        : "text-red-500"
                    }`}
            >
                {type}
            </div>
            <div className="w-[20em] text-center flex justify-center items-center">
                {<TokenTableHypeMeter id={projectId} hypeTier={tier} />}
            </div>
            <div className="w-[15%] text-center flex items-center">
                <div className="md:w-[60px] lg:w-[100px] flex justify-end">
                    <HypeButton styling={"w-10 h-10"} projectId={projectId}
                        handleClick={() => changeCurrentHype(currentHype + 1)} />
                </div>

                <span className="ml-4 text-[14px] text-[#FF5B09] dark:text-[#F80043] font-mulish_sb">
                    {Number(currentHype).toLocaleString('en')}
                </span>
            </div>
        </div>
    )
}

export default LargeTokenCard