import TokenTable from "components/tables/TokenTable/TokenTable/TokenTable";
import { useEffect, useRef, useState } from "react";
// Context
import { Link, useParams } from "react-router-dom";

import httpService from "services/http-service";



const FollowingTokensTable = () => {
    const [activeFilter, setActiveFilter] = useState("listed");
    let { user } = useParams();
    const [notSharing, setNotSharing] = useState(false)

    const [loading, setloading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setData([])
        setloading(true)
        httpService.get(`/api/v1/user/token/follow/${user}`).then((res) => {

            res.data.listings.sort((a, b) => b.hypes - a.hypes)
            let projectListings = res.data.listings.map((l, i) => ({ rank: i + 1, ...l }))
            setData(projectListings);
            setloading(false)

        }).catch(e => {
            if (e.response && e.response.status == 403) {
                setNotSharing(true)
            }
        });
    }, [activeFilter]);


    return (
        <div className="mt-8">
            {
                notSharing
                    ?
                    <div className="min-h-[300px] md:min-h-auto lg:min-h-auto my-4">
                        <p className="dark:text-gray-300 w-[100%] text-gray-600 text-center">This user does not share which tokens he or she is following.</p>
                    </div>
                    :
                    <div className="min-h-[300px] md:min-h-auto lg:min-h-auto my-4 rounded-xl border border-gray-200 dark:border-gray-800">

                        <TokenTable
                            loading={loading}
                            listings={data}
                            noData={<p className="dark:text-gray-300 w-[100%] text-gray-600">This user is not following any tokens yet.</p>}
                        />
                    </div >

            }
        </div>
    );
};




export default FollowingTokensTable