import { Redirect, Route } from "react-router-dom";
import AuthService from "services/AuthService";
// Pages
// Components

function AdminRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={() => {
                return AuthService.hasRole("ROLE_ADMIN") === true ? (
                    children
                ) : (
                    <Redirect to="/login" />
                );
            }}
        />
    );
}

export default AdminRoute