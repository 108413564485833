import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import { useEffect, useState } from "react";
import { RegisterForm } from "../../Register/RegisterForm/RegisterForm";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import AuthService from "services/AuthService";

const VerifyAccountForm = () => {
  const [verifiying, setVerifying] = useState(true)
  const [verified, setVerified] = useState(false)
  const [error, setError] = useState(false)
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const history = useHistory()

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      AuthService.logout();
    }
    axios.post('/api/v1/auth/verify?code=' + code).then((r) => {
      if (r.data)
        setVerified(true)
      else
        setError(true)
      setVerifying(false)
    }).catch(() => {
      setVerifying(false)
      setError(true)
    })
  }, [])

  return (

    <div className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 md:-mt-16 lg:mt-0 lg:my-0 lg:mb-16 ">

      <h1 className=" flex justify-center text-2xl dark:text-gray-300 mt-48" >
        {verifiying ? "Verifiying your registration" : verified ? "Thanks for your verification!" : "Invalid verification code!"}

      </h1>
      <h5 className="flex justify-center text-center font-mulish dark:text-gray-200">
        {verifiying ? "Please wait.." : verified ? "You can now login" : "Your verification code is invalid or has expired."}
      </h5>
      {verified &&
        <div class="flex justify-center  mb-8">
          <div class="flex items-center mt-8">
            <button
              class="bg-red-500 hover:bg-red-700 text-white font-bold font-mulish py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => {
                history.push('/login')
              }

              }
            >
              Login
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default VerifyAccountForm;
