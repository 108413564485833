import { useEffect, useState } from 'react'
import httpService from 'services/http-service'
import { useHistory } from "react-router-dom";
import TokenAvatar from 'components/presentation/TokenAvatar/TokenAvatar';
import { Link } from "react-router-dom";


const UserOverviewSection = ({ username }) => {
    const history = useHistory()
    const [stats, setStats] = useState([])
    const [topTokens, setTopTokens] = useState([])

    useEffect(() => {
        if (topTokens.length > 0) {

        }
    }, [topTokens])

    useEffect(() => {
        httpService.get('/api/v1/hype/stats/' + username).then((res) => {
            var total = res.data.total
            var avg = res.data.avgPerDay
            var tokens = res.data.tokens
            var active = res.data.active

            httpService.get(`/api/v1/token/${res.data.mostHypedTokens.join(',')}`).then((res2) => {
                var tokens = []
                res.data.mostHypedTokens.forEach(t => {
                    tokens.push(res2.data.result[t])
                })
                setTopTokens(tokens.filter(t => t !== undefined))
            })
            setStats([
                { name: 'Avg. Hypes per Day', stat: avg },
                { name: 'Total Hypes', stat: total },
                { name: 'Tokens Hyped', stat: tokens },
                { name: 'Active Hypes', stat: active },

            ])
        })
    }, [])

    return (
        <div className='flex flex-col'>
            <div className='flex flex-col md:flex-row md:justify-around'>
                <div className='w-full md:w-[60%] mr-16'>
                    <h1 className='pt-8 dark:text-white w-full  dark:border-[#212121] pb-2 mb-2 text-lg font-clash_b'>Statistics</h1>

                    <div className=''>
                        <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-3 ">
                            {stats.filter(i => i.stat != null).map((item) => (
                                <div
                                    key={item.name}
                                    onClick={() => item.link ? history.push(item.link) : undefined}
                                    className={`overflow-hidden rounded-lg bg-white dark:bg-[#212121] dark:text-gray-100 px-4 py-4 shadow sm:p-4 ${item.link && 'cursor-pointer'}`}
                                >
                                    <dt className=" text-md text-gray-500  dark:text-gray-300">{item.name}</dt>
                                    <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900  dark:text-gray-200">{item.stat}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
                <div>
                    <p className="pt-8 dark:text-white w-full  dark:border-[#212121] pb-2 mb-2 text-lg font-clash_b">Top 3 Most Hyped Tokens</p>
                    <div className='mt-5 '>
                        {topTokens.map((t, i) =>
                            <Link to={`/token/${t.id}`} >

                                <div className="flex items-center mb-1">
                                    <TokenAvatar id={t.avatar} className="w-10 h-10 border-2 border-gray-200 dark:border-gray-800 dark:bg-white bg-gray-300" />
                                    <p className="ml-2 dark:text-gray-100 font-clash_b text-md">{t.title}</p>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}


export default UserOverviewSection