import { useHistory } from "react-router-dom";

const RegistrationSuccessful = () => {
  const history = useHistory();
  return (
    <div className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 md:-mt-16 lg:mt-0 lg:my-0 lg:mb-16 ">
      <h1 className=" flex justify-center text-2xl dark:text-gray-300 ">
        Thank you for signing up!
      </h1>
      <h5 className="flex justify-center text-center font-mulish dark:text-gray-200">
        A verification e-mail will be sent to your e-mail address.
      </h5>
      <h5 className="flex justify-center text-center font-mulish_b dark:text-gray-200">
        If you do not see the email in a few minutes, check your "junk mail"
        folder or "spam" folder.
      </h5>
      <div class="flex justify-center  mb-8">
        <div class="flex items-center mt-8">
          <button
            class="bg-red-500 hover:bg-red-700 text-white font-bold font-mulish py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              history.push("/");
            }}
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccessful;
