import { useEffect, useState, useRef } from "react";
import httpService from "services/http-service";
import AuthService from "services/AuthService";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import * as dayjs from "dayjs";

import HeaderLayout from "../../components/layout/HeaderLayout/HeaderLayout";
import Form from "./Form";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const frequencies = [
  { value: "hypebattle", label: "Hype Battle" },
  { value: "banner", label: "Banner" },
];

const features = {
  TOKEN_PAGE: [
    "Banner displayed on TokenPage",
    "Payment in BUSD (Binance Smart Chain)",
  ],
  FRONT_PAGE: [
    "Banner displayed on Homepage",
    "Payment in BUSD (Binance Smart Chain)",
    "10K+ View Per Day",
  ],
};

const locationToPageMap = {
  TOKEN_PAGE: "Token Page",
  FRONT_PAGE: "Home Page",
};

const locationToDescriptionMap = {
  TOKEN_PAGE:
    "This banner will show on the top of every individual token page. This banner will rotate together with other banners that have been purchased for the Token page on each specific date(s).",
  FRONT_PAGE:
    "Our most exclusive banner. This banner will show on the home page of our platform. There can only be one banner on the home page at the same time. Please find a date(s) that is unoccupied.",
};

const AdvertisingPage = () => {
  const [advertisementLocations, setAdvertisementLocation] = useState([]);
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [paymentPageUrl, setPaymentPageUrl] = useState(null);
  const [activeForm, setActiveForm] = useState("");
  const [occupation, setOccupation] = useState([]);
  const [thankyouIsActive, setThankyouIsActive] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isLoggedIn());
  const formRef = useRef();

  useEffect(() => {
    const handleLocalStorageChange = () => {
      setIsLoggedIn(localStorage.getItem("user") != null);
    };

    window.addEventListener("storage", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      httpService.get("/api/v1/user/ad/locations").then((res) => {
        setAdvertisementLocation(res.data.locations);
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (activeForm) {
      httpService
        .get(`/api/v1/user/ad/occupation/${activeForm}`)
        .then((res) => {
          const formmattedDates = res?.data?.occupiedDates?.map(
            (dateObject) => ({
              startDate: dayjs(dateObject?.start).format("YYYY-MM-DD"),
              endDate: dayjs(dateObject?.end).format("YYYY-MM-DD"),
            })
          );
          setOccupation(formmattedDates);
        });
    }
  }, [activeForm]);

  function submitAdRequest(values, formikbag) {
    const { resetForm } = formikbag;

    const newValues = {
      ...values,
      advertisementLocation: activeForm,
      start: new Date(values.start).getTime(),
      end: new Date(values.end).getTime(),
    };

    httpService.post(`/api/v1/user/ad/request`, newValues).then((res) => {
      if (res?.status === 200) {
        resetForm();
        setPaymentPageUrl(res.data.fullUrl);
        setThankyouIsActive(true);
        window.open(res.data.fullUrl, "_blank");
      }
    });
  }

  function handleChangeForm(name) {
    setActiveForm(name);
    formRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  console.log("isLoggedIn", isLoggedIn);

  return (
    <HeaderLayout thankyouIsActive={thankyouIsActive}>
      <section
        className={classNames(
          "min-h-[300px] md:min-h-[500px] font-clash_b w-full",
          {
            "lg:min-h-screen": !thankyouIsActive,
          }
        )}
      >
        <div className="bg-white dark:bg-black py-16 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {thankyouIsActive ? (
              <>
                <div className="mx-auto max-w-4xl text-center">
                  <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600 dark:text-indigo-400">
                    Thank you for your request! After we receive your payment,
                    it may take up to 1 hour for our system to pick up your
                    advertisement.
                  </h2>
                  <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-300">
                    Please complete the payment{" "}
                    <a
                      className="text-lg underline underline-offset-1 leading-8 text-[#FF5B0A]"
                      href={paymentPageUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      here
                    </a>{" "}
                    so we can successfully process your ad.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="mx-auto max-w-4xl text-center">
                  <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600 dark:text-indigo-400">
                    Pricing
                  </h2>
                  <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
                    Online Advertising Options
                  </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-300">
                  Choose an advertisement option that’s packed with the best
                  features for engaging your audience, creating community
                  loyalty, and driving interaction.
                </p>
                <div className="mt-16 flex justify-center">
                  <div className="flex flex-col">
                    <p className="text-gray-900 dark:text-white mb-2">
                      Choose type of advertisement
                    </p>
                    <RadioGroup
                      value={frequency}
                      onChange={setFrequency}
                      className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200 dark:text-white"
                    >
                      <RadioGroup.Label className="sr-only">
                        Advertisement options
                      </RadioGroup.Label>
                      {frequencies.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option}
                          className={({ checked }) =>
                            classNames(
                              checked
                                ? "bg-indigo-600 text-white"
                                : "text-gray-500",
                              "cursor-pointer rounded-full py-1 px-2.5"
                            )
                          }
                        >
                          <span>{option.label}</span>
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </div>
                </div>
                {frequency?.value === "banner" ? (
                  <>
                    <div
                      className={classNames(
                        advertisementLocations?.length
                          ? "lg:grid-cols-2"
                          : "lg:grid-cols-1",
                        "isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none"
                      )}
                    >
                      {advertisementLocations?.length ? (
                        advertisementLocations?.map((location, index) => (
                          <div
                            key={`ad-type-${index}`}
                            className={classNames(
                              activeForm === location?.name
                                ? "ring-2 ring-indigo-600"
                                : "ring-1 ring-gray-200",
                              "rounded-3xl p-8 xl:p-10"
                            )}
                          >
                            <div className="flex items-center justify-between gap-x-4">
                              <h3
                                key={`ad-type-${index}-title`}
                                className={classNames(
                                  activeForm === location?.name
                                    ? "text-indigo-600 dark:text-white"
                                    : "text-gray-900 dark:text-white",
                                  "text-lg font-semibold leading-8"
                                )}
                              >
                                {locationToPageMap[location.name]}
                              </h3>
                              {location.mostPopular ? (
                                <p className="rounded-full bg-indigo-600/10 py-1 px-2.5 text-xs font-semibold leading-5 text-indigo-600 dark:text-white">
                                  Most popular
                                </p>
                              ) : null}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-gray-300">
                              {locationToDescriptionMap[location.name]}{" "}
                              <a
                                className="text-sm font-semibold leading-6 text-[#FF5B0A]"
                                href="https://hypemytoken.medium.com/announcement-automated-advertisement-system-97b3c715f01f"
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                More Info <span aria-hidden="true">→</span>
                              </a>
                            </p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                              <span className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white">
                                $ {location.pricePerDay}
                              </span>
                              <span className="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-300">
                                {`per Day`}
                              </span>
                            </p>
                            <button
                              onClick={() => handleChangeForm(location.name)}
                              aria-describedby={location.id}
                              className={classNames(
                                activeForm === location?.name
                                  ? "bg-indigo-600 dark:bg-indigo-500 text-white shadow-sm hover:bg-indigo-500"
                                  : "dark:bg-white/10 text-indigo-600 dark:text-white ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                                "w-full mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              )}
                            >
                              Select
                            </button>
                            <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                              {features[location.name]?.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-indigo-600"
                                    aria-hidden="true"
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-600 dark:text-gray-300 text-center">
                          You need to sign in to book this type of
                          advertisement.
                        </p>
                      )}
                    </div>
                    <div ref={formRef} />
                    {activeForm.length ? (
                      <Form
                        submitAdRequest={submitAdRequest}
                        occupation={occupation}
                        key={activeForm}
                      />
                    ) : null}
                  </>
                ) : (
                  <div className="lg:grid-cols-1 isolate mx-auto mt-10 grid max-w-md grid-cols-1 lg:mx-0 lg:max-w-none text-center">
                    <p className="text-gray-600 dark:text-gray-300">
                      Hype battle
                    </p>
                    <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-gray-300">
                      Promote your token via a Hype Battle for maximum exposure!
                    </p>
                    <a
                      className="text-base font-semibold leading-7 text-[#FF5B0A]"
                      href="https://forms.gle/iRN2ZM2rJWRnvWAn8"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Click <span aria-hidden="true">→</span>
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </HeaderLayout>
  );
};

export default AdvertisingPage;
