import axios from "axios"
import CheckedTextInput from "components/forms/CheckedTextInput/CheckedTextInput"


export default function SocialFields({ data, changeObj, removeFromObj }) {


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                <span>

                    Social Platforms
                    {(!data.socials || Object.keys(data?.socials).length == 0) && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                    <p className="text-xs text-gray-500">Provide a link to at least one social platform where the token is featured.</p>
                </span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <CheckedTextInput
                    title="Reddit"
                    value={data?.socials?.REDDIT}
                    onChange={(v) => changeObj('socials', 'REDDIT', v)}
                    onDisable={() => removeFromObj('socials', 'REDDIT')}
                />
                <CheckedTextInput
                    title="Discord"
                    value={data?.socials?.DISCORD}
                    onChange={(v) => changeObj('socials', 'DISCORD', v)}
                    onDisable={() => removeFromObj('socials', 'DISCORD')}
                />
                <CheckedTextInput
                    title="Telegram"
                    value={data?.socials?.TELEGRAM}
                    onChange={(v) => changeObj('socials', 'TELEGRAM', v)}
                    onDisable={() => removeFromObj('socials', 'TELEGRAM')}
                />
                <CheckedTextInput
                    title="Twitter"
                    value={data?.socials?.TWITTER}
                    onChange={(v) => changeObj('socials', 'TWITTER', v)}
                    onDisable={() => removeFromObj('socials', 'TWITTER')}
                />
            </div>
        </div>

    )
}

