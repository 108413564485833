import { useEffect, useState } from 'react'
import httpService from 'services/http-service'


const UserWalletSection = () => {
    const [wallets, setWallets] = useState({})
    const [saved, setSaved] = useState(false)
    const [changed, setChanged] = useState(false)
    useEffect(() => {
        httpService.get('/api/v1/user/details/wallet').then((res) => setWallets(res.data.wallets))
    }, [])

    const changeWallet = (name, value) => {
        setChanged(true)
        setWallets(w => ({ ...w, [name]: value }))
    }

    return (
        < >
            <h1 className='dark:text-white border-b pt-8 w-full border-gray-200  dark:border-[#212121] pb-2 text-lg font-clash_b'>Wallets</h1>

            <div>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                    By submitting your wallet you are eligible for rewards.
                </p>


            </div>

            <div className="mt-6 flex flex-col lg:flex-row">
                <div className="flex-grow space-y-6">
                    <Wallet name="Ethereum" wallet={wallets['ETHEREUM']} walletkey={'ETHEREUM'} onChange={(e) => changeWallet('ETHEREUM', e)} />
                    <Wallet name="Solana" wallet={wallets['SOLONA']} walletkey='SOLONA' onChange={(e) => changeWallet('SOLONA', e)} />
                    <Wallet name="Avalanche" wallet={wallets['AVALANCHE']} walletkey='AVALANCHE' onChange={(e) => changeWallet('AVALANCHE', e)} />
                    <Wallet name="VeChain" wallet={wallets['VECHAIN']} walletkey='VECHAIN' onChange={(e) => changeWallet('VECHAIN', e)} />
                    <Wallet name="BSC" wallet={wallets['BSC']} walletkey='BSC' onChange={(e) => changeWallet('BSC', e)} />

                    {saved && <p class="text-green-500 text-sm mt-2 font-mulish italic">Your wallet has been updated</p>}
                    <div className="mt-4 flex justify-start">
                        {changed &&
                            <button

                                type="submit"
                                className="mr-6 bg-red-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                onClick={() => httpService.put('/api/v1/user/details/wallet', { wallets: Object.values(wallets) }).then(() => {
                                    setSaved(true)
                                    setChanged(false)
                                })}
                            >
                                Save
                            </button>
                        }

                    </div>
                </div>

            </div>

        </>

    )
}


const Wallet = ({ name, wallet, onChange, walletkey }) => {
    const w = wallet ? wallet : {}

    return (
        <div className="mt-1 rounded-md shadow-sm flex">
            <span className="bg-gray-50 dark:bg-[#212121] border border-r-0 text-xs border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm dark:border-gray-500">
                {name}
            </span>
            <input
                type="text"
                className="focus:ring-0 dark:bg-[#212121] focus:border-red-500 text-xs flex-grow block w-full min-w-0 dark:text-white rounded-none rounded-r-md sm:text-sm border-gray-300 dark:border-gray-500"
                value={w?.value}
                onChange={(e) => onChange({ ...w, name: walletkey, value: e.target.value })}
            />
        </div>
    )
}
export default UserWalletSection