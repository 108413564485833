import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpService from "services/http-service";

const AdvertisementBanner = ({ location }) => {
  const [ad, setAd] = useState(null);
  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };

  useEffect(() => {
    if (location) {
      httpService.get(`/api/v1/publicity/${location}`).then((res) => {
        setAd(res.data);
      });
    } else {
      httpService.get(`/api/v1/publicity`).then((res) => {
        setAd(res.data);
      });
    }
  }, [location]);

  if (ad == null || ad.length == 0) {
    return (
      <>
        <Link to="/advertising">
          <img src="/img/hmt_banner_new.png" />
        </Link>
      </>
    );
  }
  return (
    <>
      <a
        href={getClickableLink(ad.link)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={ad.resource} />
      </a>
      <div className="flex align-center justify-center">
        <Link
          className="text-[0.6em] sm:text-xs font-semibold leading-4 text-[#FF5B0A]"
          to="/advertising"
        >
          Your advertisement here?
        </Link>
      </div>
    </>
  );
};

export default AdvertisementBanner;
