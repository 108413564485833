import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import FAQ from "views/Home/FAQ/FAQ";
import { useEffect } from "react";
import BattleSection from "views/Home/Battle/BattleSection/BattleSection";
import PumpDumpSection from "../PumpDump/PumpDumpSection/PumpDumpSection";
import AdvertisementBanner from "../../../components/sections/AdvertisementBanner/AdvertisementBanner";
import FrontPageTokenTable from "views/Home/FrontPageTokenTable/FrontPageTokenTable";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <HeaderLayout>
      <section className="px-4">
        <BattleSection />
        <PumpDumpSection />
        <AdvertisementBanner location={"FRONT_PAGE"} />
        <FrontPageTokenTable />
        <FAQ />
      </section>
    </HeaderLayout>
  );
};

export default HomePage;
