import { useState } from 'react'
import AuthService from 'services/AuthService'
import httpService from 'services/http-service'


const UserPasswordSection = ({ }) => {
    const user = AuthService.getCurrentUser()
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [changing, setChanging] = useState(false)
    const [changed, setChanged] = useState(false)
    const [wrong, setWrong] = useState(false)

    const disabled = !(password.length > 5 && passwordConfirm === password)

    return (
        <>
            <h1 className='dark:text-white border-b pt-8 w-full border-gray-200  dark:border-[#212121] pb-2 text-lg font-clash_b'>Password</h1>

            <div className="flex flex-col lg:flex-row w-[90%] md:w-[30em]">

                <div className="flex-grow space-y-6">
                    {changing && changed
                        ? <p className='dark:text-white'>{"Succesfully changed password!"}</p>
                        :
                        <>
                            <div >
                                <input
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder="Current password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-red-600  text-xs md:text-sm  focus:ring-red-500 dark:bg-[#212121] dark:text-white dark:border-black focus:border-red-500 "
                                />
                                {wrong && <p class="text-red-500 text-sm mt-2 font-mulish italic">Incorrect password!</p>}

                            </div>
                            <div >
                                <input
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder="New password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none text-xs focus:ring-1 focus:ring-red-600 md:text-sm focus:ring-red-500 dark:bg-[#212121] dark:text-white dark:border-black focus:border-red-500 "
                                />

                            </div>
                            <div >
                                <input
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder="Confirm password"
                                    value={passwordConfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                    class="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-red-600 text-xs md:text-sm focus:ring-red-500 dark:bg-[#212121] dark:text-white dark:border-black focus:border-red-500 "
                                />
                                {(passwordConfirm.length > 0 && password !== passwordConfirm) && <p class="text-red-500 text-xs mt-2 italic">Passwords do not match!</p>}

                            </div>
                            {!disabled &&
                                <div className="mt-4 py-4 flex justify-start ">
                                    <button
                                        disabled={disabled}
                                        type="submit"
                                        className="mr-6 disabled:bg-slate-200 disabled:text-slate-500 disabled:border-slate-200 dark:disabled:bg-slate-500 dark:disabled:text-slate-200 dark:disabled:border-slate-500 bg-red-700 border border-transparent rounded-md shadow-sm py-1 px-3 inline-flex justify-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        onClick={() => {
                                            setChanging(true)
                                            httpService.put('/api/v1/auth/password', { username: user.username, oldPassword: currentPassword, newPassword: password }).then((r) => {
                                                if (r.data) setChanged(true)
                                                else setWrong(true)
                                            })
                                        }
                                        }
                                    >
                                        {changing && !changed && !wrong ?
                                            <div role="status" className="flex items-center justify-center">
                                                <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                                <>Saving..</>
                                            </div>
                                            :
                                            <>Save</>
                                        }
                                    </button>

                                </div>
                            }
                        </>
                    }
                </div>

            </div>
        </>

    )
}
export default UserPasswordSection