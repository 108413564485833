import { useContext, useEffect, useState } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import httpService from "services/http-service";
import Battle from "views/Home/Battle/ActiveBattle/Battle";
import { Link } from "react-router-dom";
import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import { ThemeContext } from "utils/theme";
import BattleParticipant from "../BattleParticipant/BattleParticipant";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const BattleHistoryCard = ({ battle, darkmode, index }) => {
    const [project, setProject] = useState(null);
    const winnerHypes = battle.hypes[battle.winner]

    useEffect(() => {
        if (battle.winner != null) {
            httpService
                .get(`/api/v1/token/${battle.winner}`)
                .then((res) => setProject(res.data.result[battle.winner]));
        }
    }, [battle.winner]);

    if (battle.winner == null || project == null) return null

    return (
        <Disclosure as="div" key={'test'}>
            {({ open }) => (
                <>
                    <dt>
                        <Disclosure.Button className={`flex  items-start items-center w-full border-t ${index == 0 && 'border-none'} py-4 border-gray-700`}>
                            <div >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={darkmode ? 'white' : 'currentcolor'} className={` mr-4 w-6 h-6 ${!open && 'rotate-90'}`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                </svg>
                            </div>
                            <div className="flex md:flex-row flex-col flex-wrap items-start md:items-center max-w-[92%]">

                                <div className="hidden md:block md:flex md:flex-col md:w-[19em]">
                                    <p className="dark:text-white text-left">From <span className="text-gray-400">{new Date(battle.createdAt).toLocaleDateString()} {new Date(battle.createdAt).toLocaleTimeString()}</span></p>
                                    <p className="dark:text-white text-left">to <span className="text-gray-400">{new Date(battle.endsAt).toLocaleDateString()} {new Date(battle.endsAt).toLocaleTimeString()}</span></p>
                                </div>
                                <div className="block flex mb-4 md:hidden">
                                    <p className="dark:text-white text-left"><span className="text-gray-400 mr-2">{new Date(battle.createdAt).toLocaleDateString()}</span></p>
                                    <p className="dark:text-white text-left">-<span className="text-gray-400 ml-2">{new Date(battle.endsAt).toLocaleDateString()}</span></p>

                                </div>
                                <div className="flex justify-start md:justify-start w-full md:w-auto">
                                    <div className="lg:w-[25em] md:w-[11em] w-[13em] text-center font-mulish_b  lg:pl-12 ">
                                        <Link to={`/token/${project.id}`}>
                                            <div className="flex items-center text-left mr-2 dark:text-white">
                                                <Avatar id={project.avatar} className="w-10 h-10 mr-2" />
                                                {project.title}
                                            </div>
                                        </Link>
                                    </div>
                                    <div
                                        className="ml-4 md:ml-16 flex items-center text-[#FF5B09] dark:text-[#F80043] font-mulish_sb">
                                        <img
                                            className="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 mr-1"
                                            src={
                                                darkmode ? "/img/hype_count_dark.svg" : "/img/hype_count.svg"
                                            }
                                            alt="Hype Count"
                                        />{" "}
                                        {Number(winnerHypes).toLocaleString('en')}
                                    </div>
                                </div>
                            </div>
                        </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="pr-12 pl-4  mb-4 dark:text-gray-100 py-4 ">
                        {Object.keys(battle.hypes).sort((a, b) => (battle.hypes[b] - battle.hypes[a])).map((p, i) =>

                            <BattleParticipant project={p} hypes={battle.hypes[p]} darkmode={darkmode} rank={i} />
                        )}

                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default BattleHistoryCard