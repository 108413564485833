// Context

import axios from "axios";
import { useEffect, useState } from "react";
import DumpCard from "../DumpCard/DumpCard";
import PumpCard from "../PumpCard/PumpCard";


// Truncate Text

const PumpDumpSection = () => {
    const [pump, setPump] = useState(null)
    const [dump, setDump] = useState(null)

    useEffect(() => {
        axios.get('/api/v1/hype/pumpdump').then(res => {
            if (res.data.pumpToken) {
                setPump({ project: res.data.pumpToken, diff: res.data.pumpIncrease })
            }
            if (res.data.dumpToken) {
                setDump({ project: res.data.dumpToken, diff: res.data.dumpDecrease })
            }
        })
    }, [])

    return (

        <div className="flex xl:max-w-[80%] flex-wrap between items-center justify-between lg:mx-auto mt-8 md:mt-0 ">

            <PumpCard projectID={pump?.project} diff={pump?.diff} />

            <DumpCard projectID={dump?.project} diff={dump?.diff} />

        </div>

    );
};

export default PumpDumpSection;

// Console

