import axios from "axios"
import CheckedTextInput from "components/forms/CheckedTextInput/CheckedTextInput"


export default function PriceDataFields({ data, changeObj, removeFromObj }) {



    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                <span>
                    Price Data
                    {(!data.priceData || Object.keys(data?.priceData).length == 0) && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                    <p className="text-xs text-gray-500">Provide at least one link to price data of the token.</p>

                </span>

            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {data.type === "nft" ?
                    <CheckedTextInput
                        title="Marketplace"
                        value={data?.priceData?.MARKETPLACE}
                        onChange={(v) => changeObj('priceData', 'MARKETPLACE', v)}
                        onDisable={() => removeFromObj('priceData', 'MARKETPLACE')}
                    />
                    :

                    <>
                        <CheckedTextInput
                            title="Coinmarketcap"
                            value={data?.priceData?.COINMARKETCAP}
                            onChange={(v) => changeObj('priceData', 'COINMARKETCAP', v)}
                            onDisable={() => removeFromObj('priceData', 'COINMARKETCAP')}
                        />
                        <CheckedTextInput
                            title="Coingecko"
                            value={data?.priceData?.COINGECKO}
                            onChange={(v) => changeObj('priceData', 'COINGECKO', v)}
                            onDisable={() => removeFromObj('priceData', 'COINGECKO')}
                        />
                        <CheckedTextInput
                            title="DEX"
                            value={data?.priceData?.DEX}
                            onChange={(v) => changeObj('priceData', 'DEX', v)}
                            onDisable={() => removeFromObj('priceData', 'DEX')}
                        />
                    </>
                }
            </div>
        </div>
    )
}

