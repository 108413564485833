import HeaderLayout from "components/layout/HeaderLayout/HeaderLayout";
import { LoginForm } from "views/Login/LoginForm/LoginForm";
import { useContext, useEffect, useState } from "react";


const RedirectLogin = ({ to, onClose }) => {
    const open = true




    return (
        <>
            <div id="defaultModal" tabindex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed w-[90%] md:w-[80%] top-1/2 left-1/2 -translate-y-1/2 max-w-xl -translate-x-1/2 z-50  `} >
                <div class="relative bg-white dark:bg-black dark:border-[#212121] dark:shadow-lg dark:shadow-[#212121] dark:border rounded-lg shadow  max-h-[70vh] flex justify-center items-center p-2 md:p-8">


                    <LoginForm redirectTo={to} onLogin={onClose}/>


                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>
        </>
    )
};

export default RedirectLogin;
