import { useContext, useEffect, useState } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import httpService from "services/http-service";
import Battle from "views/Home/Battle/ActiveBattle/Battle";
import { Link } from "react-router-dom";
import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import { ThemeContext } from "utils/theme";

const BattleParticipant = ({ project, hypes, darkmode, rank }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        httpService
            .get(`/api/v1/token/${project}`)
            .then((res) => setData(res.data.result[project]));

    }, []);

    if (data == null) return null


    return (
        <div className={`flex items-center mb-2 ${rank == 0 && 'bg-yellow-200 dark:bg-yellow-900'} px-4 py-1 rounded-lg w-full  min-w-[20em]`}>
            <div className={`text-center font-mulish_b lg:w-[25em] md:w-[20em] w-[15em]  `}>

                <Link to={`/token/${data.id}`}>
                    <div className="flex items-center text-left mr-2 dark:text-white min-w-[10em]">
                        #{rank + 1}
                        <Avatar id={data.avatar} className="ml-4 w-10 h-10 mr-2" />
                        {data.title}
                    </div>
                </Link>
            </div>
            <div
                className="ml-4 md:ml-16 flex items-center text-[#FF5B09] dark:text-[#F80043] font-mulish_sb">
                <img
                    className="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 mr-1"
                    src={
                        darkmode ? "/img/hype_count_dark.svg" : "/img/hype_count.svg"
                    }
                    alt="Hype Count"
                />{" "}
                {Number(hypes).toLocaleString('en')}
            </div>
        </div>
    )
}

export default BattleParticipant