import { useState } from "react";
import { useField, ErrorMessage } from "formik";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function File({
  label,
  className,
  setFieldValue,
  file,
  setFile,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  const [validationError, setValidationError] = useState("");
  const [fileResult, setFileResult] = useState();

  async function handleChange(event) {
    const fileReader = new FileReader();
    const file = event.target.files[0];
    const fileSize = file?.size;

    if (fileSize > 10485760) {
      setValidationError("File to large");
      return;
    }

    fileReader.onloadend = () => {
      const image = new Image();
      image.src = fileReader.result;

      image.onload = async function () {
        if (this.width !== 1900) {
          setValidationError("Image width must be 1900px");
          return;
        }
        if (this.height !== 250) {
          setValidationError("Image height must be 250px");
          return;
        }
        setValidationError("");
        setFile(file);
        setFileResult(fileReader.result);

        setFieldValue(field.name, fileReader.result);
      };

      // setFieldValue(field.name, fileReader.result);
    };
    fileReader?.readAsDataURL(file);
  }

  return (
    <div className="flex-1">
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700 dark:text-white sm:mt-px sm:pt-2"
      >
        {label}
        <span className="text-xs font-medium text-gray-700 dark:text-gray-400">
          {" (1900px x 250px)"}
        </span>
      </label>
      <div className="mt-1 flex flex-col">
        {fileResult || field?.value ? (
          <div
            className={classNames(
              "flex items-center justify-center mb-5 rounded-md p-2 border border-[#3E3EA2]",
              className
            )}
          >
            <img src={fileResult || field?.value}></img>
          </div>
        ) : null}
        <div className="flex max-w-lg sm:max-w-xs justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600 items-center justify-center">
              <label
                htmlFor={field.name}
                className="relative cursor-pointer rounded-md font-medium text-orange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-orange-500"
              >
                <span>Upload a file</span>
                <input
                  {...props}
                  onChange={handleChange}
                  type="file"
                  className="sr-only"
                  accept="image/png, image/gif, image/jpeg,  image/jpg"
                />
              </label>
              {/* <p className="pl-1">
                {t("general-settings.fields.drag-or-drop")}
              </p> */}
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
        <div>
          {validationError || meta.error ? (
            <p className="mt-2 text-pink-600 text-sm">
              {validationError
                ? validationError
                : <ErrorMessage name="resourceId" /> || null}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}
