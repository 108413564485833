import axios from "axios"
import { Fragment, useContext, useEffect, useState } from "react"
import Resizer from "react-image-file-resizer";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


export default function TokenMediaFields({ avatar, setAvatar, setBattleLogo, battleLogo }) {
    const [avatarFileError, setAvatarFileError] = useState(false)
    const [battleLogoFileError, setBattleLogoFileError] = useState(false)

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
            setAvatarFileError(true)
            return
        }
        setAvatarFileError(false)
        const image = await resizeFile(file, 128, 128);
        setAvatar({ file: image, url: URL.createObjectURL(image) })
    }

    const handleChangeBattleLogo = async (e) => {
        const file = e.target.files[0];
        if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
            setBattleLogoFileError(true)
            return
        }
        setBattleLogoFileError(false)
        const image = await resizeFile(file, 248, 165);
        setBattleLogo({ file: image, url: URL.createObjectURL(image) })
    }

    const resizeFile = (file, width, height) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                width,
                height,
                "JPG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    return (
        <>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                    <span>
                        Avatar
                        <p className="text-xs text-gray-500">PNG, JPG, JPEG</p>
                    </span>

                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                        <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                            <img
                                src={avatar == null ? '/img/projects/DEFAULT_LOGO.png' : avatar.url}
                                className={"w-12 h-12 mr-2"}
                            />
                        </span>

                        <label
                            htmlFor="file-upload"
                            className="ml-4 relative cursor-pointer border-2 bg-white dark:bg-black rounded-md border border-red-600 px-2 py-1 font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                        >
                            <span>Change</span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                accept="image/*" type="file"
                                className="sr-only"
                                onChange={handleAvatarChange}
                            />
                        </label>

                    </div>
                </div>
                <FileError open={avatarFileError} onClose={() => setAvatarFileError(false)} />

            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                    <span>
                        Battle Logo
                        <p className="text-xs text-gray-500">This image will be displayed when this token is participating in a Hype Battle. PNG, JPG, JPEG. Max. resolution: 248 x 165</p>

                    </span>

                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="flex">
                        <div className="space-y-1 text-center">
                            <img
                                className="w-[248px] h-[165px] mb-4"
                                src={battleLogo == null ? `/img/battle/hb_default.png` : battleLogo.url}
                                alt="Hype Battle Project"
                            />

                            <label
                                htmlFor="battle-upload"
                                className="ml-4 relative cursor-pointer border-2 bg-white dark:bg-black rounded-md border border-red-600 px-2 py-1 font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                            >
                                <span>Change</span>
                                <input
                                    id="battle-upload"
                                    name="battle-upload"
                                    accept="image/*" type="file"
                                    className="sr-only"
                                    onChange={handleChangeBattleLogo}
                                />
                            </label>


                        </div>
                    </div>
                    <FileError open={battleLogoFileError} onClose={() => setBattleLogoFileError(false)} />

                </div>
            </div>
        </>
    )
}




const FileError = ({ open, onClose }) => {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white  dark:bg-[#111111] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
                                            Couldn't accept your upload
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500 dark:text-gray-300">
                                                Supported extensions: JPEG, JPG and PNG
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                        onClick={onClose}
                                    >
                                        I understand
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}