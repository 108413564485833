import axios from "axios"


export default function GiveAwayCriteriaField({ data, changeData }) {
    return (
        <div className="flex-col items-center">
            <div className="flex">
                <label htmlFor="title" className="block text-md text-gray-700 dark:text-gray-400 ">
                    Criteria
                </label>
                <button onClick={() => {
                    if (data?.criteria)
                        changeData('criteria', [...data.criteria, ''])
                    else
                        changeData('criteria', [''])


                }
                }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-3 h-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </button>
            </div>
            <div className="mt-1">
                {data?.criteria && data.criteria.map((c, i) =>
                    <div className="max-w-lg flex rounded-md shadow-sm mb-1">
                        <input
                            type="text"
                            name="title"
                            value={c}
                            onChange={(e) => changeData('criteria', Object.assign([], data.criteria, {[i]: e.target.value}))}

                            id="title"
                            className=" block w-full focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
                        />
                        <button
                            onClick={() => {
                                changeData('criteria', data.criteria.filter((_, ix) => ix !== i))
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-4 text-red-500 w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                            </svg>

                        </button>

                    </div>
                )}
            </div>
        </div>
    )
}

