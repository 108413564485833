import { Redirect, Route, useLocation } from "react-router-dom";
import AuthService from "services/AuthService";
// Pages
// Components

function PrivateRoute({ children, ...rest }) {
    const location = useLocation(); 

    return (
        <Route
            {...rest}
            render={() => {
                return AuthService.isLoggedIn() === true ? (
                    children
                ) : (
                    <Redirect to={{pathname: "/login",  state: {from: location}}} />
                );
            }}
        />
    );
}

export default PrivateRoute