import { SEO } from "components/presentation/SEO/SEO";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import httpService from "services/http-service";
import { ThemeContext } from "utils/theme";
import TokenDetails from "components/sections/TokenDetails/TokenDetails";
import TokenHeader from "components/sections/TokenHeader/TokenHeader";
import SubmissionActions from "../SubmissionActions/SubmissionActions";
import SubmissionBattleIcon from "../SubmissionBattleIcon/SubmissionBattleIcon";


const SubmissionPageLayout = () => {
    const { darkMode } = useContext(ThemeContext);

    let { id } = useParams();
    const [project, setProject] = useState(null);

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        httpService
            .get(`/api/v1/submission/review/${id}`)
            .then((res) => {
                setProject(res.data)
                setLoading(false)
            })
            .catch((err) => {
                setProject(null)
                setLoading(false)
            });
    }, [id]);



    if (!project) {
        return (
            <section className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 md:-mt-16 lg:mt-0 lg:my-0 lg:mb-16">
                {loading == false &&
                    <div className="flex flex-col items-center justify-center my-16">
                        <h3 className="text-2xl font-clash_b text-hmt_text_bold_light dark:text-hmt_text_bold_dark mb-4">
                            Project Not Found
                        </h3>
                        <Link to="/">
                            <button
                                className="font-mulish_sb px-2 py-1 rounded-md border border-gray-500 text-hmt_text_color_light dark:text-hmt_text_color_dark ">
                                Go Home
                            </button>
                        </Link>

                    </div>
                }
            </section>
        );
    }
    return (
        <section className="max-w-[90%] lg:max-w-[1000px] xl:max-w-[1200px] mx-auto my-8 ">
            <SubmissionActions id={id}/>
            <TokenHeader project={{...project, chain: project.network}} darkMode={darkMode} submission={true}/>
            <TokenDetails project={{...project, projectLink: project.website}} darkMode={darkMode} />
           <SubmissionBattleIcon project={project}/>
        </section>
    );
};


export default SubmissionPageLayout;

