import { useEffect, useState } from "react";
import httpService from "services/http-service";
import GiveAwayCarousel from "views/Administration/AdministrationSections/GiveAwaySection/GiveAwayCarousel/GiveAwayCarousel";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import GiveAwayEntry from "../GiveAwayEntry/GiveAwayEntry";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const GiveAwayPage = () => {
    const [giveAways, setGiveAways] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    useEffect(() => {
        httpService.get("/api/v1/giveaway").then(res => setGiveAways(res.data.giveAways))
    }, [])

    return (
        <HeaderLayout>
            <GiveAwayCarousel />
        </HeaderLayout >
    );
};


export default GiveAwayPage;
