import { useEffect } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import SubmissionPageLayout from "../SubmissionPageLayout/SubmissionPageLayout";

const SubmissionPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0, behavior: "smooth",
        });
    }, []);
    return (
        <HeaderLayout>

            <section>
    
                <SubmissionPageLayout />
            </section>
        </HeaderLayout>
    );
};

export default SubmissionPage