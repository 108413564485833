import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkSquareIcon,
  PaperAirplaneIcon,
  UserPlusIcon,
  Bars3Icon,
  HomeIcon,
  GiftIcon,
  BellAlertIcon,
  TrophyIcon,
  UserGroupIcon,
  BuildingLibraryIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import { ThemeContext } from "../../../utils/theme";
import { useContext, useState, useRef, useEffect } from "react";
import TokenSearchBox from "components/forms/TokenSearchBox/TokenSearchBox";
import { Link, useHistory } from "react-router-dom";
import AuthService from "services/AuthService";
import RedirectLogin from "components/sections/RedirectLogin/RedirectLogin";

const token = [
  {
    name: "Submit a Token",
    href: "/submit",
    icon: PaperAirplaneIcon,
    loginRequired: true,
  },
  {
    name: "Your Tokens",
    href: `/user/${AuthService.getCurrentUser()?.username}/submitted-tokens`,
    icon: UserPlusIcon,
    loginRequired: true,
  },
  {
    name: "Followed Tokens",
    href: `/user/${AuthService.getCurrentUser()?.username}/followed-tokens`,
    icon: BellAlertIcon,
    loginRequired: true,
  },
];

const platform = [
  { name: "Home", href: "/", icon: HomeIcon },
  { name: "Battles", href: "/battles", icon: TrophyIcon },
  { name: "Giveaways", href: "/giveaway", icon: GiftIcon },
  { name: "Advertising", href: "/advertising", icon: SpeakerWaveIcon },
  {
    name: "Administration",
    href: "/admin",
    role: "ROLE_ADMIN",
    icon: BuildingLibraryIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FlyoutMenu() {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const history = useHistory();
  const [redirectLogin, setRedirectLogin] = useState(null);

  return (
    <>
      <Popover className="z-10 overscroll-none">
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                open ? "text-gray-900" : "text-gray-500",
                "group inline-flex focus:outline-none items-center rounded-md bg-white  dark:bg-[#121212] dark:text-white text-base font-medium"
              )}
            >
              <Bars3Icon
                className={classNames(
                  open
                    ? "dark:text-gray-400 text-gray-600"
                    : "dark:text-gray-300 text-gray-500",
                  "mr-4 mt-2 h-5 w-5 md:w-6 md:h-6 group-hover:text-gray-600 dark:group-hover:text-gray-300 "
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave=""
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >
              <Popover.Panel className="absolute inset-x-0 mt-1 transform shadow-lg dark:shadow-[#262626] overscroll-none ">
                <div className="flex flex-col justify-between items-start px-6 pt-4 bg-[#FCFCFC] dark:bg-[#111111] pb-8 md:pl-8 ">
                  <div className="w-full flex justify-between mb-4">
                    {AuthService.isLoggedIn() ? (
                      <div className="flex items-center">
                        <p className="inline-flex text-sm font-medium text-black dark:text-gray-300 mb-1">
                          <span>
                            Welcome{" "}
                            <Link
                              to={`/user/${
                                AuthService.getCurrentUser().username
                              }`}
                            >
                              {AuthService.getCurrentUser().username}
                            </Link>
                          </span>
                        </p>
                      </div>
                    ) : (
                      <Link
                        to="/login"
                        className="inline-flex block mobile:hidden py-2 text-xs font-medium text-black dark:text-white dark:mobile:text-gray-300 "
                      >
                        Login or Register
                      </Link>
                    )}
                  </div>
                  <div className="w-[95%] mobile:w-[20em] md:w-[25em] mb-8">
                    <TokenSearchBox />
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col md:flex-row">
                      <div className="md:mr-32 mb-8">
                        <h3 className="text-base font-medium text-gray-500">
                          Platform
                        </h3>
                        <ul role="list" className="mt-5 space-y-6">
                          {platform.map(
                            (item) =>
                              (item.role == null ||
                                AuthService.hasRole(item.role)) && (
                                <li key={item.name} className="flow-root">
                                  <Link
                                    to={item.href}
                                    className="-m-3 flex items-center rounded-md p-3 pr-6 text-base dark:text-gray-200 font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-800 "
                                  >
                                    <item.icon
                                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-4 text-sm">
                                      {item.name}
                                    </span>
                                  </Link>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                      <div className="">
                        <h3 className="text-base font-medium text-gray-500">
                          Tokens
                        </h3>
                        <ul role="list" className="mt-5 space-y-6">
                          {token.map((item) => (
                            <li key={item.name} className="flow-root">
                              {item.loginRequired &&
                              !AuthService.isLoggedIn() ? (
                                <Popover.Button
                                  onClick={() =>
                                    setRedirectLogin(
                                      item.href.replace(
                                        "undefined",
                                        "%username%"
                                      )
                                    )
                                  }
                                  className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 dark:text-gray-200 transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-800"
                                >
                                  <item.icon
                                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-4 text-sm">
                                    {item.name}
                                  </span>
                                </Popover.Button>
                              ) : (
                                <Link
                                  to={item.href}
                                  className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 dark:text-gray-200 transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-800"
                                >
                                  <item.icon
                                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-4 text-sm">
                                    {item.name}
                                  </span>
                                </Link>
                              )}
                            </li>
                          ))}
                          <li key={"search"} className="flow-root"></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <button
                    key={"light"}
                    onClick={() => toggleDarkMode()}
                    className={" text-gray-900 dark:text-gray-300 mt-4"}
                  >
                    <div className="flex items-center text-xs">
                      {darkMode ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                            />
                          </svg>
                          Light mode
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                            />
                          </svg>
                          Dark mode
                        </>
                      )}
                    </div>
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      {redirectLogin && (
        <RedirectLogin
          to={redirectLogin}
          onClose={() => setRedirectLogin(null)}
        />
      )}
    </>
  );
}
