import { useEffect } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import BattleHistoryTable from "../BattleHistoryTable/BattleHistoryTable";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const BattlesPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);


    return (
        <HeaderLayout>

            <BattleHistoryTable/>

        </HeaderLayout >
    );
};


export default BattlesPage;
