

export default function TokenTypeField({ data, changeType }) {




    return (

        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                    Type
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md ">
                        <fieldset className="flex ">


                            <div className="flex items-center mx-2">
                                <input
                                    id="push-email"
                                    name="push-notifications"
                                    type="radio"
                                    checked={data.type === 'token'} onChange={(e) => changeType('token')}
                                    className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                                />
                                <label htmlFor="push-email" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-400 dark:text-gray-300">
                                    Token
                                </label>

                            </div>
                            <div className="flex items-center mx-2">
                                <input
                                    id="push-everything"
                                    name="push-notifications"
                                    type="radio"
                                    checked={data.type === 'nft'} onChange={(e) => changeType('nft')}
                                    className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300  "
                                />
                                <label htmlFor="push-everything" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-400  dark:text-gray-300">
                                    NFT
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    )
}

