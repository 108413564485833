import { useContext, useState, useRef, useEffect, Fragment } from "react"
import { Link, useHistory, useLocation } from 'react-router-dom'
/* This example requires Tailwind CSS v2.0+ */

import { ThemeContext } from "../../../utils/theme";
import AuthService from "../../../services/AuthService";
import TokenSearchBox from "components/forms/TokenSearchBox/TokenSearchBox";
import FlyoutMenu from "../FlyoutMenu/FlyoutMenu";
import { Menu, Transition } from "@headlessui/react";
import RedirectLogin from "components/sections/RedirectLogin/RedirectLogin";

const Header = () => {
    const [openProfile, setOpenProfile] = useState(false)
    const profileMenu = useRef(null)
    const [redirectLogin, setRedirectLogin] = useState(null)
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const history = useHistory()
    let location = useLocation();

    useEffect(() => {
        function handleClickOutside(event) {
            if (profileMenu.current && !profileMenu.current.contains(event.target)) {
                setOpenProfile(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileMenu]);

    return (
        <nav class="bg-[#FCFCFC] dark:bg-[#111111] shadow-sm dark:shadow-sm md:shadow-md  dark:shadow-gray-700 sticky top-0 z-50">

            <div class="max-w-[1920px] mx-auto px-4">

                <div class="flex items-center justify-between h-12">
                    <div className="hidden md:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-[15em] lg:w-[20em]">
                        <TokenSearchBox />
                    </div>
                    <div className="flex items-center">
                        <FlyoutMenu />
                        <img
                            onClick={() => { history.push('/') }}
                            className="w-[150px] md:w-[180px] mb-0.5 cursor-pointer"
                            src={darkMode ? "/img/hmt_footer_logo.svg" : "/img/hmt_logo_light.svg"}
                            alt="HypeMyToken Logo"
                        />
                    </div>

                    <div className="flex align-center">


                        <div>
                            {AuthService.isLoggedIn() ?

                                <div class="flex items-center" ref={profileMenu}>

                                    <ProfileMenu username={AuthService.getCurrentUser().username} />

                                </div>
                                :
                                <button
                                    onClick={() => setRedirectLogin(location.pathname)}
                                    className="inline-flex block mobile:px-2.5 py-2 text-xs font-medium text-black dark:text-white mt-1"
                                >
                                    <p class="hidden mobile:block">Login or Register</p>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="dark:text-gray-200 text-gray-600 block mobile:hidden w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                    </svg>

                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {redirectLogin &&
                <RedirectLogin to={redirectLogin} onClose={() => setRedirectLogin(null)} />
            }
        </nav>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProfileMenu = ({ username }) => {
    const history = useHistory()

    return (
        <Menu as="div" className="relative ml-3">
            <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full dark:text-white text-black text-sm ">
                    <span className="sr-only">Open user menu</span>
                    <svg
                        class="inline-block w-7 h-7"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        stroke="currentColor"
                    >
                        <path d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-[#FCFCFC] dark:bg-[#111111] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item key={"profile"}>
                        {({ active }) => (
                            <Link
                                to={`/user/${username}`}
                                className={classNames(
                                    active ? 'dark:bg-[#212121] bg-gray-100' : '',
                                    'block px-4 py-2 text-sm dark:text-gray-100 text-gray-700'
                                )}
                            >
                                Profile
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item key={"settings"}>
                        {({ active }) => (
                            <Link
                                to={`/profile`}
                                className={classNames(
                                    active ? 'dark:bg-[#212121] bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 dark:text-gray-100 '
                                )}
                            >
                                Settings
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item key={"logout"}>
                        {({ active }) => (
                            <button
                                onClick={() => {
                                    AuthService.logout()
                                    history.push('/')
                                }}
                                className={classNames(
                                    active ? 'dark:bg-[#212121] bg-gray-100' : '',
                                    'block px-4 w-full border-t dark:border-gray-700 text-left py-2 text-sm text-gray-700 dark:text-gray-100 '
                                )}
                            >
                                Sign out
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Header