import * as dayjs from "dayjs";
import { useEffect, useState, useContext } from "react";
import AuthService from "services/AuthService";
import httpService from "services/http-service";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";
import { useParams } from "react-router-dom";
import UserOverviewSection from "../UserOverviewSection/UserOverviewSection";
import TokenAvatar from "components/presentation/TokenAvatar/TokenAvatar";
import { ThemeContext } from "utils/theme";
import { Link, useHistory } from "react-router-dom";
import SubmittedTokensTable from "views/SubmittedTokens/SubmittedTokensTable/SubmittedTokensTable";
import FollowingTokensTable from "views/FollowingTokens/FollowingTokensTable/FollowingTokensTable";
import UserActiveHypeSection from "views/PublicUserProfile/UserActiveHypeSection/UserActiveHypeSection";
import AdvertisementSection from "components/sections/AdvertisementSection/AdvertisementSection";
import AdvertisementBanner from "components/sections/AdvertisementBanner/AdvertisementBanner";
const tabMap = {
  overview: "Overview",
  activity: "Activity",
  "followed-tokens": "Followed Tokens",
  "submitted-tokens": "Submitted Tokens",
};
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PublicUserProfilePage = () => {
  const { darkMode } = useContext(ThemeContext);
  let { user, tab } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const currentTab =
    tab == null ? "overview" : tab.replace(/ /g, "-").toLowerCase();

  const history = useHistory();
  const navigationSwitch = () => {
    switch (tab) {
      case "overview":
        return <UserOverviewSection username={user} />;
      case "submitted-tokens":
        return (
          <div>
            <AdvertisementBanner location={"TOKEN_PAGE"} />
            <SubmittedTokensTable />
          </div>
        );
      case "activity":
        return <HypeFeed username={user} />;

      case "followed-tokens":
        return (
          <div>
            <AdvertisementBanner location={"TOKEN_PAGE"} />
            <FollowingTokensTable />
          </div>
        );
      case "active-hypes":
        return <UserActiveHypeSection />;
      default:
        history.push(`/user/${user}/overview`);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    httpService
      .get(`/api/v1/user/profile/${user}`)
      .then((res) => setUserInfo(res.data));
  }, [user]);

  return (
    <HeaderLayout>
      <section className="px-4 sm:px-6 md:px-0 mb-4">
        <div className="mt-8 ">
          <div className="flex flex-col items-start rounded-2xl p-6  dark:border shadow-sm border-gray-100 dark:border-[#121212] dark:shadow-[#121212]">
            <div className="flex ">
              <img
                className="h-9 w-9 rounded-full flex items-center justify-center "
                src={
                  darkMode
                    ? `https://ui-avatars.com/api/?background=800020&color=fff&name=${user}&bold=true`
                    : `https://ui-avatars.com/api/?background=9A2A2A&color=fff&name=${user}bold=true`
                }
                alt=""
              />
              <h1 className="ml-2 mb-1 dark:text-gray-100 font-clash_b text-2xl">
                {userInfo?.username}
              </h1>
            </div>
            <p className="dark:text-gray-400 font-clash_b text-xs mt-1">
              Registered at {new Date(userInfo?.createdAt).toLocaleDateString()}{" "}
              {new Date(userInfo?.createdAt).toLocaleTimeString()}
            </p>
            {AuthService.getCurrentUser().username === userInfo?.username && (
              <button
                className="border dark:border-gray-700 px-2 text-xs py-1 my-4 dark:text-gray-400 rounded-lg"
                onClick={() => history.push("/profile")}
              >
                Manage Profile
              </button>
            )}
            <div className="mt-4">
              {userInfo?.twitter && (
                <a
                  href={`https://www.twitter.com/${userInfo?.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-6 w-6 rounded-full  flex items-center justify-center"
                    src={"/img/brand/Twitter-logo.svg"}
                    alt="twitter"
                  />
                </a>
              )}
            </div>
          </div>

          <div className="p-4  mb-32">
            <div className="rounded-lg  overflow-hidden border-gray-100 dark:border-gray-800  ">
              <div className="lg:hidden">
                <label htmlFor="selected-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="selected-tab"
                  value={tabMap[tab]}
                  name="selected-tab"
                  onChange={(e) =>
                    history.push(
                      `/user/${user}/${e.target.value
                        .replace(/ /g, "-")
                        .toLowerCase()}`
                    )
                  }
                  className="dark:bg-[#121212] dark:text-white block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none dark:border-[#212121] focus:ring-0  sm:text-sm rounded-md"
                >
                  {Object.values(tabMap).map((tab) => (
                    <option key={tab}>{tab}</option>
                  ))}
                  {AuthService.getCurrentUser().username ===
                    userInfo?.username && (
                    <>
                      <option key={"active-hypes"}>Active Hypes</option>
                    </>
                  )}
                </select>
              </div>
              <div className="hidden lg:block ">
                <div className="">
                  <nav className="flex space-x-8 px-4 ">
                    {Object.values(tabMap).map((t) => (
                      <a
                        key={t}
                        onClick={() =>
                          history.push(
                            `/user/${user}/${t
                              .toLowerCase()
                              .replace(/ /g, "-")}`
                          )
                        }
                        className={classNames(
                          "border-transparent text-gray-500 hover:border-red-300 hover:text-gray-700",
                          "whitespace-nowrap py-4 px-1 border-b-2 font-clash_b text-sm dark:text-gray-200",
                          currentTab == t.replace(/ /g, "-").toLowerCase() &&
                            "border-red-500 hover:border-red-500 "
                        )}
                      >
                        {t}
                      </a>
                    ))}
                    {AuthService.getCurrentUser().username ===
                      userInfo?.username && (
                      <>
                        <a
                          key={"active-hypes"}
                          onClick={() =>
                            history.push(`/user/${user}/active-hypes`)
                          }
                          className={classNames(
                            "border-transparent text-gray-500 hover:border-red-300 hover:text-gray-700",
                            "whitespace-nowrap py-4 px-1 border-b-2 font-clash_b text-sm dark:text-gray-200",
                            currentTab == "active-hypes" &&
                              "border-red-500 hover:border-red-500 "
                          )}
                        >
                          Active Hypes
                        </a>
                      </>
                    )}
                  </nav>
                </div>
              </div>

              {user != null && navigationSwitch()}
            </div>
          </div>
        </div>
      </section>
    </HeaderLayout>
  );
};

const HypeFeed = ({ username }) => {
  const [hypes, setHypes] = useState([]);

  const activity = [...hypes];

  activity.sort((a, b) => b.timestamp - a.timestamp);

  useEffect(() => {
    httpService
      .get(`/api/v1/hype/user/${username}/log?limit=50`)
      .then((res) => {
        const hypes = res.data.log;
        httpService
          .get(`/api/v1/token/${res.data.log.map((l) => l.token).join(",")}`)
          .then((res2) => {
            var tokens = res2.data.result;
            const hypeActivity = hypes.map((h) => ({
              id: h.token,
              token: tokens[h.token].title,
              avatar: tokens[h.token].avatar,
              content: {
                amount: h.amount,
              },
              timestamp: h.timestamp,
            }));
            setHypes(hypeActivity);
          });
      });
  }, []);

  return (
    <div className="p-4">
      {hypes.length > 0 ? (
        <>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 pb-4">
            The {hypes.length} most recent hypes of {username} are shown below.
          </p>
          <div className="md:shadow md:border md:dark:border-[#212121] md:dark:border-[#212121] p-4 rounded-lg">
            <ul
              role="list"
              className=" max-h-[20em] overflow-auto   scrollbar-thin dark:scrollbar-thumb-gray-900 dark:scrollbar-track-gray-700 scrollbar-thumb-gray-300 pr-4"
            >
              {activity.map((a, i) => (
                <li key={i}>
                  <div className="relative pb-8">
                    {i !== activity.length - 1 ? (
                      <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-1">
                      <div>
                        <div className="relative px-1">
                          <Link to={`/token/${a.id}`}>
                            <TokenAvatar
                              id={a.avatar}
                              className="w-8 h-8 mr-1 dark:bg-gray-500 bg-white"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 py-1.5">
                        <div className="text-sm text-gray-500 dark:text-gray-300 font-mulish">
                          <span className="font-medium text-gray-900 dark:text-gray-100">
                            {a.token}
                          </span>{" "}
                          <span
                            className={`${
                              a.content.amount > 1 && "text-[#F1573D]"
                            }`}
                          >
                            + {a.content.amount} Hype
                          </span>{" "}
                          <span className="whitespace-nowrap">
                            (
                            {dayjs
                              .unix(a.timestamp / 1000)
                              .format("D MMM - HH:mm")}
                            )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 pb-4">
          {username} has not hyped any tokens yet.
        </p>
      )}
    </div>
  );
};

export default PublicUserProfilePage;
