import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#121212] py-8">
      <div className="max-w-full lg:max-w-[1000px] xl:max-w-[1200px] mx-auto py-0 pt-4 xl:pt-0 px-2 lg:px-4 xl:px-[8px] flex flex-wrap items-center text-white font-mulish">
        <div className="w-full flex flex-col items-center justify-center lg:block lg:w-1/3 mb-8 lg:mb-0">
          <img
            className="w-[200px] mb-8"
            src="/img/hmt_footer_logo.svg"
            alt="HypeMyToken Logo"
          />
          <div className="flex items-center mb-4">
            <span className="mr-4">
              <a href="https://twitter.com/HypeMyToken">
                <img
                  className="w-6 h-6"
                  src="/img/brand/dark/twitter.svg"
                  alt="Twitter Link"
                />
              </a>
            </span>
            <span className="mr-4">
              <a href="https://medium.com/@HypeMyToken">
                <img
                  className="w-6 h-6"
                  src="/img/brand/dark/medium.svg"
                  alt="Medium Link"
                />
              </a>
            </span>
            <span className="mr-4">
              <a href="https://www.tiktok.com/@hypemytoken">
                <img
                  className="w-6 h-6"
                  src="/img/brand/dark/tiktok.svg"
                  alt="tiktok Link"
                />
              </a>
            </span>
            <span className="mr-4">
              <a href="https://www.instagram.com/hypemytoken/">
                <img
                  className="w-6 h-6"
                  src="/img/brand/dark/instagram.svg"
                  alt="instagram Link"
                />
              </a>
            </span>
            <span className="mr-4">
              <a href="https://t.me/HypeMyToken">
                <img
                  className="w-6 h-6"
                  src="/img/brand/dark/telegram.svg"
                  alt="telegram Link"
                />
              </a>
            </span>
          </div>
          <h6 className="md:text-left text-center">
            HypeMyToken ©2022. All Rights Reserved
          </h6>
        </div>
        <div className="flex flex-wrap lg:w-2/3 ml-8 md:ml-16 lg:ml-0 lg:pt-4 md:justify-start justify-center">
          <div className="mr-4 md:mr-0 md:mx-8 xl:mx-8 mb-4 md:mb-8">
            <h5 className="text-xl mb-4">Policies</h5>
            <ul className="text-xs md:text-sm">
              <li className="mb-2 hover:text-[#FD6902]">
                <Link to="/#">Cookie Statement</Link>
              </li>
              <li className="mb-2 hover:text-[#FD6902]">
                <Link to="/#">Terms & Conditions</Link>
              </li>
              <li className="mb-2 hover:text-[#FD6902]">
                <Link to="/#">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col ">
            <div className="mr-4 md:mr-0 md:mx-8 xl:mx-8 mb-4">
              <h5 className="text-lg ">Contact Us</h5>
              <ul className="text-xs md:text-sm">
                <li className="mb-2 hover:text-[#FD6902]">
                  <a href="mailto:info@hypemytoken.com">info@hypemytoken.com</a>
                </li>
              </ul>
            </div>
            <div className="mr-4 md:mr-0 md:mx-8 xl:mx-8 mb-4">
              <h5 className="text-lg ">Advertisement Space</h5>
              <ul className="text-xs md:text-sm">
                <li className="mb-2 hover:text-[#FD6902]">
                  <Link to="/advertising">Advertising</Link>
                </li>
                <li className="mb-2 hover:text-[#FD6902]">
                  <a href="mailto:info@hypemytoken.com">
                    business@hypemytoken.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mr-4 md:mr-0 md:mx-8 xl:mx-8 ">
            <h5 className="text-lg">Request Update</h5>
            <ul className="text-xs md:text-sm">
              <li className="mb-2 hover:text-[#FD6902]">
                <a href="mailto:update@hypemytoken.com">
                  update@hypemytoken.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
