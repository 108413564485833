import { useEffect, useState } from 'react'
import httpService from 'services/http-service'
import { Link, useParams, useHistory } from "react-router-dom";
import UserAccountSection from '../UserAccountSection/Section/UserAccountSection';




export default function UserProfilePanel() {
    const [userDetails, setUserDetails] = useState(null)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        httpService.get('/api/v1/user').then((res) => {
            setUserDetails(res.data)

        })
    }, [update]);

    return (
        <div>
            <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8 p-4">

                <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-2xl leading-6 font-bold text-gray-900 dark:text-gray-200 my-4 pb-2">Manage Profile</h1>
                </div>

                <div className="bg-white dark:bg-black rounded-lg shadow dark:shadow-[#121212] overflow-hidden border-gray-100 dark:border-gray-900 border  ">


                    <div className="divide-y divide-gray-200 dark:divide-gray-800  lg:col-span-9 ">
                        {userDetails != null &&
                            <UserAccountSection user={userDetails} onChange={() => setUpdate(u => !u)} />
                        }
                    </div>
                </div>

            </div>
        </div>
    )

}
