import { Dialog, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom"
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import axios from "axios";
import { ThemeContext } from "utils/theme";
import { Fragment, useContext, useEffect, useState } from "react"
import httpService from "services/http-service"
import Resizer from "react-image-file-resizer";
import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import S3 from 'react-aws-s3';
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function TokenAdminEditForm({ id, onClose }) {
    const [project, setProject] = useState(null);

    const [data, setData] = useState({})

    const [submitted, setSubmitted] = useState(false)
    const [avatar, setAvatar] = useState(null)
    const [allowedNetworks, setAllowedNetworks] = useState([])
    const [battleLogo, setBattleLogo] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [uploadingImages, setUploadingImages] = useState(false)
    const [missingData, setMissingData] = useState(false)
    const history = useHistory()
    const [avatarFileError, setAvatarFileError] = useState(false)
    const [battleLogoFileError, setBattleLogoFileError] = useState(false)
    const [submitError, setSubmitError] = useState(false)

    const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS,
        secretAccessKey: process.env.REACT_APP_SECRET,
    }

    const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            "JPG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0];
        if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
            setAvatarFileError(true)
            return
        }
        setAvatarFileError(false)
        const image = await resizeFile(file,128,128);
        setAvatar({ file: image, url: URL.createObjectURL(image) })
    }

    const handleChangeBattleLogo = async (e) => {
        const file = e.target.files[0];
        if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
            setBattleLogoFileError(true)
            return
        }
        setBattleLogoFileError(false)
        const image = await resizeFile(file,248,165);
        setBattleLogo({ file: image, url: URL.createObjectURL(image) })
    }


    const changeObj = (objName, key, value) => {
        setData(d => ({
            ...d,
            [objName]: {
                ...d[objName],
                [key]: value
            }
        }))
    }

    const removeFromObj = (objName, key) => {
        const s = { ...data[objName] }
        delete s[key]
        setData(d => ({ ...d, [objName]: { ...s } }))
    }

    const changeData = (key, value) => {
        setData(d => ({ ...d, [key]: value }))
    }

    useEffect(() => {
        httpService.get('/api/v1/token/properties/network').then(res => setAllowedNetworks(res.data.networks))

        httpService
            .get(`/api/v1/token/${id}`)
            .then((res) => setData({ ...res.data.result[id], ticker: res.data.result[id].ticker.substring(1) }))
            .catch((err) => setData(null));
    }, [])


    const submitFormData = () => {
        if (!validateFormData(data)) {

            setMissingData(true)
            return
        }
        setSubmitting(true)

        setUploadingImages(true)
        const avatarFormData = new FormData()
        avatarFormData.append("file", avatar?.file)
        avatarFormData.append("upload_preset", "dentb8lw")
        avatarFormData.append("cloud_name", "hypemytoken")
        const battleFormData = new FormData()
        battleFormData.append("file", battleLogo?.file)
        battleFormData.append("upload_preset", "dentb8lw")
        battleFormData.append("cloud_name", "hypemytoken")

        const ReactS3Client = new S3(config);
        const battleLogoFilename = `battle_${data.title}_${Date.now()}`
        const avatarFilename = `avatar_${data.title}_${Date.now()}`


        Promise.all([
            avatar != null && ReactS3Client.uploadFile(avatar?.file, avatarFilename),
            battleLogo != null && ReactS3Client.uploadFile(battleLogo?.file, battleLogoFilename)
        ]).then(() => {
            const formData = { ...data }
            formData.battleLogo = battleLogo != null ? battleLogoFilename : null
            formData.avatar = avatar != null ? avatarFilename : null
            formData['ticker'] = `#${formData.ticker}`
            setUploadingImages(false)
            httpService.put('/api/v1/admin/token', formData).then(() => {

                setSubmitted(true)
                onClose()
            }).catch(e => {

                setSubmitError(true)
            })
        })

    }



    return (
        <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-800 p-4 ">
            <div>
                <h1 className="text-2xl leading-6 font-medium text-gray-900 dark:text-gray-200 mb-2">Edit Token</h1>
            </div>
            <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-800 pt-4 sm:space-y-5">
                {missingData &&

                    <div className="rounded-md bg-red-700 p-2 mt-4 my-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <div className="text-sm text-white font-mulish">
                                    Please fill in the missing information!
                                </div>
                            </div>
                        </div>
                    </div>

                }

                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Token Details</h3>
                        {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Enter the correct details 
                        </p> */}
                    </div>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                Type
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md ">
                                    <fieldset className="flex ">

                                        <div className="flex items-center mx-2">
                                            <input
                                                id="push-everything"
                                                name="push-notifications"
                                                type="radio"
                                                checked={data.type === 'NFT'} onChange={(e) => changeData('type', 'NFT')}
                                                className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300  "
                                            />
                                            <label htmlFor="push-everything" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-400  dark:text-gray-300">
                                                NFT
                                            </label>
                                        </div>
                                        <div className="flex items-center mx-2">
                                            <input
                                                id="push-email"
                                                name="push-notifications"
                                                type="radio"
                                                checked={data.type === 'TOKEN'} onChange={(e) => changeData('type', 'TOKEN')}
                                                className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300"
                                            />
                                            <label htmlFor="push-email" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-400 dark:text-gray-300">
                                                Token
                                            </label>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">

                                Title
                                {!data.title && <span className={`text-red-600 text-xs ml-1`}>*</span>}
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg flex rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="title"
                                        value={data.title}
                                        onChange={(e) => changeData('title', e.target.value)}
                                        id="title"
                                        className="flex-1 block w-full focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                Network
                                {!data.network && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">

                                <div className="max-w-[15em] flex rounded-md shadow-sm">

                                    <select
                                        id="title"
                                        name="title"
                                        className="flex-1 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-white dark:border-black block w-full min-w-0 rounded-none rounded-r-md text-xs border-gray-300 font-mulish"
                                        value={data.chain}
                                        onChange={(e) => changeData('chain', e.target.value)}
                                    >
                                        <option></option>
                                        {allowedNetworks.map(n => <option>{n}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                Ticker
                                {!data.ticker && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                            </label>

                            <div className="mt-1 sm:mt-0 sm:col-span-2 relative">
                                <div className="max-w-[15em] flex rounded-md shadow-sm ">

                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-black text-xs dark:bg-gray-800 dark:text-white dark:border-black">#</span>
                                    </div>
                                    <input
                                        type="text"
                                        name="ticker"
                                        id="ticker"
                                        maxlength="10"
                                        value={data.ticker}
                                        onChange={(e) => changeData('ticker', e.target.value)}
                                        className="pl-6 flex-1 block w-full focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                                <span>
                                    Description
                                    {!data.description && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                                    <p className="text-xs text-gray-500">Provide a brief description of the token. {560 - (data.description ? data.description.length : 0)} characters left.</p>

                                </span>

                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    rows={5}
                                    value={data.description}
                                    onChange={(e) => changeData('description', e.target.value)}
                                    maxlength="560"
                                    className="max-w-lg shadow-sm block w-full focus:ring-red-500 focus:border-red-500 text-xs border border-gray-300 rounded-md dark:bg-gray-800 dark:text-white dark:border-black"
                                    defaultValue={''}
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                                <span>
                                    Avatar
                                    <p className="text-xs text-gray-500">PNG, JPG, JPEG</p>
                                </span>

                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="flex items-center">
                                    <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                        <img
                                            src={avatar == null ?
                                                data.avatar == null ? '/img/projects/DEFAULT_LOGO.png' : `https://hypemytoken.s3.eu-central-1.amazonaws.com/${data.avatar}.jpg`
                                                : avatar.url
                                            }
                                            className={"w-12 h-12 mr-2"}
                                        />
                                    </span>

                                    <label
                                        htmlFor="file-upload"
                                        className="ml-4 relative cursor-pointer border-2 bg-white dark:bg-black rounded-md border border-red-600 px-2 py-1 font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                                    >
                                        <span>Change</span>
                                        <input
                                            id="file-upload"
                                            name="file-upload"
                                            accept="image/*" type="file"
                                            className="sr-only"
                                            onChange={handleAvatarChange}
                                        />
                                    </label>

                                </div>
                            </div>
                            <FileError open={avatarFileError} onClose={() => setAvatarFileError(false)} />

                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                                <span>
                                    Battle Logo
                                    <p className="text-xs text-gray-500">This image will be displayed when this token is participating in a Hype Battle. PNG, JPG, JPEG. Max. resolution: 248 x 165</p>

                                </span>

                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="flex">
                                    <div className="space-y-1 text-center">
                                        <img
                                            src={battleLogo == null ? data.battleLogo ? `https://hypemytoken.s3.eu-central-1.amazonaws.com/${data.battleLogo}.jpg` : `/img/battle/hb_default.png` : battleLogo.url}
                                            className="h-[165px] w-[248px] mb-4 flex justify-center md:justify-start"

                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = "/img/battle/hb_default.png";
                                            }}
                                            alt="Hype Battle Project"
                                        />
                                        <label
                                            htmlFor="battle-upload"
                                            className="ml-4 relative cursor-pointer border-2 bg-white dark:bg-black rounded-md border border-red-600 px-2 py-1 font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500"
                                        >
                                            <span>Change</span>
                                            <input
                                                id="battle-upload"
                                                name="battle-upload"
                                                accept="image/*" type="file"
                                                className="sr-only"
                                                onChange={handleChangeBattleLogo}
                                            />
                                        </label>


                                    </div>
                                </div>
                                <FileError open={battleLogoFileError} onClose={() => battleLogoFileError(false)} />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">Community Details</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">
                                Official Website
                                {!data.website && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                    type="text"
                                    name="website"
                                    id="website"
                                    value={data.website}
                                    onChange={(e) => changeData('website', e.target.value)}
                                    className="max-w-lg block w-full shadow-sm focus:ring-red-500 focus:border-red-500 sm:max-w-xs text-xs border-gray-300 rounded-md dark:bg-gray-800 dark:text-white dark:border-black"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                                <span>
                                    Price Data
                                    {(!data.priceData || Object.keys(data?.priceData).length == 0) && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                                    <p className="text-xs text-gray-500">Provide at least one link to price data of the token.</p>

                                </span>

                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <CheckedTextInput
                                    title="Coinmarketcap"
                                    value={data?.priceData?.COINMARKETCAP}
                                    onChange={(v) => changeObj('priceData', 'COINMARKETCAP', v)}
                                    onDisable={() => removeFromObj('priceData', 'COINMARKETCAP')}
                                />
                                <CheckedTextInput
                                    title="Coingecko"
                                    value={data?.priceData?.COINGECKO}
                                    onChange={(v) => changeObj('priceData', 'COINGECKO', v)}
                                    onDisable={() => removeFromObj('priceData', 'COINGECKO')}
                                />
                                <CheckedTextInput
                                    title="DEX"
                                    value={data?.priceData?.DEX}
                                    onChange={(v) => changeObj('priceData', 'DEX', v)}
                                    onDisable={() => removeFromObj('priceData', 'DEX')}
                                />

                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2 mb-4">
                                <span>

                                    Social Platforms
                                    {(!data.socials || Object.keys(data?.socials).length == 0) && <span className={`text-red-600 text-xs ml-1`}>*</span>}

                                    <p className="text-xs text-gray-500">Provide a link to at least one social platform where the token is featured.</p>
                                </span>
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <CheckedTextInput
                                    title="Reddit"
                                    value={data?.socials?.REDDIT}
                                    onChange={(v) => changeObj('socials', 'REDDIT', v)}
                                    onDisable={() => removeFromObj('socials', 'REDDIT')}
                                />
                                <CheckedTextInput
                                    title="Discord"
                                    value={data?.socials?.DISCORD}
                                    onChange={(v) => changeObj('socials', 'DISCORD', v)}
                                    onDisable={() => removeFromObj('socials', 'DISCORD')}
                                />
                                <CheckedTextInput
                                    title="Telegram"
                                    value={data?.socials?.TELEGRAM}
                                    onChange={(v) => changeObj('socials', 'TELEGRAM', v)}
                                    onDisable={() => removeFromObj('socials', 'TELEGRAM')}
                                />
                                <CheckedTextInput
                                    title="Twitter"
                                    value={data?.socials?.TWITTER}
                                    onChange={(v) => changeObj('socials', 'TWITTER', v)}
                                    onDisable={() => removeFromObj('socials', 'TWITTER')}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {submitError &&
                <div className="rounded-md bg-red-700 p-2 mt-4 my-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <div className="text-sm text-white font-mulish">
                                Something went wrong while submitting your token.
                                Please check if all fields contain sane data.
                            </div>
                        </div>
                    </div>
                </div>

            }
            <div className="pt-5">
                <div className="flex justify-end">
                    {(!submitting || submitError) &&
                        <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => onClose()}
                        >
                            Cancel
                        </button>
                    }
                    <button
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={() => submitFormData()}

                    >
                        {(submitting && !submitError) ?
                            <div role="status" className="flex justify-start items-center">
                                <svg aria-hidden="true" class="mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                {uploadingImages ? "Uploading Images.." : "Submitting Token.."}
                            </div>
                            :
                            <>Save</>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}


const CheckedTextInput = ({ title, onChange, value, onDisable }) => {
    const { darkMode } = useContext(ThemeContext);

    const [enabled, setEnabled] = useState(false)


    return (
        <div className="mb-4" >
            <div className="relative flex items-start ">
                <div className="flex items-center h-5">
                    <input
                        name={title}
                        id={title}
                        type="checkbox"
                        checked={enabled || value?.length > 0}
                        onChange={e => {
                            setEnabled(e.target.checked)
                            if (!e.target.checked) onDisable()
                        }}
                        className={`focus:ring-red-500 h-4 w-4 text-red-600 ${darkMode && 'bg-gray-800'}   border-gray-300 rounded`}
                    />
                </div>
                <div className="ml-2 text-sm mr-4">
                    <label htmlFor="comments" className="font-medium text-gray-700 dark:text-white dark:text-gray-300 text-xs">
                        {title}
                    </label>
                </div>
            </div>
            <div className={`sm:col-span-4 ${!(enabled || value?.length > 0) && 'hidden'}`}>

                <div className="mt-2 flex rounded-md shadow-sm sm:col-span-2 sm:w-[50%]">

                    <input
                        type="text"
                        name={title}
                        id={title}
                        value={value ? value : ''}
                        onChange={e => {
                            onChange(e.target.value)
                            if (e.target.value.length == 0) onDisable()
                        }}
                        className="flex-1 block w-full focus:ring-red-500 focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300 dark:bg-gray-800 dark:text-white dark:border-black"
                    />
                </div>
            </div>
        </div>
    )
}



const FileError = ({ open, onClose }) => {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white  dark:bg-[#111111] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
                                            Couldn't accept your upload
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500 dark:text-gray-300">
                                                Supported extensions: JPEG, JPG and PNG
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                        onClick={onClose}
                                    >
                                        I understand
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

const validateFormData = (data) => {
    const fieldsPresent = ['title', 'ticker', 'chain', 'type', 'description', 'website', 'priceData', 'socials'].every(item => data.hasOwnProperty(item));
    const noTextEmpty = fieldsPresent ? ['title', 'ticker', 'chain', 'type', 'description', 'website'].every(item => data[item].length > 0) : false
    const noObjectsEmpty = fieldsPresent ? ['priceData', 'socials'].every(item => Object.keys(data[item]).length > 0) : false
    return fieldsPresent && noTextEmpty && noObjectsEmpty
}