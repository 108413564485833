import { useState } from "react"
import { Link, useHistory } from 'react-router-dom'


const HypeLimitPopup = ({ open, onClose }) => {



    return (
        <>

            <div id="defaultModal" tabIndex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 -translate-y-1/2 max-w-lg w-full -translate-x-1/2 z-50 h-modal `} >
                <div class="relative p-8 pt-4 flex justify-center max-w-lg bg-white max-h-lg rounded flex-col dark:bg-[#111111]">
                    <h className="font-clash_b text-lg pb-4">Attention!</h>
                    <p className="font-clash">
                        The daily anonymous hype limit for this token has been exceeded! <br/>
                        <Link to='/register' className="text-gray-800 dark:text-gray-50 font-clash_b">Create an account</Link> to hype this token or try again tomorrow!
                    </p>
                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>
        </>
    )
}


export default HypeLimitPopup