import axios from "axios"


export default function TokenTitleField({ data, changeData, setTitleExists, titleExists }) {

    const checkTitle = () => {
        axios.get("/api/v1/token/title/exists/" + data.title).then(e => setTitleExists(e.data))
    }


    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2">

                Title
                {!data.title && <span className={`text-red-600 text-xs ml-1`}>*</span>}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                        type="text"
                        name="title"
                        value={data.title}
                        onChange={(e) => changeData('title', e.target.value)}
                        onBlur={() => checkTitle()}

                        id="title"
                        className="flex-1 block w-full focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
                    />

                </div>
                {titleExists && <p class="text-red-500 text-xs mt-2 italic">Title already in use, please use a different one!</p>}

            </div>
        </div>
    )
}

