import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import Graph from "components/presentation/HypeGraphSmall/HypeGraphSmall";
import { HypeButton } from "components/buttons/HypeButton/HypeButton";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpService from "../../../services/http-service";


const SimilarTokens = ({ darkMode }) => {
    const [projects, setProjects] = useState([])
    useEffect(() => {
        httpService
            .get("/api/v1/token?order=random&size=2")
            .then((res) => {
                setProjects(res.data.listings);
            })
            .catch((err) => setProjects([]));
    }, []);

    if (projects.length == 0) return null;

    return (
        <div className="mt-16">
            <h3 className="font-clash_b text-black dark:text-white mb-4">
                Recommended
            </h3>

            <div className="flex flex-wrap items-center justify-between ">
                {projects.map(p =>
                    <SimilarProject darkMode={darkMode} projectID={p.id} />
                )}
            </div>
        </div>
    );
};

const SimilarProject = ({ darkMode, projectID }) => {
    const [project, setProject] = useState(null);

    // Project Data
    useEffect(() => {
        httpService
            .get(`/api/v1/token/${projectID}`)
            .then((res) => {
                setProject(res.data.result[projectID]);
            })
            .catch((err) => setProject(null));
    }, [projectID]);

    if (!project) {
        return null;
    }

    return (
        <div className="w-full md:w-[49%] mb-4">
            <div
                className="border-2 border-[#FF5B0A] flex items-center justify-between p-4 lg:p-8 rounded-lg bg-[#48D27716] dark:bg-[#48D27713] text-black dark:text-white font-mulish h-[8em]">

                <div className="flex">
                    <Link to={`/token/${projectID}`}>
                        <Avatar
                            id={project.avatar}
                            className="w-12 h-12 mr-4"
                        />
                    </Link>
                    <div className="">
                        <h2 className="font-mulish_b lg:text-xl lg:mb-4">
                            <Link to={`/token/${projectID}`}>
                                {project.title}{" "}
                                <span className="font-mulish text-xs">({project.chain})</span>
                            </Link>
                        </h2>
                        <span className="flex md:justify-start text-xs">
                            <span
                                className="bg-white dark:bg-white/10 p-1 md:p-0 mt-1 md:mt-0 mr-4 rounded md:bg-transparent md:dark:bg-transparent">
                                {project.ticker}
                            </span>
                            <span
                                className="bg-white dark:bg-white/10 p-1 md:p-0 mt-1 md:mt-0 rounded md:bg-transparent md:dark:bg-transparent">
                                {project.type}
                            </span>
                        </span>
                    </div>
    
                </div>
                <HypeButton styling={"w-10 h-10 md:w-10 md:h-10 lg:w-12 lg:h-12"} projectId={projectID} />

            </div>
        </div>
    );
};


export default SimilarTokens