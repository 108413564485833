import { useEffect, useState } from 'react'
import httpService from 'services/http-service'

import { useHistory } from "react-router-dom";

const UserActiveHypeSection = () => {
    const [hypes, setHypes] = useState([])
    const history = useHistory()

    useEffect(() => {
        httpService.get('/api/v1/hype/active').then(r => setHypes(r.data.hypes))
    }, [])

    const durationText = (duration) => {
        const ms = duration / 1000
        const h = Math.floor(ms / 3600)
        const m = Math.floor((ms % 3600) / 60)
        const s = Math.floor((ms % 3600) % 60)
        return `${h}h ${m}m ${s}s`
    }

    return (
        <div className="p-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                        All your active hypes are shown below. For every active hype you're able to see which project you hyped, at what time you hyped it,
                        the time until the hype will be revoked, and for how much hypes it counted in the platform.
                    </p>
                </div>

            </div>
            {hypes.length == 0 ?
                <p className="mt-8 text-sm text-gray-500 dark:text-gray-400">
                    You have no active hypes. <a className='underline cursor-pointer' onClick={() => history.push('/')}>Start hyping your favorite tokens!</a>
                </p>
                :
                <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ">

                    <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
                        <thead className="bg-gray-50 dark:bg-[#212121]">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font text-gray-900 dark:text-gray-300 sm:pl-6">
                                    Token
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                                >
                                    Hyped At
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                                >
                                    Duration
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-900 bg-white dark:bg-[#121212] ">
                            {hypes.map((hype) => (
                                <tr key={hype.tokenId + "-" + hype.hypedAt}>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6">
                                        {hype.title}
                                        <dl className="font-normal lg:hidden">
                                            <dt className="sr-only ">Token</dt>
                                            <dd className="mt-1 truncate text-gray-700 dark:text-gray-200">{hype.title}</dd>
                                            <dt className="sr-only sm:hidden">Hyped At</dt>
                                            <dd className="mt-1 truncate text-gray-500 sm:hidden dark:text-gray-300 text-xs">{new Date(hype.hypedAt).toLocaleDateString()} {new Date(hype.hypedAt).toLocaleTimeString()}</dd>
                                            <dt className="sr-only sm:hidden">Duration</dt>
                                            <dd className="mt-1 truncate text-gray-500 sm:hidden dark:text-gray-300 text-xs">Duration: {durationText(hype.duration)}</dd>
                                        </dl>
                                    </td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{new Date(hype.hypedAt).toLocaleDateString()} {new Date(hype.hypedAt).toLocaleTimeString()}</td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{durationText(hype.duration)}</td>
                                    <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-200">{hype.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            }
        </div>
    )
}
export default UserActiveHypeSection