import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BattleCard from "../BattleCard/BattleCard";
import BattleTimeRemaining from "../BattleTimeRemaining/BattleTimeRemaining";

const Battle = ({ data }) => {
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    if (data.winner) {
      // Set Hype Battle Winner ID
      setWinner(data.winner);
    }
  }, [data]);

  const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };

  if (data.hypes === undefined) return null;
  return (
    <div className="bg-[#F9F9F9] dark:bg-[#131212] p-2 md:p-4 text-hmt_text_color_light dark:text-hmt_text_color_dark">
      <h2 className="font-clash_b text-xl mb-4 flex items-center justify-center md:justify-start">
        Hype Battle {winner ? "Winner" : "Active"}{" "}
        <span className="ml-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M13 8V0L8.11 5.87 3 12h4v8L17 8h-4z" />
          </svg>
        </span>
      </h2>
      <div
        className={`flex ${
          winner && "justify-center"
        } items-center w-full overflow-x-auto mb-8  scrollbar scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-900 dark:scrollbar-track-[#111111]  scrollbar-thin`}
      >
        {winner
          ? Object.keys(data.hypes)
              .sort(function (a, b) {
                return data.hypes[b] - data.hypes[a];
              })
              .map((p, i) => (
                <BattleCard
                  key={p}
                  projectId={p}
                  hypes={data.hypes[p]}
                  victory={winner ? winner.toString() === p : null}
                  enableHype={!winner}
                />
              ))
          : shuffle(Object.keys(data.hypes)).map((p, i) => (
              <BattleCard
                key={p}
                projectId={p}
                hypes={data.hypes[p]}
                victory={winner ? winner.toString() === p : null}
                enableHype={!winner}
              />
            ))}
      </div>
      {!winner && <BattleTimeRemaining time={data.endsAt} />}
      <div className="flex align-center justify-center">
        <Link
          className="text-sm sm:text-base font-semibold leading-7 text-[#FF5B0A]"
          to="/advertising"
        >
          Want to promote your Token? Contact us!{" "}
          <span aria-hidden="true">→</span>
        </Link>
      </div>
    </div>
  );
};

export default Battle;
