import { useEffect } from "react";
import HeaderLayout from "../../../components/layout/HeaderLayout/HeaderLayout";

const GiveAwayEntry = ({ link, description, image, title, criteria, date, type, left }) => {

    const getClickableLink = link => {
        if (link == null) return
        return link.startsWith("http://") || link.startsWith("https://") ?
            link
            : `http://${link}`;
    };


    return (
        <div className="flex flex-col justify-center items-center p-8 ">
            <div className="md:w-[40%] md:h-[40%] mb-2 w-[80%] h-[80%]">
                <a href={getClickableLink(link)} target="_blank" rel="noopener noreferrer">
                    <img src={image} />
                </a>
            </div>
            <div className="flex flex-col md:max-w-[70%] items-center">
                <p className="dark:text-white text-md md:text-xl mb-2 text-center">{title}</p>
                <p className="dark:text-gray-300 text-gray-800 mb-2 text-center text-xs md:text-md">{description}</p>
                <p className="dark:text-white text-center mt-2 md:text-lg">Criteria:</p>
                <ul className="dark:text-gray-300 text-gray-800 mb-4 text-xs md:text-sm ml-4 text-center md:text-left">
                    {criteria && criteria.map(c => <li>- {c}</li>)}
                </ul>
                {type == "EXTERNAL" ?
                    <>
                        {date ?
                                <p className="dark:text-gray-300 text-gray-800 mb-2 underline">End Date: {new Date(date - 86400000).toLocaleDateString()}</p>
                                :
                                <p>
                                    <p className="dark:text-gray-300 text-gray-800 mb-2 underline">Runs until out of stock</p>

                                </p>

                        }
                    </>
                    :
                    <>
                        {left >= 9999 ?
                            <p className="dark:text-gray-300 text-gray-800 mb-2 underline">This Give Away does not end</p>
                            :
                            <p className="dark:text-gray-300 text-gray-800 mb-2 underline">{left} Wins left until this Give Away ends</p>

                        }
                    </>

                }
            </div>
        </div >
    );
};


export default GiveAwayEntry;
