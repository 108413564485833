import { useEffect, useState } from "react";
import httpService from "services/http-service";

import S3 from 'react-aws-s3';
import GiveAwayTitleField from "../GiveAwayFields/GiveAwayTitleField/GiveAwayTitleField";
import GiveAwayDescriptionField from "../GiveAwayFields/GiveAwayDescriptionField/GiveAwayDescriptionField";
import GiveAwayCriteriaField from "../GiveAwayFields/GiveAwayCriteriaField/GiveAwayCriteriaField";
import GiveAwayLinkField from "../GiveAwayFields/GiveAwayLinkField/GiveAwayLinkField";
import GiveAwayResourceField from "../GiveAwayFields/GiveAwayResourceField/GiveAwayResourceField";
import GiveAwayDateFieldEnd from "../GiveAwayFields/GiveAwayDateFieldEnd/GiveAwayDateFieldEnd";
import GiveAwayTypeField from "../GiveAwayFields/GiveAwayTypeField/GiveAwayTypeField";
import GiveAwayTargetEndDay from "../GiveAwayFields/GiveAwayTargetEndDay/GiveAwayTargetEndDay";
import GiveAwayNumberOfWins from "../GiveAwayFields/GiveAwayNumberOfWins/GiveAwayNumberOfWins";

const GiveAwaySection = () => {
    const [giveAways, setGiveAways] = useState([])
    const [editGiveAway, setEditGiveAway] = useState(false)
    const [winners, setWinners] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        httpService.get('/api/v1/admin/giveaway').then(r => setGiveAways(r.data.giveAways))
        httpService.get('/api/v1/admin/giveaway/winners').then(r => setWinners(r.data.giveAways))
    }, [reload])

    const deleteAd = giveaway => {
        httpService.delete(`/api/v1/admin/giveaway/${giveaway.id}`).then(r => setReload(r => !r))
    }

    const getClickableLink = link => {
        return link.startsWith("http://") || link.startsWith("https://") ?
            link
            : `http://${link}`;
    };

    return (
        <div className="p-4">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                        All GiveAways are shown below
                    </p>
                </div>

            </div>
            <button
                type="button"
                class="text-white mt-4 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-1 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                onClick={() => setEditGiveAway({})}>
                Create new
            </button>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ">
                <p className="dark:text-white mb-2">External Give Aways</p>
                <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
                    <thead className="bg-gray-50 dark:bg-[#212121]">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font text-gray-900 dark:text-gray-300 sm:pl-6">
                                Title
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Desciption
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Link
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Resource
                            </th>
                            <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">
                                End
                            </th>
                            <th scope="col" className="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">

                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-900 bg-white dark:bg-[#121212] ">
                        {giveAways.filter(ga => ga.type === "EXTERNAL").map((ga) => (
                            <tr key={ga.id} >
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6">
                                    {ga.title}
                                    <dl className="font-normal sm:hidden">
                                        <dt className="sr-only ">Description</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200">{ga.description}</dd>
                                        <dt className="sr-only ">End</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200">{ga.end != null && new Date(ga.end).toLocaleDateString()}</dd>
                                        <dt className="sr-only ">Resource</dt>
                                        <dd className="my-4"><a className="border p-1 text-xs rounded-lg" href={ga.resource} target="_blank" rel="noopener noreferrer">View Image</a></dd>
                                        <dt className="sr-only ">Link</dt>
                                        <dd className="my-4"><a className="border p-1 text-xs rounded-lg" href={getClickableLink(ga.link)} target="_blank" rel="noopener noreferrer">View Link</a></dd>
                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{ga.description}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell"><a className="border p-2 text-xs rounded-lg" href={getClickableLink(ga.link)} target="_blank" rel="noopener noreferrer">View</a></td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell"><a className="border p-2 text-xs rounded-lg" href={getClickableLink(ga.resource)} target="_blank" rel="noopener noreferrer">View</a></td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{ga.end != null && new Date(ga.end).toLocaleDateString()}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-200">
                                    <div className="flex">
                                        <button className="mr-4" onClick={() => setEditGiveAway(ga)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 dark:text-gray-200">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                            </svg>


                                        </button>
                                        <button onClick={() => deleteAd(ga)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>

                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ">
                <p className="dark:text-white mb-2">Hype Click Give Aways</p>
                <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
                    <thead className="bg-gray-50 dark:bg-[#212121]">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font text-gray-900 dark:text-gray-300 sm:pl-6">
                                Title
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Desciption
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Link
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Resource
                            </th>
                            <th scope="col" className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">
                                Probability
                            </th>
                            <th scope="col" className="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">

                            </th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 dark:divide-gray-900 bg-white dark:bg-[#121212] ">
                        {giveAways.filter(ga => ga.type === "HYPE_CLICK").map((ga) => (
                            <tr key={ga.id} >
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6">
                                    {ga.title}
                                    <dl className="font-normal sm:hidden">
                                        <dt className="sr-only ">Description</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200">{ga.description}</dd>
                                        <dt className="sr-only ">End</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200">{ga.end}</dd>
                                        <dt className="sr-only ">Resource</dt>
                                        <dd className="my-4"><a className="border p-1 text-xs rounded-lg" href={ga.resource} target="_blank" rel="noopener noreferrer">View Image</a></dd>
                                        <dt className="sr-only ">Link</dt>
                                        <dd className="my-4"><a className="border p-1 text-xs rounded-lg" href={getClickableLink(ga.link)} target="_blank" rel="noopener noreferrer">View Link</a></dd>
                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{ga.description}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell"><a className="border p-2 text-xs rounded-lg" href={getClickableLink(ga.link)} target="_blank" rel="noopener noreferrer">View</a></td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell"><a className="border p-2 text-xs rounded-lg" href={getClickableLink(ga.resource)} target="_blank" rel="noopener noreferrer">View</a></td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{ga.probability.toFixed(6)}</td>
                                <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-200">
                                    <div className="flex">
                                        <button className="mr-4" onClick={() => setEditGiveAway(ga)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 dark:text-gray-200">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                            </svg>


                                        </button>
                                        <button onClick={() => deleteAd(ga)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>

                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg ">
                <p className="dark:text-white mb-2">Unclaimed GiveAway Winners</p>
                <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-800">
                    <thead className="bg-gray-50 dark:bg-[#212121]">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font text-gray-900 dark:text-gray-300 sm:pl-6">
                                Username
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                GiveAway
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Email
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300 sm:table-cell"
                            >
                                Wallets
                            </th>
                            <th scope="col" className="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-300">

                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-900 bg-white dark:bg-[#121212] ">
                        {winners.map((w) => (
                            <tr key={w.username} >
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-200 sm:w-auto sm:max-w-none sm:pl-6">
                                    {w.username}
                                    <dl className="font-normal sm:hidden">
                                        <dt className="sr-only ">Username</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200">{w.username}</dd>
                                        <dt className="sr-only ">Email</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200">{w.email}</dd>
                                        <dt className="sr-only ">Wallets</dt>
                                        <dd className="mt-1 truncate text-gray-700 text-xs dark:text-gray-200 flex">
                                            {Object.keys(w.wallets).map(k => <div className="">{k}: {w.wallets[k]}</div>)}
                                        </dd>

                                    </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{w.title}</td>
                                <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-gray-200 sm:table-cell">{w.email}</td>
                                <td className="hidden px-3 py-4 text-xs text-gray-500 dark:text-gray-200 sm:table-cell"> {Object.keys(w.wallets).map(k => <div >{k}: {w.wallets[k]}</div>)}</td>
                                <td className="px-1 py-4 text-sm text-gray-500 dark:text-gray-200">
                                    <div className="flex">
                                        <button className="border rounded-lg px-2 py-1 border-red-500" onClick={() =>
                                             httpService.put(`/api/v1/admin/giveaway/winners/claim/${w.id}`).then(r => setReload(r => !r))
                                        }>
                                            Claim
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {editGiveAway &&
                <EditAdvertisement open={true} data={editGiveAway} onClose={() => {
                    setEditGiveAway(null)
                    setReload(r => !r)
                }} />
            }
        </div>
    )
}


const EditAdvertisement = ({ open, onClose, data }) => {
    const [newData, setNewData] = useState(data);
    const [resource, setResource] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [resourceChanged, setResourceChanged] = useState(false)


    const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS,
        secretAccessKey: process.env.REACT_APP_SECRET,
    }

    const submitFormData = () => {
        const ReactS3Client = new S3(config);
        const filename = `ad_${newData.owner}_${Date.now()}`

        Promise.all([
            resourceChanged && ReactS3Client.uploadFile(resource?.file, `${filename}`),
        ]).then((e) => {
            console.log(e)
            const formData = { ...newData }
            if (resourceChanged)
                formData.resource = e[0].location
            if (data.id != undefined)
                httpService.put('/api/v1/admin/giveaway', formData).then(() => onClose())
            else
                httpService.post('/api/v1/admin/giveaway', formData).then(() => onClose())
        })

    }
    const changeData = (key, value) => {
        setNewData(d => ({ ...d, [key]: value }))
    }

    if (!newData.type) changeData("type", "EXTERNAL")

    return (
        <>

            <div id="defaultModal" tabindex="-1" class={` ${!open && 'hidden'} overscroll-none overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2 -translate-y-1/2 w-full max-w-2xl -translate-x-1/2 z-50 h-modal `} >
                <div class="relative p-4  ">
                    <div class="relative bg-white rounded-lg shadow dark:bg-[#212121] ">
                        <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600 ">
                            <h3 class="text-xl font-clash_b text-gray-900 dark:text-white">
                                {data.id != undefined ? "Edit" : "Create"} GiveAway
                            </h3>

                            <button type="button" onClick={() => onClose()} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div class="p-6 space-y-6 dark:text-white font-mulish text-xs overflow-y-auto h-[50vh]  scrollbar-thin dark:scrollbar-thumb-gray-900 dark:scrollbar-track-gray-700 scrollbar-thumb-gray-300">
                            <GiveAwayTypeField data={newData} changeData={changeData} />
                            <GiveAwayTitleField data={newData} changeData={changeData} />
                            <GiveAwayDescriptionField data={newData} changeData={changeData} />
                            <GiveAwayCriteriaField data={newData} changeData={changeData} />
                            <GiveAwayLinkField data={newData} changeData={changeData} />
                            <GiveAwayResourceField resource={resource} setResource={r => {
                                setResource(r)
                                setResourceChanged(true)
                            }} />
                            {newData.type === "EXTERNAL" &&
                                <GiveAwayDateFieldEnd data={newData} changeData={changeData} />
                            }

                            {newData.type === "HYPE_CLICK" &&
                                <>
                                    <GiveAwayTargetEndDay data={newData} changeData={changeData} />
                                    <GiveAwayNumberOfWins data={newData} changeData={changeData} />
                                </>
                            }
                        </div>
                        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                            <button data-modal-toggle="defaultModal" type="button" class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={() => {
                                submitFormData()
                            }}>Save</button>
                            <button onClick={() => onClose()} data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel</button>

                        </div>

                    </div>
                </div>

            </div >
            <div class={` ${!open && 'hidden'} opacity-75 fixed inset-0 z-40 bg-black`} onClick={onClose}></div>
        </>
    )
}

export default GiveAwaySection;
