import { BrowserRouter as Router } from "react-router-dom";
// Pages
// Components
import { useDispatch } from 'react-redux';
import { setFollowingTokens, setRestrictedProjects } from "./features/user/userSlice";
import AuthService from "./services/AuthService";
import httpService from "./services/http-service";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "utils/theme";
import { SEO } from "components/presentation/SEO/SEO";
import HypeMyTokenRoutes from "components/routing/Routes/HypeMyTokenRoutes";
import { ClientJS } from 'clientjs';

function App() {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);
    const dispatch = useDispatch()
    const client = new ClientJS();
    const [userChange, setUserChange] = useState(false)
    window.addEventListener('storage', () => {
        setUserChange(u => !u)
    })

    useEffect(() => {
        const storedTheme = sessionStorage.getItem("hypemytoken_theme");

        if (storedTheme == null && AuthService.isLoggedIn()) {

            httpService.get('/api/v1/user/settings').then(r => {
                const prefersDark = r.data.prefersDarkmode
                if (!prefersDark && darkMode) {
                    toggleDarkMode()
                } else if (prefersDark && !darkMode) {
                    toggleDarkMode()
                }
            })
        } else if (storedTheme != null) {
            if (storedTheme === 'light' && darkMode) {
                toggleDarkMode()
            } else if (storedTheme === 'dark' && !darkMode) {
                toggleDarkMode()
            }
        }
        const fingerprint = client.getFingerprint();
        httpService.get(`/api/v1/hype/cooldown`, { headers: { "X-Request-ID": fingerprint } })
            .then((res) => dispatch(setRestrictedProjects(res.data.tokens)))
            .catch(() => dispatch(setRestrictedProjects([])))
        if (AuthService.isLoggedIn()) {
            httpService.get(`/api/v1/user/token/follow/${AuthService.getCurrentUser().username}`)
                .then((res) => dispatch(setFollowingTokens(res.data.listings.map(t => t.id))))
                .catch(() => dispatch(setFollowingTokens([])))
        }
    }, [userChange]);




    return (

        <main className="bg-white dark:bg-black">
            <SEO
                title={"HypeMyToken"}
                description="The platform to Hype up your favourite crypto tokens"
            />

            <Router>
                <HypeMyTokenRoutes />
            </Router>


        </main >
    );
}





export default App;
