import axios from "axios"


export default function GiveAwayTargetEndDay({ data, changeData }) {

    return (
        <div className="flex-col items-center">
            <label htmlFor="end" className="block text-md text-gray-700 dark:text-gray-400 ">

                Target days until Win
                {!data?.end && <span className={`text-red-600 text-xs ml-1`}>*</span>}
            </label>
            <div className="mt-1">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                        type="number"
                        name="end"
                        value={data?.end}
                        onChange={(e) => changeData('end', e.target.value)}

                        id="end"
                        className=" block w-[8em] focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
                    />

                </div>

            </div>
        </div> 
    )
}

