import axios from 'axios';
import authHeader from "./auth-header";
import { useHistory } from "react-router-dom";
import AuthService from "../services/AuthService"
// axios instance for making requests
const httpService = axios.create();


// request interceptor for adding token
httpService.interceptors.request.use((config) => {
    // add token to request headers
    config.headers['Authorization'] = authHeader();
    return config;
});

httpService.interceptors.response.use(response => { return response; }, error => {
    if (error.response.status === 401) {
        AuthService.logout()
        window.location.reload();
        return Promise.resolve()
    }
    return Promise.reject(error)
});


export default httpService;