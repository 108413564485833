const TokenDetails = ({ project, darkMode }) => {
  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };

  return (
    <div className="flex flex-wrap md:flex-nowrap justify-between text-black dark:text-white py-8 border-t md:border-b border-gray-200 dark:border-gray-800">
      <div className="md:w-1/3">
        <div className="flex-col flex-wrap md:flex-nowrap items-center mb-4">
          <span>Website</span>
          <div className="font-mulish_b md:truncate mr-3">
            <a
              className="text-[#FD6902] "
              href={getClickableLink(project.projectLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.projectLink}
            </a>
          </div>
        </div>

        <CommunitySection project={project} />
        <PriceDataSection project={project} />
        {project.listedAt && (
          <div className="flex-col flex-wrap md:flex-nowrap items-center mb-4">
            <span>Listed at</span>
            <div className="font-mulish_b md:truncate mr-3 dark:text-gray-300 text-gray-600 text-sm">
              {new Date(project.listedAt).toLocaleDateString()}{" "}
              {new Date(project.listedAt).toLocaleTimeString()}
            </div>
          </div>
        )}
      </div>
      <div className="md:w-2/3">
        <h5 className="md:text-lg mr-1 text-hmt_text_color_light dark:text-hmt_text_color_dark font-clash_sb mb-4">
          About
        </h5>
        <p className="font-mulish text-hmt_text_color_light dark:text-hmt_text_color_dark text-ellipsis overflow-hidden">
          {project.description}
        </p>
      </div>
    </div>
  );
};

const CommunitySection = ({ project }) => {
  return (
    <div className="flex-col flex-wrap md:flex-nowrap items-center mb-4">
      <span>Community</span>
      <span className="flex mt-2 items-center">
        {project.socials.TWITTER && (
          <a
            className="mr-2"
            href={project.socials.TWITTER}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/Twitter-logo.svg"
              alt="Twitter"
              className="h-6 w-6"
            />
          </a>
        )}
        {project.socials.REDDIT && (
          <a
            className="mr-2"
            href={project.socials.REDDIT}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/reddit_color.svg"
              alt="Reddit"
              className="h-6 w-6"
            />
          </a>
        )}
        {project.socials.DISCORD && (
          <a
            className="mr-2"
            href={project.socials.DISCORD}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/discord-color.svg"
              alt="Discord"
              className="h-6 w-6"
            />
          </a>
        )}
        {project.socials.TELEGRAM && (
          <a
            className="mr-2"
            href={project.socials.TELEGRAM}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/telegram_color.svg"
              alt="Telegram"
              className="h-6 w-6"
            />
          </a>
        )}
      </span>
    </div>
  );
};

const PriceDataSection = ({ project }) => {
  return (
    <div className="flex-col flex-wrap md:flex-nowrap items-center mb-4">
      <span>Price Data</span>
      <span className="flex items-center mt-2">
        {project.priceData.COINGECKO && (
          <a
            className="mr-2"
            href={project.priceData.COINGECKO}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/coingecko.svg"
              alt="Coingecko"
              className="h-6 w-6"
            />
          </a>
        )}
        {project.priceData.COINMARKETCAP && (
          <a
            className="mr-2"
            href={project.priceData.COINMARKETCAP}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/coinmarketcap.svg"
              alt="Coinmarketcap"
              className="h-6 w-6"
            />
          </a>
        )}
        {project.priceData.DEX && (
          <a
            className="mr-2"
            href={project.priceData.DEX}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/img/brand/dex.svg" alt="DEX" className="h-6 w-6" />
          </a>
        )}
        {project.priceData.MARKETPLACE && (
          <a
            className="mr-2"
            href={project.priceData.MARKETPLACE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/brand/marketplace.svg"
              alt="MARKETPLACE"
              className="h-6 w-6"
            />
          </a>
        )}
      </span>
    </div>
  );
};
export default TokenDetails;
