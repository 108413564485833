import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import { Darkmode } from "components/layout/ThemeToggler/ThemeToggler";
import HypeMeter from "views/Token/TokenHypeMeter/TokenHypeMeter";
import { useEffect, useState } from "react";
import httpService from "../../../services/http-service";
import TokenHypeMeter from "views/Token/TokenHypeMeter/TokenHypeMeter";
import { useSelector, useDispatch } from 'react-redux'
import { addFollowingToken, removeFollowingToken, selectFollowingTokens } from "features/user/userSlice";
import TokenFollowers from "../TokenFollowers/TokenFollowers";
import AuthService from "services/AuthService";


const TokenHeader = ({ project, darkMode, submission = false }) => {
    const [hypes, setHypes] = useState(0);
    const [ranking, setRanking] = useState(submission ? 1 : null)
    const dispatch = useDispatch()
    const followingTokens = useSelector(selectFollowingTokens)
    const [changed, setChanged] = useState(false)
    const [followersData, setFollowersData] = useState(null)

    useEffect(() => {
        setFollowersData(null)
        httpService.get(`/api/v1/token/followers/${project.id}`).then((res) => {
            setFollowersData(res.data);

        })

    }, [changed]);

    useEffect(() => {
        httpService
            .get(`/api/v1/token/${project.id}/tier`)
            .then((res) => {
                setHypes(res.data.hypes);
            })
            .catch((err) => setHypes(0));
        if (!submission)
            httpService.get(`/api/v1/token/${project.id}/ranking`).then((res) => setRanking(res.data.ranking))
    }, [project]);

    return (
        <header className="mt-16 mb-8">
            <div className="flex flex-wrap md:flex-nowrap items-center justify-between mb-4 md:mb-8">
                <div className="flex-col">
                    <div className="flex items-center mb-4 md:mb-2 md:mr-16">
                        <Avatar
                            id={project.avatar}
                            className="w-10 h-10 mr-2"
                        />
                        <h1 className="font-clash_b text-2xl mr-1 text-hmt_text_color_light dark:text-hmt_text_color_dark ">
                            {project.title}{" "}
                            #{ranking}
                        </h1>


                    </div>
                    <TokenFollowers data={followersData} />
                    {AuthService.isLoggedIn() &&
                        <button
                            className="bg-white dark:bg-black dark:text-white text-xs  px-2 py-1 mr-2 rounded-lg border border-2 border-gray-500"
                            onClick={() => {
                                if (followingTokens.includes(project.id))
                                    httpService.delete(`/api/v1/user/token/follow/${project.id}`).then(() => {
                                        dispatch(removeFollowingToken(project.id))
                                        setChanged(t => !t)
                                    })
                                else
                                    httpService.post(`/api/v1/user/token/follow/${project.id}`).then(() => {
                                        dispatch(addFollowingToken(project.id))
                                        setChanged(t => !t)
                                    })



                            }}
                        >
                            {followingTokens.includes(project.id) ? "Unfollow" : "Follow"}
                        </button>
                    }
                </div>
                <div className="flex  items-center md:mt-0 mt-8">
                    <DogTags project={project} />
                    {!submission &&

                        <HypeCount darkMode={darkMode} hypes={hypes} />
                    }
                </div>
            </div>

            {
                !submission &&
                <TokenHypeMeter
                    id={project.id}
                    darkMode={darkMode}
                    hypes={hypes}
                    setHypes={setHypes}
                />
            }

        </header >

    );
};


const DogTags = ({ project }) => {
    return (
        <div className="flex flex-wrap items-center">
            <span
                className="bg-[#f6f6f6] mb-2 dark:bg-[#444444] text-hmt_text_color_light dark:text-hmt_text_color_dark text-sm font-mulish px-2 py-1 mr-2 rounded-lg">
                {project.ticker ? project.ticker : 'N/a'}
            </span>
            <span
                className="bg-[#f6f6f6] mb-2 dark:bg-[#444444] text-hmt_text_color_light dark:text-hmt_text_color_dark text-sm  font-mulish px-2 py-1 mr-2 rounded-lg">
                {project.type ? project.type : 'N/a'}
            </span>
            <span
                className="bg-[#f6f6f6] mb-2 dark:bg-[#444444] truncate text-hmt_text_color_light dark:text-hmt_text_color_dark text-sm  font-mulish px-2 py-1 mr-2 rounded-lg">
                {project.chain ? project.chain : 'N/a'}
            </span>
            {project.listedAt &&
                <span className="bg-[#f6f6f6] mb-2 dark:bg-[#444444] truncate text-hmt_text_color_light dark:text-hmt_text_color_dark text-sm  font-mulish px-2 py-1 mr-2 rounded-lg">
                    <div className="flex">
                        Listed
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 text-green-500 w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                    </div>
                </span>

            }
        </div>
    );
}

export default TokenHeader

const HypeCount = ({ darkMode, hypes }) => {
    return (
        <span className="ml-4 md:ml-16 mr-4 md:mr-1 flex items-center text-[#FF5B09] dark:text-[#F80043] font-mulish_sb">
            <img
                className="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 mr-1"
                src={darkMode ? "/img/hype_count_dark.svg" : "/img/hype_count.svg"}
                alt="Hype Count" />{" "}
            {Number(hypes).toLocaleString('en')}
        </span>
    );
}
