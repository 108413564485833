import axios from "axios";

export default function TokenTickerField({
  data,
  changeData,
  setTickerExists,
  tickerExists,
}) {
  const checkTicker = () => {
    axios
      .get("/api/v1/token/ticker/exists/" + data.ticker)
      .then((e) => setTickerExists(e.data));
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:dark:border-gray-800 sm:pt-5">
      <label
        htmlFor="city"
        className="block text-sm font-medium text-gray-700 dark:text-gray-400 sm:mt-px sm:pt-2"
      >
        Ticker
        {!data.ticker && <span className={`text-red-600 text-xs ml-1`}>*</span>}
      </label>

      <div className="mt-1 sm:mt-0 sm:col-span-2 ">
        <div className="relative">
          <div className="max-w-[15em] flex rounded-md shadow-sm ">
            <div
              className={`absolute inset-y-0 ${
                tickerExists && "bottom-6"
              } left-0 pl-3 flex items-center pointer-events-none`}
            >
              <span className="text-black text-xs dark:bg-gray-800 dark:text-white dark:border-black">
                #
              </span>
            </div>
            <input
              type="text"
              name="ticker"
              id="ticker"
              onBlur={() => checkTicker()}
              maxlength="100"
              value={data.ticker}
              onChange={(e) => {
                const newValue = e.target.value.replace(/[^0-9a-zA-Z]+/gi, "");
                changeData("ticker", newValue);
              }}
              className="pl-6 flex-1 block w-full focus:ring-red-500 dark:bg-gray-800 dark:text-white dark:border-black focus:border-red-500 min-w-0 rounded-none rounded-r-md text-xs border-gray-300"
            />
          </div>
          {tickerExists && (
            <p class="text-red-500 text-xs mt-2 italic">
              Ticker already in use, please use a different one!
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
