import { ThemeContext } from "utils/theme";
import Avatar from "components/presentation/TokenAvatar/TokenAvatar";
import Graph from "components/presentation/HypeGraphSmall/HypeGraphSmall";
import { HypeButton } from "components/buttons/HypeButton/HypeButton";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpService from "services/http-service";
import MobileTokenCard from "../MobileTokenCard/MobileTokenCard";
import LargeTokenCard from "../LargeTokenCard/LargeTokenCard";
// Context

const truncate = (text) =>
    text.length > 5 ? `${text.substring(0, 5)}...` : text;

const TokenCard = ({
    index,
    title,
    chain,
    ticker,
    type,
    hype = 0,
    link,
    projectId,
    allTimeHigh,
    avatar,
    tier,
}) => {
    // @ts-ignore
    const { darkMode } = useContext(ThemeContext);
    const [currentHype, setCurrentHype] = useState(hype);


    return (
        <>
            <MobileTokenCard
                index={index}
                tier={tier}
                link={link}
                avatar={avatar}
                title={title}
                type={type}
                allTimeHigh={allTimeHigh}
                darkMode={darkMode}
                currentHype={currentHype}
                projectId={projectId}
                chain={chain}
                ticker={ticker}
                changeCurrentHype={setCurrentHype}
            />

            <LargeTokenCard
                tier={tier}
                index={index}
                allTimeHigh={allTimeHigh}
                link={link}
                avatar={avatar}
                title={title}
                type={type}
                currentHype={currentHype}
                projectId={projectId}
                chain={chain}
                ticker={ticker}
                changeCurrentHype={setCurrentHype}
            />
        </>
    );
};


export default TokenCard