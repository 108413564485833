import axios from "axios"


export default function GiveAwayDescriptionField({ data, changeData }) {

    return (
        <div className="flex-col items-center">
            <label htmlFor="description" className="block text-md text-gray-700 dark:text-gray-400 ">

                Description
                {!data?.description && <span className={`text-red-600 text-xs ml-1`}>*</span>}
            </label>
            <div className="mt-1">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <textarea
                        id="description"
                        name="description"
                        value={data?.description}
                        rows={4}
                        onChange={(e) => changeData('description', e.target.value)}
                        className="max-w-lg shadow-sm block w-full focus:ring-red-500 focus:border-red-500 text-xs border border-gray-300 rounded-md dark:bg-gray-800 dark:text-white dark:border-black resize-none"
                        defaultValue={''}
                    />
                </div>

            </div>
        </div>
    )
}

