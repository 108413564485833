import axios from "axios"


export default function GiveAwayTypeField({ data, changeData}) {

    return (
        <div className="flex-col items-center">
            <label htmlFor="title" className="block text-md text-gray-700 dark:text-gray-400 ">

                Type
                {!data?.title && <span className={`text-red-600 text-xs ml-1`}>*</span>}
            </label>
            <div className="mt-1">
                <div className="max-w-lg flex rounded-md shadow-sm">
                <div className="max-w-lg flex rounded-md ">
                        <fieldset className="flex ">


                            <div className="flex items-center">
                                <input
                                    id="push-email"
                                    name="push-notifications"
                                    type="radio"
                                    checked={data.type === 'EXTERNAL'} onChange={(e) => changeData('type', "EXTERNAL")}
                                    className="focus:ring-red-500 h-3 w-3 text-red-600 border-gray-300 dark:bg-gray-700" 
                                />
                                <label htmlFor="push-email" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-400 dark:text-gray-300">
                                    External
                                </label>

                            </div>
                            <div className="flex items-center mx-2">
                                <input
                                    id="push-everything"
                                    name="push-notifications"
                                    type="radio"
                                    checked={data.type === 'HYPE_CLICK'} onChange={(e) => changeData('type', "HYPE_CLICK")}
                                    className="focus:ring-red-500 h-3 w-3 text-red-600 border-gray-300 dark:bg-gray-700 "
                                />
                                <label htmlFor="push-everything" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-400  dark:text-gray-300">
                                    Hype Click
                                </label>
                            </div>
                        </fieldset>
                    </div>

                </div>

            </div>
        </div>
    )
}

